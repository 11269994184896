:root {
  --transition: 0.4s;
}
/* @import url("https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  position: relative;
  background-image: none;
  background-color: #1e202c;
  font-family: "Poppins", sans-serif !important;
  background-size: contain;
  background-repeat: no-repeat;
  overflow: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.imgCls {
  margin: 0 auto;
  display: block;
  justify-content: center;
}

.tradeee {
  font-family: "Poppins", sans-serif !important;
}

.icon_loader {
  font-size: 36px;
  text-align: center;
  padding: 0.5em 0.6em;
}

.MuiButton-root {
  font-family: "Poppins", sans-serif !important;
}
::-webkit-scrollbar {
  height: 6px;
  width: 5px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: -webkit-linear-gradient(91.43deg, #5c2d91 2.55%, #9f02b9 123.64%);
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

::-webkit-scrollbar-corner {
  background: #000;
}
/* body::before {
	content: "";
	width: 100%;
	height: 100%;

	position: absolute;
	left: 0;
	top: 0;
	z-index: -1;
	background-color: #0e0f26;
	opacity: 0.05;
	background-size: auto;
} */
.tradeee header {
  padding: 10px 0px;
  border-bottom: 0px solid #ffffff14;
  position: relative;
  z-index: 9;
  background: #0d042c;
}
.tradeheight {
  height: 100%;
}

.homepage-section-banner {
  /* background-image: url(../src/img/home-bg-1-banner.png); */
  height: 100vh;
  /* background-color: #0f0f12; */
}
.navbar-expand-lg .navbar-nav .nav-link {
  color: #fff;
}
.search {
  border: 1px solid #ffffff14;
  border-radius: 5px;
}
.search .form-control {
  background: transparent;
  border: none;
  color: #fff;
}
.form-control:focus-visible {
  outline: none;
  box-shadow: none;
}
.search span {
  color: #fff;
  padding: 0px 13px;
  cursor: pointer;
}
button.btn.btn-primary-alta {
  background: linear-gradient(
    93deg,
    rgba(91, 177, 254, 1) 0%,
    rgba(72, 81, 244, 1) 100%
  );
  color: #fff;
}
button.btn.connectBtn {
  font-size: 14px;
  text-transform: capitalize;
  padding: 8px 17px;
  margin-left: 13px;
}
.notification {
  font-size: 17px;
  margin-left: 14px;
  border-radius: 50%;
  width: 40px !important;
  height: 40px !important;
  line-height: 9px;
  background: transparent !important;
  border: 1px solid #42424200 !important;
  padding: 0 !important;
}
.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

.navbar-brand .logo {
  width: 175px;
  margin-right: 16px;
  /* background: #fff; */
  padding: 0;
  /* border-radius: 38px; */
}
.navbar-brand {
  padding: 0;
}
.card {
  position: relative;
  padding: 15px;
  background: #1d1d29;
  border-radius: 7px;
}
.thumbnail {
  overflow: hidden;
  border-radius: 7px;
}

.thumbnail a {
  display: block;
  position: relative;
}
.thumbnail a::before {
  content: "";
  left: 0;
  bottom: 0;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    to(#000)
  );
  background-image: -moz-linear-gradient(top, transparent, #000);
  background-image: linear-gradient(180deg, transparent, #000);
  height: 50%;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.thumbnail a span {
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  width: initial;
  height: initial;
  background: rgba(0, 0, 0, 0) none repeat scroll 0% 0%;
  opacity: 1;
  border: 0px none;
  margin: 0px;
  padding: 0px;
  position: relative;
  max-width: 100%;
}
.banner-read-thumb {
  position: absolute;
  bottom: 50px;
  left: 50px;
}
.card .banner-read-thumb {
  z-index: 3;
}
.banner-read-thumb h4 {
  margin-bottom: 10px;
  color: #fff;
  font-size: 25px;
  font-weight: 600;
}
.banner-read-thumb span {
  font-size: 17px;
  color: #999;
}
.banner-read-thumb a:hover {
  text-decoration: none;
  color: #fff;
}
.section {
  padding: 40px 0px;
}
.headeing_site {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
}
.headeing_site h2 {
  font-size: 34px;
  color: #fff;
  font-weight: bold;
}
.nice-select {
  margin-right: 0;
  border: none;
  background: #212e48;
  padding-left: 10px;
  -webkit-tap-highlight-color: transparent;
  border-radius: 5px;
  clear: both;
  cursor: pointer;
  display: block;
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
  height: 42px;
  line-height: 40px;
  outline: 0;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  width: auto;
  min-width: -moz-fit-content;
  min-width: fit-content;
  color: #fff;
  width: 146px;
  font-size: 17px;
}
.creator-list-wrapper {
  counter-reset: rn-list-counter;
}
.creator-list-wrapper .creator-single {
  counter-increment: rn-list-counter;
  position: relative;
}
.creator-list-wrapper .creator-single::before {
  content: counter(rn-list-counter);
  font-weight: 700;
  font-size: 61px;
  margin-right: 0.5rem;
  line-height: 1;
  position: absolute;
  right: 30px;
  z-index: 3;
  color: #a9a9a9;
  opacity: 0.1;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}
.top-seller-inner-one.explore {
  border: 1px solid #25253657;
  border-radius: 5px;
  display: flex;
  padding: 20px;
  background: #23233457;
  position: relative;
  margin: 16px 0px;
}
.top-seller-inner-one .top-seller-wrapper {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
}
.top-seller-inner-one.explore .top-seller-wrapper .thumbnail {
  max-width: 80px;
}
.top-seller-inner-one .top-seller-wrapper .thumbnail a {
  display: block;
  border-radius: 50%;
  line-height: 0.7;
  overflow: hidden;
  border: 3px solid #fcfafa14 !important;
}
.top-seller-inner-one .top-seller-wrapper .thumbnail a span {
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  width: 74px;
  height: 74px;
  background: rgba(0, 0, 0, 0) none repeat scroll 0% 0%;
  opacity: 1;
  border: 0px none;
  margin: 0px;
  padding: 0px;
  position: relative;
}
.top-seller-inner-one .top-seller-wrapper .thumbnail a span img {
  position: absolute;
  inset: 0px;
  box-sizing: border-box;
  padding: 0px;
  border: medium none;
  margin: auto;
  display: block;
  width: 0px;
  height: 0px;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
}
.top-seller-inner-one .top-seller-wrapper .top-seller-content {
  margin-left: 12px;
  color: #fff;
}
.top-seller-inner-one .top-seller-wrapper .top-seller-content a h6 {
  margin-bottom: 10;
  font-size: 20px;
  font-weight: 500;
}
.top-seller-inner-one .top-seller-wrapper .top-seller-content span {
  font-size: 14px;
}
.top-seller-inner-one .top-seller-wrapper .top-seller-content span {
  font-size: 14px;
  color: #999;
}
.top-seller-content a:hover {
  text-decoration: none;
  color: #fff;
}
.top-seller-inner-one.explore:hover {
  background: #212d47;
}
.product-style-one {
  padding: 20px;
  border-radius: 10px;
  background: #242435;
  height: 100%;
  position: relative;
  z-index: 5;
}
.product-style-one::before {
  top: auto;
  bottom: 0;
  border-radius: 15px;
  background-image: -moz-linear-gradient(350deg, #13131d, #0398ed);
  background-image: linear-gradient(100deg, #13131d, #0398ed);
  background-repeat: repeat-x;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  z-index: -2;
}
.product-style-one::after,
.product-style-one::before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
}
.product-style-one .card-thumbnail {
  overflow: hidden;
  border-radius: 5px;
  margin: -15px;
}
.product-style-one .card-thumbnail {
  position: relative;
}
.product-style-one .card-thumbnail a {
  position: relative;
  display: block;
}
.product-style-one .card-thumbnail a::before {
  content: "";
  left: 0;
  bottom: 0;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    to(#000)
  );
  background-image: -moz-linear-gradient(top, transparent, #000);
  background-image: linear-gradient(180deg, transparent, #000);
  height: 50%;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.card_logoki.mobileapp {
  margin-top: 30% !important;
}
.spinloader {
  color: #fff !important;
  font-size: 100px !important;
  margin-top: 30% !important;
  margin-left: 250px;
}
.product-style-one .card-thumbnail span {
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0;
  margin: 0;
  padding: 0;
  position: relative;
  max-width: 100%;
}
.product-style-one .card-thumbnail a img {
  border-radius: 5px;
  object-fit: cover;
  width: 100%;
  height: auto;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
}
.product-share-wrapper {
  -moz-box-pack: justify;
  justify-content: space-between;
  margin-top: 30px;
}
.product-share-wrapper,
.product-share-wrapper .profile-share {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
}
.product-share-wrapper,
.product-share-wrapper .profile-share {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
}
.avatar {
  position: relative;
}
.product-share-wrapper .profile-share a {
  position: relative;
  z-index: 1;
  -webkit-transition: var(--transition);
  -moz-transition: var(--transition);
  transition: var(--transition);
  line-height: 0.5;
}
.product-share-wrapper .profile-share a span {
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  width: 30px;
  height: 30px;
  background: none;
  opacity: 1;
  border: 0;
  margin: 0;
  padding: 0;
  position: relative;
}
.product-share-wrapper .profile-share a img {
  max-width: 30px;
  border-radius: 50%;
  border: 2px solid #fbfafa14 !important;
  -webkit-transition: var(--transition);
  -moz-transition: var(--transition);
  transition: var(--transition);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  padding: 0;
  border: none;
  margin: auto;
  display: block;
  width: 0;
  height: 0;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
}
.product-share-wrapper .profile-share a.more-author-text {
  color: #a9a9a9;
  display: inline-block;
  padding-left: 7px;
}
.product-share-wrapper .share-btn {
  cursor: pointer;
  position: relative;
  z-index: 1;
  -webkit-transition: var(--transition);
  -moz-transition: var(--transition);
  transition: var(--transition);
  height: 35px;
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
}
.product-share-wrapper .share-btn .icon {
  padding: 0;
  margin: 0;
  border: 0;
  display: inline-block;
  font-weight: 500;
  text-align: center;
  font-size: 16px;
  color: #fff;
  padding: 10px 16px;
  border-radius: 10px;
  -webkit-transition: var(--transition);
  -moz-transition: var(--transition);
  transition: var(--transition);
  letter-spacing: 0.5px;
  width: auto;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}
.product-style-one a .product-name {
  display: block;
  margin-top: 10px;
  font-weight: 500;
  font-size: 18px;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  transition: 0.4s;
  color: #fff;
}
.product-style-one .latest-bid {
  font-weight: 400;
  font-size: 16px;
  margin-top: 10px;
}
.product-style-one .bid-react-area {
  display: -moz-box;
  display: flex;
  -moz-box-pack: justify;
  justify-content: space-between;
  margin-top: 10px;
}
.product-style-one .bid-react-area .last-bid {
  font-weight: 500;
  color: #00a3ff;
}
.product-style-one .bid-react-area .react-area {
  cursor: pointer;
  position: relative;
  z-index: 1;
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -webkit-transition: var(--transition);
  -moz-transition: var(--transition);
  transition: var(--transition);
  padding: 2px 8px;
  border-radius: 6px;
  color: #fff;
}
.product-style-one .bid-react-area .react-area:hover::after {
  opacity: 1;
  background: var(--color-primary);
}
.product-style-one .bid-react-area .react-area a {
  margin-right: 7px;
}
.product-style-one .bid-react-area .react-area span.number {
  font-size: 14px;
  position: relative;
}
.product-style-one .bid-react-area .react-area::after {
  background: #202d47;
  position: absolute;
  content: "";
  top: 0;
  z-index: -1;
  border-radius: 6px;
  -webkit-transition: var(--transition);
  -moz-transition: var(--transition);
  transition: var(--transition);
  opacity: 0;
  width: 100%;
  left: 0;
  height: 100%;
}
.product-style-one::after,
.product-style-one::before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
}
.product-style-one::after {
  top: 0;
  background: #242435;
  border-radius: 10px;
  z-index: -1;
}
.product-style-one::before {
  top: auto;
  bottom: 0;
  border-radius: 15px;
  background-image: -moz-linear-gradient(350deg, #13131d, #0398ed);
  background-image: linear-gradient(100deg, #13131d, #0398ed);
  background-repeat: repeat-x;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  z-index: -2;
}
.product-style-one:hover::before {
  -webkit-transform: rotate(2deg) translateX(-7px) translateY(11px);
  -moz-transform: rotate(2deg) translateX(-7px) translateY(11px);
  transform: rotate(2deg) translateX(-7px) translateY(11px);
}
.countdown {
  display: -moz-box;
  display: flex;
  margin: -4px;
  -moz-box-pack: center;
  justify-content: center;
  margin: 9px 0px;
}
.product-style-one .card-thumbnail .countdown {
  position: absolute;
  display: -moz-box;
  display: flex;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%);
  -moz-transform: translate(-50%);
  transform: translate(-50%);
  bottom: 12px;
  cursor: pointer;
  padding: 5px 8px;
  border-radius: 5px;
  z-index: 2;
}
.product-style-one .card-thumbnail .countdown {
  cursor: pointer;
}
.countdown .countdown-container {
  margin: 4px !important;
  position: relative;
}
.countdown .countdown-container .countdown-value {
  display: block;
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  border-radius: 4px;
  padding: 2px 10px;
  position: relative;
  background-color: rgba(36, 36, 53, 0.3);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
}
.countdown .countdown-container .countdown-value::after {
  content: ":";
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -11px;
  position: absolute;
  font-size: 20px;
  color: #abaaaa;
  font-weight: 400;
  width: 25px;
}
.countdown .countdown-container:last-child .countdown-value:after {
  display: none;
}
.countdown .countdown-container .countdown-heading {
  color: #a7a7a7;
  font-size: 14px;
  text-align: center;
  margin-top: 6px;
  text-transform: uppercase;
  margin-top: 0;
  display: block;
}

.rn-collection-inner-one .collection-wrapper {
  position: relative;
  padding: 18px;
  background: #24243657;
  border-radius: 5px;
}
.rn-collection-inner-one .collection-wrapper .collection-big-thumbnail {
  overflow: hidden;
  border-radius: 5px;
}
.rn-collection-inner-one .collection-wrapper .collection-big-thumbnail span {
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  width: initial;
  height: initial;
  background: rgba(0, 0, 0, 0) none repeat scroll 0% 0%;
  opacity: 1;
  border: 0px none;
  margin: 0px;
  padding: 0px;
  position: relative;
  max-width: 100%;
  width: 100%;
}
.rn-collection-inner-one .collection-wrapper .collection-big-thumbnail img {
  border-radius: 5px;
  object-fit: cover;
  width: 100%;
  height: auto;
  -webkit-transition: var(--transition);
  -moz-transition: var(--transition);
  transition: var(--transition);
}
.rn-collection-inner-one .collection-wrapper .collenction-small-thumbnail {
  display: -moz-box;
  display: flex;
  -moz-box-pack: justify;
  justify-content: space-between;
  margin-left: -4px;
  margin-top: 4px;
}
.rn-collection-inner-one
  .collection-wrapper
  .collenction-small-thumbnail
  > div {
  display: inline-block;
  width: 33.33%;
  padding: 4px;
  line-height: 1;
  margin-bottom: -4px;
}
.rn-collection-inner-one
  .collection-wrapper
  .collenction-small-thumbnail
  > div
  span {
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  width: initial;
  height: initial;
  background: rgba(0, 0, 0, 0) none repeat scroll 0% 0%;
  opacity: 1;
  border: 0px none;
  margin: 0px;
  padding: 0px;
  position: relative;
  max-width: 100%;
}
.rn-collection-inner-one .collection-wrapper .collenction-small-thumbnail img {
  border-radius: 10px;
}
.rn-collection-inner-one .collection-wrapper .collection-profile {
  position: absolute;
  top: 48%;
  left: 50%;
  -webkit-transform: translate(-50%);
  -moz-transform: translate(-50%);
  transform: translate(-50%);
  max-width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 5px solid #202d46;
  -webkit-transition: var(--transition);
  -moz-transition: var(--transition);
  transition: var(--transition);
}
.rn-collection-inner-one .collection-wrapper .collection-profile span {
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  width: initial;
  height: initial;
  background: rgba(0, 0, 0, 0) none repeat scroll 0% 0%;
  opacity: 1;
  border: 0px none;
  margin: 0px;
  padding: 0px;
  position: relative;
  max-width: 100%;
}
.rn-collection-inner-one .collection-wrapper .collection-profile img {
  border-radius: 50% !important;
}
.rn-collection-inner-one .collection-wrapper .collection-deg {
  display: -moz-box;
  display: flex;
  margin-top: 15px;
  -moz-box-pack: justify;
  justify-content: space-between;
  -moz-box-align: center;
  align-items: center;
}
.rn-collection-inner-one .collection-wrapper .collection-deg h6 {
  margin: 0;
  font-weight: 400;
  transition: var(--transition);
  color: #fff;
  font-weight: 500;
  text-decoration: none;
}
.rn-collection-inner-one .collection-wrapper .collection-deg span.items {
  padding: 5px 8px;
  background: #212e49;
  border-radius: 3px;
  color: #fff;
  -webkit-transition: var(--transition);
  -moz-transition: var(--transition);
  transition: var(--transition);
}
a {
  text-decoration: none !important;
}
.rn-section-gap {
  padding: 100px 0;
}
.mt--100 {
  margin-top: 100px !important;
}
.bg-color--1 {
  background: #25253657;
}
.logo-thumbnail a {
  display: block;
  line-height: 0.8;
}
.logo-thumbnail a span {
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  width: initial;
  height: initial;
  background: rgba(0, 0, 0, 0) none repeat scroll 0% 0%;
  opacity: 1;
  border: 0px none;
  margin: 0px;
  padding: 0px;
  position: relative;
  max-width: 100%;
}
.rn-footer-one .footer-left .rn-footer-describe {
  font-size: 18px;
  margin-top: 20px;
  padding: 0 30px 0 0;
  color: #fff;
}
.widget-bottom .title {
  color: #999;
  margin-bottom: 15px;
}
.pt--40 {
  padding-top: 40px !important;
}
.mt--40 {
  margin-top: 40px !important;
}
.widget-content-wrapper .widget-bottom {
  border-top: 1px solid #14141f;
}
.widget-content-wrapper .widget-bottom {
  border-top: 1px solid #14141f;
}
.widget-content-wrapper .input-group input {
  color: #aeaeae;
  position: relative;
  z-index: 1;
  font-size: 16px;
  border-radius: 5px 0 0 5px;
  background: #13131d;
  outline: none;
  box-shadow: none;
  border-color: #999;
}
.widget-content-wrapper .input-group .input-group-append {
  position: relative;
  left: -5px;
  z-index: 5;
}
.widget-content-wrapper .newsletter-dsc p {
  margin-top: 10px;
  font-size: 14px;
  letter-spacing: 0.2px;
}
.footer-widget.widget-quicklink {
  margin-left: 100px;
}
.footer-widget .widget-title {
  font-size: 19px;
  margin-bottom: 25px;
  color: #fff;
  font-weight: 600;
}
.footer-list-one {
  margin: 0;
  list-style: none;
  padding: 0;
}
.footer-list-one .single-list {
  margin: 11px 0;
  color: #acacac;
}
.footer-list-one .single-list a {
  font-size: 16px;
  color: #b3b2b2;
  font-weight: 400;
  -webkit-transition: var(--transition);
  -moz-transition: var(--transition);
  transition: var(--transition);
  position: relative;
}
.footer-list-one .single-list a::after {
  position: absolute;
  content: "";
  left: auto;
  bottom: 0;
  background: #00a3ff;
  width: 0;
  height: 2px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
  right: 0;
}
.footer-list-one .single-list a:hover::after {
  width: 100%;
  left: 0;
  right: auto;
}

footer .logo {
  width: 66px;
}

.footer-recent-post li.recent-post {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  padding: 10px 0;
  margin: 0;
}
.footer-recent-post li.recent-post .thumbnail {
  margin-right: 12px;
}
.footer-recent-post li.recent-post .thumbnail span {
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  width: 60px;
  height: 60px;
  background: rgba(0, 0, 0, 0) none repeat scroll 0% 0%;
  opacity: 1;
  border: 0px none;
  margin: 0px;
  padding: 0px;
  position: relative;
}
.footer-recent-post li.recent-post .thumbnail a img {
  max-height: 60px;
  border-radius: 100%;
  object-fit: cover;
}
.footer-recent-post li.recent-post .content .title {
  font-size: 14px;
  margin-bottom: 4px;
}
.footer-recent-post li.recent-post .content p {
  margin-bottom: 3px;
  font-size: 13px;
  color: #adadad;
}
.footer-recent-post li.recent-post .content .price {
  color: #00a3ff;
  font-weight: 700;
}
.recent-post .thumbnail a::before {
  background: none;
}
.footer-recent-post li.recent-post .content .title a {
  color: #fff;
}

.pulse {
  /* margin: 100px; */
  display: block;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #019cf4;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
  animation: pulse 2s infinite;
  margin-right: 12px;
}
.pulse:hover {
  animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

.title_flex_animation {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rn-service-one {
  text-align: left;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  border-radius: 10px;
  /* background: #2B2B4099;
   */
  background: transparent;
  position: relative;
  z-index: 1;
  height: 100%;
  padding: 70px 30px 40px;
}
.rn-service-one::before {
  content: "";
  position: absolute;
  -webkit-transition: var(--transition);
  -moz-transition: var(--transition);
  transition: var(--transition);
  width: 100%;
  height: 100%;
  border-radius: 10px;
  top: 0;
  left: 0;
  background: transparent;
  opacity: 0;
  z-index: -1;
}
.rn-service-one .inner > * {
  -webkit-transition: 0.7s cubic-bezier(0.17, 0.67, 0, 1.01);
  -moz-transition: 0.7s cubic-bezier(0.17, 0.67, 0, 1.01);
  transition: 0.7s cubic-bezier(0.17, 0.67, 0, 1.01);
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  transform: translateY(10px);
}
.rn-service-one .inner .icon img {
  max-height: 110px !important;
}
.rn-service-one .inner .subtitle {
  position: absolute;
  color: #5bb1fe;
  top: 47px;
  left: 30px;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.rn-service-one .inner .content {
  padding-top: 20px;
}
.rn-service-one .inner .content .title {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 10px;
  color: #fff;
}
.rn-service-one .inner .content p.description {
  font-size: 16px;
  line-height: 28px;
  color: var(--color-lightn);
  margin-bottom: 30px;
  color: #999;
  margin-top: 20px;
}
.rn-service-one .inner .content .read-more-button {
  font-size: 24px;
}
.rn-service-one .inner .content .read-more-button i {
  color: #adadad;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}
.rn-service-one .inner .icon {
  position: absolute;
  z-index: 2;
  right: -15px;
  top: -60px;
}
/* 
.rn-service-one:hover {
  cursor: pointer;
  top: -10px;
} */
.Dropdown-control {
  margin-right: 0;
  border: none;
  background: #22304a !important;
  padding-left: 10px;
  -webkit-tap-highlight-color: transparent;
  border-radius: 5px;
  clear: both;
  cursor: pointer;
  display: block;
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
  height: 42px;
  line-height: 27px;
  outline: 0;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  width: auto;
  min-width: -moz-fit-content;
  min-width: fit-content;
  color: #bdbdbd !important;
  border-radius: 5px !important;
  border: none !important;
  font-size: 16px;
}
#filter label {
  font-weight: 500;
  color: #fff;
  font-size: 14px;
  margin-bottom: 13px;
  text-transform: uppercase;
}
.Dropdown-menu {
  background: #22304a !important;
  border: none !important;
  color: #fff !important;
}

.Dropdown-option {
  box-sizing: border-box;
  color: #fff !important;
  cursor: pointer;
  display: block;
  padding: 8px 10px;
  font-weight: 500;
  font-size: 16px;
}
.Dropdown-option:hover {
  box-sizing: border-box;
  color: #fff !important;
  background: #007bff !important;
}
.rc-slider-rail {
  background-color: #00a3ff;
}
.rc-slider-handle {
  background-color: #00a3ff;
  border: solid 2px #00a3ff;
}

#filter .rc-slider {
  margin: 3px 0px;
}
#filter small {
  color: #999;
  font-size: 13px;
  margin-top: 14px;
}

.ptb--30 {
  padding: 30px 0 !important;
}
.rn-breadcrumb-inner {
  border-bottom: 1px solid #ffffff14;
}
.rn-breadcrumb-inner .pageTitle {
  text-transform: capitalize;
  margin-bottom: 0;
  color: #fff;
  font-size: 25px;
  font-weight: 600;
}
.rn-breadcrumb-inner .breadcrumb-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: -moz-box;
  display: flex;
  -moz-box-pack: end;
  justify-content: flex-end;
  -moz-box-align: center;
  align-items: center;
}
ul li {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #aeaeae;
}

.rn-breadcrumb-inner .breadcrumb-list li a {
  color: #adadad;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  transition: 0.4s;
}
.rn-breadcrumb-inner .breadcrumb-list li.separator {
  margin: 0 15px;
}
.rn-breadcrumb-inner .breadcrumb-list li.current {
  color: #fff;
}
.upload-variant-title-wrapper h3 {
  color: #fff;
  /* text-align: center; */
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 23px;
}
.upload-variant-title-wrapper p {
  text-align: center;
  color: #999;
  font-size: 17px;
  width: 61%;
  display: block;
  margin: 25px auto;
  line-height: 30px;
}
.upload-variant-wrapper {
  padding: 18px 20px;
  background: #25253857;
  border-radius: 5px;
  -moz-box-pack: center;
  justify-content: center;
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
}
.upload-variant-wrapper .variant-preview {
  overflow: hidden;
  border-radius: 5px;
}
.upload-variant-wrapper .variant-preview span {
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  width: initial;
  height: initial;
  background: rgba(0, 0, 0, 0) none repeat scroll 0% 0%;
  opacity: 1;
  border: 0px none;
  margin: 0px;
  padding: 0px;
  position: relative;
}
.upload-variant-wrapper a {
  border-radius: 5px;
}
.mt--20 {
  margin-top: 20px !important;
}
.mb--30 {
  margin-bottom: 30px !important;
}
.upload-area .upload-formate h6.title {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
}
.upload-area .upload-formate p.formate {
  font-size: 16px;
  color: #ccc;
}
p:last-child {
  margin-bottom: 0;
}
.upload-area .brows-file-wrapper {
  position: relative;
  border: 2px dashed #575767;
  border-radius: 5px;
}
.upload-area .brows-file-wrapper input {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}
.upload-area label {
  width: 100%;
  height: 250px;
  border-radius: 5px;
  display: -moz-box;
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
  -moz-box-align: center;
  align-items: center;
  cursor: pointer;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  margin-bottom: 15px;
}
.upload-area .brows-file-wrapper label {
  position: relative;
  z-index: 10;
  -webkit-transition: var(--transition);
  -moz-transition: var(--transition);
  transition: var(--transition);
}
.upload-area label i {
  font-size: 40px;
  stroke-width: 1px;
  color: #00a3ff;
  margin-bottom: 10px;
}
.upload-area label span {
  font-size: 20px;
  color: #fff;
}
.upload-area label p {
  font-size: 14px;
  font-weight: 300;
}
.mt--10 {
  margin-top: 10px !important;
}
.upload-area .brows-file-wrapper:hover label {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  transform: scale(0.8);
}
.upload-area {
  padding: 15px 0;
}
.upload-area .upload.file {
  color: #fff;
  font-size: 18px;
  margin: 5px 0px;
}
.upload-area label p {
  color: #999;
}

.Upload.file {
  color: #fff;
  margin-bottom: 7px;
  font-size: 19px;
  margin-top: 10px;
}
.form-wrapper-one .input-box {
  display: block;
}
.pb--20 {
  padding-bottom: 20px !important;
}
.form-label {
  margin-bottom: 0.5rem;
}
.form-wrapper-one input,
.form-wrapper-one textarea {
  background: #212131;
  height: 50px;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  padding: 10px 20px;
  border: 2px solid #ffffff14 !important;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}
.form-wrapper-one label {
  /* display: inline-block; */
  font-size: 16px;
  color: #999;
  margin-bottom: 12px !important;
  margin-top: 11px !important;
  text-transform: capitalize;
}
.form-wrapper-one textarea {
  height: 100px;
}
.form-wrapper-one {
  padding: 40px 35px;
  border-radius: 6px;
  background: #25253757;
  height: 100%;
  border: 1px solid #ffffff14;
}
.rn-section-gapTop {
  padding-top: 100px;
}

a.btn.btn-primary-alta,
button.btn.btn-primary-alta {
  width: 100%;
  margin: 0 auto;
  display: block;
  padding: 10px 0px;
  background: #4f41a4 !important;
  color: #fff;
  border: 1px solid #4f41a4 !important;
}
a.btn.btn-primary-alta:hover,
button.btn.btn-primary-alta:hover {
  width: 100%;
  margin: 0 auto;
  display: block;
  padding: 10px 0px;
  background: #4f41a4 !important;
  color: #fff;
  border: 1px solid #4f41a4 !important;
}
button.btn.btn-primary-alta.notification:hover {
  border: 1px solid #611da1 !important;
}

a.btn.btn-large,
button.btn.btn-large {
  padding: 15px 34px;
  min-width: 160px;
}
a.btn.btn-primary-alta,
button.btn.btn-primary-alta {
  background: #3b82f6;
  color: #fff;
}
a.btn.btn-primary,
button.btn.btn-primary {
  background: #4f41a4;
}
a.btn,
button.btn {
  display: inline-block;
  font-weight: 500;
  text-align: center;
  font-size: 14px;
  color: #fff;
  padding: 10px 16px;
  border-radius: 10px;
  -webkit-transition: var(--transition);
  -moz-transition: var(--transition);
  transition: var(--transition);
  letter-spacing: 0.5px;
  width: auto;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}
.single-activity-wrapper {
  margin-bottom: 30px;
  padding: 20px;
  background: #26263757;
  border-radius: 5px;
  position: relative;
}
.single-activity-wrapper .inner {
  -moz-box-pack: justify;
  justify-content: space-between;
}
.single-activity-wrapper .inner,
.single-activity-wrapper .inner .read-content {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
}
.single-activity-wrapper .thumbnail {
  width: 108px;
  height: 108px;
}
.single-activity-wrapper .thumbnail a {
  display: block;
  overflow: hidden;
}
.single-activity-wrapper .thumbnail a span {
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  width: initial;
  height: initial;
  background: rgba(0, 0, 0, 0) none repeat scroll 0% 0%;
  opacity: 1;
  border: 0px none;
  margin: 0px;
  padding: 0px;
  position: relative;
  max-width: 100%;
}

.single-activity-wrapper .thumbnail img {
  border-radius: 5px !important;
}
.single-activity-wrapper .thumbnail a img {
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  transition: 0.4s;
}
.single-activity-wrapper:hover .thumbnail a img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  transform: scale(1.1);
}
.single-activity-wrapper:hover .thumbnail a img {
  /* position: absolute; */
  inset: 0px;
  box-sizing: border-box;
  padding: 0px;
  border: medium none;
  margin: auto;
  display: block;
  /* width: 0px;
  height: 0px; */
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
}
.single-activity-wrapper .inner .content {
  margin-left: 20px;
}
.single-activity-wrapper .inner .content a {
  color: #fff;
}
.single-activity-wrapper .inner .content h6.title {
  margin-bottom: 10px;
}
.single-activity-wrapper .inner .content p {
  margin-bottom: 5px;
  font-size: 20px;
  color: #7f859d;
}
.single-activity-wrapper .inner .content p span {
  color: #00a3ff;
}
.single-activity-wrapper .inner .content .time-maintane {
  display: -moz-box;
  display: flex;
  color: #abaaaa !important;
}
.single-activity-wrapper .inner .content .time-maintane .time {
  margin-right: 10px;
}
.single-activity-wrapper .inner .content .time-maintane .data {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
}
.single-activity-wrapper .inner .content .time-maintane .data i,
.single-activity-wrapper .inner .content .time-maintane .data svg {
  width: 18px;
  font-size: 18px;
  height: auto;
  margin-right: 7px;
}
.single-activity-wrapper .inner .content .time-maintane .data a:hover {
  color: #00a3ff;
}

.user-area.data > a {
  color: #abaaaa !important;
}
.single-activity-wrapper .inner .content h6.title {
  font-weight: 500;
  font-size: 23px;
}
.single-activity-wrapper .inner .icone-area i,
.single-activity-wrapper .inner .icone-area svg {
  width: 30px;
  height: auto;
  color: #00a3ff;
  margin-right: 10px;
  stroke-width: 1.5px;
  font-size: 30px;
}
.rn-section-gapTop {
  padding-top: 100px;
}

.title {
  font-size: 32px;
  font-weight: bold;
  color: #fff;
}

.widge-wrapper .inner {
  margin-bottom: 30px;
}
.widge-wrapper h3 {
  font-weight: 500;
  font-size: 18px;
  color: #fff;
  margin: 0 0 20px;
}
.filter-wrapper .sing-filter {
  display: -moz-box;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -15px;
  margin-right: -15px;
}
.filter-wrapper .sing-filter button {
  display: inline-block;
  text-transform: capitalize;
  padding: 10px 15px;
  background: #1e1e29;
  cursor: pointer;
  margin-right: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
  -webkit-transition: var(--transition);
  -moz-transition: var(--transition);
  transition: var(--transition);
  font-size: 16px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  color: #fff;
  border: 1px solid #ffffff14;
}
.filter-wrapper .sing-filter button:focus,
.filter-wrapper .sing-filter button:hover {
  background: #202d47;
  color: #fff;
}
.widge-wrapper {
  position: -webkit-sticky;
  position: sticky;
  z-index: 10;
}
.filter-wrapper {
  height: 100%;
}
.posiclasss {
  position: sticky;
  top: 20px;
}

.nice-select {
  margin-right: 0;
  border: none;
  background: #202d46;
  padding-left: 10px;
  -webkit-tap-highlight-color: transparent;
  border-radius: 5px;
  clear: both;
  cursor: pointer;
  display: block;
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
  height: 42px;
  line-height: 40px;
  outline: 0;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  width: auto;
  /* min-width: -webkit-fit-content; */
  /* min-width: -moz-fit-content; */
  min-width: fit-content;
  padding: 0px 26px !important;
  border: 1px solid #1a1a1a !important;
  border-radius: 7px !important;
}
.top-seller-inner-one .thumbnail.varified::after {
  position: absolute;
  content: "";
  right: -3px;
  top: 26%;
  height: 20px;
  width: 20px;
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: feather !important;
  z-index: 1;
  color: var(--color-white);
  background: var(--color-primary);
  border-radius: 50%;
  line-height: 0;
}
nav.pagination-wrapper {
  display: -moz-box;
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 0;
}

.pagination {
  display: -moz-box;
  display: flex;
  padding-left: 0;
  list-style: none;
}

nav.pagination-wrapper .pagination {
  -webkit-transition: var(--transition);
  -moz-transition: var(--transition);
  transition: var(--transition);
  background: #1c1c28;
  border: 1px solid #ffffff14;
  border-radius: 6px;
  flex-wrap: wrap;
}
nav.pagination-wrapper .pagination li {
  margin: 0;
}

nav.pagination-wrapper .pagination li a.active,
nav.pagination-wrapper .pagination li a:hover,
nav.pagination-wrapper .pagination li button.active,
nav.pagination-wrapper .pagination li button:hover {
  background: #00a3ff;
  color: #fff !important;
}
nav.pagination-wrapper .pagination li a,
nav.pagination-wrapper .pagination li button {
  background: #1e1e2c;
  border: none;
  color: #fff;
  padding: 12px 20px;
  margin: 6px;
  border-radius: 6px !important;
  display: block;
  width: auto;
}

.first_content h1 {
  font-size: 64px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 15px;
  text-align: center;
  margin-top: 20px;
  text-shadow: 0px 0px 11px #444343;
}
.first_content h1 span {
  color: #3b82f6;
}
.first_content p {
  font-size: 20px;
  color: #fff;
  padding: 7px 0px;
  margin-bottom: 15px;
  text-shadow: 0px 0px 11px #444343;
  text-align: center;
}
.first_content .social {
  display: flex;
  padding: 14px 0px;
  justify-content: flex-start;
  filter: drop-shadow(0px 0px 3px #444);
}
.airdrop_btn {
  text-align: center;
}
.first_content .social a {
  margin: 0px 5px;
}
.first_content .social a img {
  width: 29px;
  margin: 0 8px;
}
.first_content {
  padding: 59px 0px;
}

.why-chooks h3 {
  font-size: 40px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  margin-bottom: 15px;
}
.why-chooks p {
  font-size: 20px;
  color: #b0c0e0;
  text-align: center;
}
.why-chooks span {
  color: #3b82f6;
}
.why-card .card-transparant {
  background: #2b2b4099;
  padding: 20px 20px;
  margin: 0px 59px;
  border-radius: 13px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 395px;
  max-height: 395px;
  margin-bottom: 40px;
}
.why-card .card-transparant h5 {
  font-size: 21px;
  color: #fff;
  padding: 7px 0px;
  margin-bottom: 15px;
  font-weight: 600;
}
.why-card .card-transparant p {
  color: #fff;
  text-align: center;
}
.why-card .card-transparant img {
  width: 70px;
  margin: 6px 0px;
  margin-bottom: 17px;
}
.why-card {
  padding: 40px 0px;
}
.boiconi_min {
  width: 77%;
  margin: 14px auto;
}
.section-2-s {
  padding: 60px 0px;
  background-image: url(../src/img/wavw2.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.card-transparnt {
  margin: 40px 65px;
  padding: 50px 33px;
  background: #2b2b4099;
  border-radius: 15px;
}
.section_3_coneten h4 {
  color: #ffff;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 25px;
}
.section_3_coneten p {
  color: #b0c0e0;
  margin-bottom: 25px;
}
.airdrop_btn > button {
  margin: 18px 0px !important;
  padding: 10px 30px !important;
  font-size: 20px !important;
  margin-top: 36px !important;
}
.section_3_coneten > button.learnmore {
  padding: 10px 30px;
  margin-right: 20px;
  text-transform: capitalize;
}
.section_3_coneten > button.learnmore2 {
  text-transform: capitalize;
  padding: 10px 30px;

  background: transparent;
  border: 1px solid #5baffe;
}
.section_3_coneten > button.learnmore2:hover {
  text-transform: capitalize;

  background: transparent;
  border: 1px solid #5baffe;
}
.rowwww-w {
  position: relative;
}
.rowwww-w img {
  position: absolute;
  top: -246px;
  width: 100%;
}
.section-4 {
  background-image: url(../src/img/Ellipse7.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.abute h4 {
  font-size: 48px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 15px;
}
.abute h4 span {
  color: #3b82f6;
}
.abute p {
  font-size: 17px;
  color: #b0c0e0;
}
.abute {
  padding: 100px 0px;
}
.teamcard .card-transparant > img {
  width: 110px;
  border-radius: 50%;
  height: 110px;
}
.teamcard .card-transparant span {
  color: #3b82f6;
  margin-bottom: 5px;
}
.section-5 {
  padding-bottom: 0;
}

.teamcard .card-transparant h5 {
  margin-bottom: 5px;
}
.teamcard .card-transparant p {
  font-size: 16px;
  margin-top: 10px;
}
.teamcard .card-transparant {
  padding-top: 25px;
  min-height: 460px;
}
.chart_widyth {
  width: 62%;
  margin: 0 auto;
}
.chart_widyth {
  width: 62%;
  margin: 0 auto;
}
.content ul {
  list-style: conic-gradient;
  padding-left: 17px;
}
.content ul li {
  color: #fff;
  font-size: 18px;
}
.roadmap_img {
  width: 70%;
  margin: 0 auto;
}
.title_advantage p {
  text-align: center;
  color: #fff;
  font-weight: 600;
  font-size: 20px;
}
.title_advantage img {
  width: 90px;
  margin: 0 auto;
  margin-bottom: 20px;
}
.title_advantage {
  padding: 50px;
}
.partercc {
  width: 90%;
  margin: 48px auto;
}
.abute h5 {
  margin-bottom: 15px;
  font-size: 23px;
  font-weight: 600;
  color: #fff;
}
.tradepage-bg {
  background: #1e202c;
  /* height: 100vh; */
}
.market_palace {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0;
  margin: 5px 0px;
  background: #313545 !important;
  border-radius: 5px;
}
.card_light {
  background: #313545;
  margin: 2px;
  padding: 7px 5px;
  box-shadow: 0px 0px 0px #292929;
  border-radius: 0px;
}
.trade_chart,
.trade_chart222 {
  padding: 0 5px;
  background: transparent;
}
.trade_chart222 {
  margin: 2px 2px;
  margin-bottom: 1px;
  border: 0px solid #303030;
  border-radius: 0;
}
.price_section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.dropdown,
.dropleft,
.dropright,
.dropup {
  position: relative;
}
.price_section button {
  background: #0000;
  border: none;
  padding: 0 18px;
  color: #000;
  font-size: 14px;
}
.price_section .text_red {
  font-size: 20px;
  margin-left: 20px;
  color: #2bbdb5;
}
.market_price_secrtiom {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
}
.market_price_secrtiom > div::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 67px;
  background: #454545;
  right: -70px;
  top: 0;
  display: none;
}
.market_price_secrtiom > div,
.trade_header_11 > div {
  padding: 5px 15px;
  margin-left: 20px;
  position: relative;
}
.market_price_secrtiom span {
  font-size: 13px;
  color: #777e90;
  font-weight: 400;
  margin-bottom: 7px;
  display: block;
}
.market_price_secrtiom i {
  margin-right: 5px;
  display: none;
}
.market_price_secrtiom h5 {
  color: #c3c2d4;
  font-size: 15px;
  text-align: left;
}
.trade_header_11 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}
.market_price_secrtiom > div,
.trade_header_11 > div > div {
  padding: 5px 15px;
  margin-left: 20px;
  position: relative;
  margin: 0;
}
/* #tv_chart_container {
  transform: scale(0.9);
} */
.upcomming {
  background: #423089 !important;
}
.formplac {
  padding: 25px !important;
  padding-top: 11px !important;
}
.title-table li a {
  font-size: 15px;
}
.trade_header_11 > div {
  display: flex;
}
.col-green {
  color: #2bbdb5 !important;
}
.formplac li {
  margin: 0;
  display: flex;
  text-transform: capitalize;
}
.formplac li a {
  padding: 6px 17px;
  margin-right: 0px;
  color: #777e90;
  font-weight: 400;
  font-size: 16px;
}
.ordertabel .fixTableHead {
  overflow-y: auto;
  height: 266px;
}
.formplac li a.active {
  background: no-repeat;
  color: #fff;
  border-radius: 28px;
  background: -webkit-linear-gradient(91.43deg, #9166c1 2.55%, #dc06ff 123.64%);
  background-clip: border-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.nav-tabs {
  border-bottom: 0px solid #dee2e6;
  margin-bottom: 10px;
}
.trade-colpading {
  padding-right: 5px;
  padding-left: 5px;
  padding: 0;
  border: 0px solid #303030;
  border-top: 0;
}
/* .foem_group .form-group input {
  background: transparent;
  border: 1px solid #2f2f2f;
  border-radius: 4px;
  height: 44px;
  margin: 16px 0px;
  color: #fff;
} */
.foem_group .form-group {
  background: transparent;
  border: 1px solid #5b6178;
  border-radius: 4px;
  height: 44px;
  margin: 16px 0px;
  color: #fff;
}
.foem_group .form-group input {
  background: transparent;
  color: #fff;
  border: none;
  height: 44px;
  width: 73%;
}
.kycdetaile p {
  color: #fff;
}

.kycdetaile p:first-child {
  color: #fff;
  font-size: 20px;
  margin-bottom: 15px !important;
}
.foem_group .form-group .currency {
  position: absolute;
  right: 7px;
  top: 11px;
  color: #777e90;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
}
.foem_group .form-group {
  position: relative;
}

.foem_group .form-group > button {
  width: 90%;
  margin: 0 auto;
  display: block;
}
.pading-rr {
  padding: 0px 10px;
}
.rc-slider-rail {
  height: 2px !important;
  background-color: #353945 !important;
}
.rc-slider-track {
  height: 2px !important;
  background-color: #5aabfd !important;
}
.buysellform li {
  width: 50%;
}
.buysellform a {
  width: 100%;
  text-align: center;
}
.buysellform li a.active {
  background: linear-gradient(
    90.15deg,
    #5c2d91 0.73%,
    #502abb 101.92%
  ) !important;
  border-radius: 8px;
  color: #fff;
  -webkit-text-fill-color: #fff;
}

.order-table .table td {
  padding: 6px 0px !important;
  border-top: 0px solid #dee2e6 !important;
  text-align: center;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
}

.order-table .table thead th {
  vertical-align: bottom;
  border-bottom: 0px solid #dee2e6 !important;
  border-top: 0px solid #dee2e6 !important;
  padding: 6px 0px;
  color: #777e90;
  font-weight: 400;
  text-align: center;
}
.order-table li {
  margin: 0;
  display: flex;
}
.order-table ul {
  padding: 10px 0px;
}
.login_button {
  background: none !important;
  border: 1px solid #9685ff !important;
}
.order-table a {
  font-size: 14px;
  font-weight: 500;
  color: #777e90;
  padding: 0px 0px;
  margin-right: 10px;
}
.order-table a.active {
  color: #fff;
}
.position_rel_over {
  position: relative;
}
.nodata_content {
  margin-left: 250px;
  color: #fff;
}
.position_rel_over td {
  position: relative;
  z-index: 2;
}
.position_rel_over .red_overlay {
  height: 32px;
  position: absolute;
  right: 0;
  background: #361833;
  z-index: 1;
  top: 0px;
}
.position_rel_over .green_overlay {
  height: 30px;
  position: absolute;
  right: 0;
  background: #2bbdb570;
  z-index: 1;
  top: 0px;
}
.color-green {
  color: #ef3e9e;
}
.curront_price {
  display: flex;
  border: 1px solid #474747;
  border-left: 0;
  border-right: 0;
  padding: 4px 0px;
}
.curront_price .curret0green {
  color: #2bbdb5;
  font-size: 16px;
  font-weight: 600;
  margin-right: 10px;
}
.curront_price i {
  color: #2bbdb5;
  font-size: 16px;
}

.curront_price .text-redc {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
}

.red-green {
  color: #2bbdb5;
}

.fixTableHead {
  overflow-y: auto;
  height: 181px;
}
.fixTableHead thead th {
  position: sticky;
  top: 0;
  z-index: 8;
}
.fixTableHead table {
  border-collapse: collapse;
  width: 100%;
}
.fixTableHead th,
.fixTableHead td {
  padding: 8px 15px;
  border: 0px solid #529432;
  text-align: center;
}
.fixTableHead th {
  background: #313545;
  font-weight: 400;
  text-align: center;
  color: #777e90;
  font-size: 15px;
}
.fixTableHead td {
  color: #fff;
  cursor: pointer;
}

.title-table {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
}
.title-table .nav-tabs {
  margin: 0;
}
.title-table li {
  margin: 0;
  display: flex;
}
.text-green {
  color: #2bbdb5;
}
.text-red {
  color: #ef3e9e;
}
.title-table h4 {
  font-size: 17px;
  color: #fff;
  font-weight: 600;
}
.title-table li a {
  color: #777e90;
  font-weight: 600;
  margin-left: 12px;
}
.title-table li a.active {
  color: #fff;
}
.search_option {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #505258;
  padding: 0px 7px;
  border-radius: 6px;
}
.search_option > input {
  background: transparent;
  height: 40px;
  flex: 1;
  width: 80%;
  color: #fff;
}
.search_option i {
  color: #fff;
  margin-right: 5px;
  cursor: pointer;
}
.coinpair .fixTableHead th,
.coinpair .fixTableHead td {
  padding: 7px 6px;
}
.coinpair .fixTableHead th {
  font-size: 13px;
}

.coinpair .fixTableHead td {
  font-size: 12px;
  font-weight: 400;
}
.coinpair .fixTableHead tr:hover {
  background: #27272775;
}
.img_pair {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
}
.img_pair img {
  width: 22px;
  margin-right: 5px;
}

.coinpair li {
  display: flex;
  margin: 0;
}
.coinpair li a {
  font-size: 14px;
  font-weight: 500;
  color: #777e90;
  padding: 0px 0px;
  margin-right: 10px;
}
.coinpair li a.active {
  color: #fff;
}
.coinpair ul {
  padding: 8px 0px;
  justify-content: space-around;
}
.coinpair .fixTableHead {
  height: 470px;
}
.uniqu_height {
  height: 472px;
}
:focus-visible {
  outline: none !important;
}

.experiance img {
  width: 65%;
  margin: 39px auto;
  filter: drop-shadow(0px 6px 4px #000);
  margin-top: 55px;
}
.section-8 {
  padding-top: 55px;
}
.tocken_details h4 {
  font-size: 21px;
  text-transform: capitalize;
  color: #fff;
  font-weight: 600;
  margin-bottom: 14px;
}
.tocken_details h2 {
  font-size: 30px;
  font-weight: bold;
  color: #3b82f6;
  padding: 4px 0px;
}
.tocken_details {
  padding: 15px 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.trade111 {
  color: #fff;
  font-weight: 500;
  margin-right: 10px;
}
.trade111:hover {
  color: #fff;
}

.login_body_ {
  background: none;
  background-size: contain;
  height: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  max-height: 100%;
  min-height: 100%;
  overflow: visible;
  overflow-y: hidden;
  overflow-y: scroll;
}
.search_theme {
  display: flex;
  margin-bottom: 12px;
  overflow: hidden;
  border-radius: 5px;
}
.search_theme input {
  padding: 5px 11px;
  padding: 5px 11px;
  background: #28283c;
  color: #fff;
}
.search_theme .copyicon span {
  margin-left: 0px;
}
.login_content_section {
  padding: 135px 0px;
}
.login_content_section h1 {
  color: #fff;
  font-size: 64px;
  font-weight: 600;
  padding: 20px 0px;
}
.login_content_section p {
  color: #fff;
  font-size: 18px;
  margin-bottom: 30px;
}

.login_content_section .login_btn_n {
  padding: 10px 0px;
}
.login_content_section .btn-primary {
  width: 30%;
  line-height: 16px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: #3b82f6;
  border: none;
  border-radius: 40px;
  padding: 11px 10px;
}
.form_login_section {
  padding: 50px 0px;
}
.form_login_section h1 {
  color: #fff;
  font-size: 36px;
  margin: 0;
  padding: 10px 0px;
}
.form_login_section p {
  margin: 0;
  color: #fff;
  padding: 5px 0px;
}
.form_login_section .login_with_gool {
  padding: 18px 0px;
}
.form_login_section .login_with_gool button {
  width: 100%;
  padding: 12px 10px;
  border: 1px solid #ccc;
  background: #000;
  border-radius: 10px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login_with_gool img {
  margin-right: 10px;
}
.form_login_section span.or_login_section {
  color: #fff;
  width: 100%;
  display: block;
  text-align: center;
  position: relative;
  font-size: 16px;
  color: #b2bbcc;
}
.form_login_section span.or_login_section::after {
  content: "";
  position: absolute;
  height: 2px;
  width: 142px;
  color: #fff;
  background: #eff2f7;
  right: 0;
  top: 12px;
}
.form_login_section span.or_login_section::before {
  content: "";
  position: absolute;
  height: 2px;
  width: 142px;
  color: #fff;
  background: #eff2f7;
  left: 0;
  top: 12px;
}
.register_login {
  padding: 50px 0px;
}
.register_login label {
  font-size: 18px;
  color: #fff;
  margin-bottom: 10px;
  font-weight: 400;
}
.register_login .form-control {
  height: 43px;
  background: #1a223f;
  border: 1px solid #b6bece;
  color: #fff;
}
.register_login label {
  font-size: 18px;
  color: #fff;
  margin-bottom: 10px;
  font-weight: 400;
}
.register_login .btn-primary {
  /* background: #3b82f6; */
  border: none;
  margin: 22px auto;
  padding: 13px 10px;
  display: block;
  background: linear-gradient(
    90.15deg,
    #5c2d91 0.73%,
    #502abb 101.92%
  ) !important;
}
.form_login_section p {
  margin: 0;
  color: #fff;
  padding: 5px 0px;
}
/* .bottom_linnk a {
  -webkit-text-fill-color: transparent;
  background: #3b82f6;
  background-clip: border-box;
  -webkit-background-clip: text;
  padding: 0;
  font-size: 17px;
  font-weight: 600;
} */
.register_login a.forget {
  width: 100%;
  display: block;
  text-align: right;
  color: #786cc0;
}
.bottom_linnk {
  justify-content: center;
  display: flex;
  align-items: center;
}
.register_login h1 {
  color: #fff;
  text-align: center;
  margin: 0;
  font-size: 2.5rem;
}
.register_login p {
  color: #000;
  text-align: center;
  padding: 8px 0px;
}
.register_login select {
  height: 53px;
  background: #1a223f;
  border: 1px solid #b6bece;
  color: #fff;
  width: 100%;
  border-radius: 5px;
  padding: 0px 10px;
}
.register_login .swaps {
  height: 53px !important;
  background: #2c2c2c !important;
  border: none !important;
  color: #acacac !important;
  width: 100%;
  border-radius: 10px !important;
  padding: 0px 10px !important;
}
.padin_otp {
  padding: 176px 0px;
}
.register_login h1 {
  color: #fff;
  text-align: center;
  margin: 0;
}
.register_login p {
  color: #000;
  text-align: start;
  padding: 8px 0px;
}
.register_login span {
  width: 100%;
  display: block;
  text-align: center;
  color: #fff;
  font-size: 13px;
  padding-bottom: 18px;
}
.height_formrr {
  height: auto;
  min-height: auto !important;
  margin-bottom: 40px !important;
}
.otp_content_e {
  display: flex;
  align-items: center;
  justify-content: center;
}
.otp_content_e .form-group {
  /* width: 10%; */
  padding-top: 15px;
  display: flex;
}
.otp_content_e .form-control {
  margin: 0 10px;
  width: auto;
  width: 100%;
}
.otp_content_e input {
  width: 32px;
  height: 32px;
  text-align: center;
  margin-right: 20px;
  height: 53px !important;
  background: #1a223f;
  border: 1px solid #b6bece;
  color: #fff;
  padding: 10px 7px;
  width: 55px !important;
  margin: 0 15px !important;
}
.w-70 {
  width: 70% !important;
}
button.class_buutn_resend {
  background: transparent !important;
  border: 1px solid #9b59d5 !important;
  border: none !important;
  font-size: 22px !important;
  margin: 0 auto;
  display: block;
  margin-bottom: 12px;
}
button.class_buutn_resend span {
  padding: 0;
  font-size: 16px;
}
.profile_section {
  background: #313545;
  border-radius: 5px;
  padding: 11px 12px;
  margin-top: 100px;
}
.profile_section .nav-tabs {
  display: flex;
  flex-direction: column;
  border: none;
  margin: 0 10px;
}
.my_fund_content {
  padding-top: 60px;
}
.my_fund_content .bg_card_section {
  background: #2b2b40cc;
  padding: 21px 25px;
  margin: 15px 0px;
  border-radius: 6px;
}
.my_fund_content .my_fund_sectin_ h1 {
  margin: 0;
  color: #fff;
  font-weight: 600;
  font-size: 35px;
}
.my_fund_content .my_fund_sectin_ .search_row {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.my_fund_content .my_fund_sectin_ .search_row .input_rpow {
  padding: 5px 26px;
  border: 1px solid #353945;
  border-radius: 36px;
}
a.active .header_icon_meniu {
  color: #fff !important;
  background: linear-gradient(
    90.15deg,
    #5c2d91 0.73%,
    #502abb 101.92%
  ) !important;
  border-radius: 6px;
  filter: none;
}
.my_fund_content .my_fund_sectin_ .search_row .input_rpow input {
  background: transparent;
  border: none;
  height: 50px;
  color: #fff;
}
.my_fund_content .my_fund_sectin_ .search_row .input_rpow i {
  font-size: 21px;
  color: #777e90;
}
.my_fund_content .my_fund_sectin_ .search_row select {
  height: 60px;
  padding: 0px 18px;
  border-radius: 38px;
  border: none;
  background: #353945;
  color: #fff;
  font-weight: 600;
}
.my_fund_content .my_fund_sectin_ .search_row .btn-primary {
  height: 60px;
  background: #3b82f6;
  border: none;
  border-radius: 52px;
  font-weight: 600;
}
.my_fund_content .balance_section p {
  margin: 0;
  color: #b1b5c3;
  font-size: 22px;
  font-weight: 600;
}
.my_fund_content .balance_section h3 {
  color: #fff;
  font-size: 40px;
  font-weight: 600;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.my_fund_content .balance_section h3 span {
  background: #58bd7d;
  padding: 6px 11px;
  border-radius: 10px;
  font-size: 18px;
  color: #141416;
  margin: 14px 9px;
}
.my_fund_content .balance_section small {
  font-size: 20px;
  color: #777e90;
  font-weight: 600;
  margin-bottom: 12px;
}
.my_fund_content .title_aa {
  margin: 0;
  color: #777e90 !important;
}
.my_fund_content .bg_card_section .balanc_avail p {
  margin: 0;
  color: #fff;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.my_fund_content .bg_card_section .balanc_avail p img {
  margin-right: 5px;
}
.my_fund_content .bg_card_section .balanc_avail h5 {
  margin: 0;
  font-weight: 600;
  color: #fff;
  padding-bottom: 10px;
}
.my_fund_content .bg_card_section .balanc_avail span {
  color: #777e90;
  width: 100%;
  display: block;
  text-align: right;
  font-weight: 600;
}
.my_fund_content .bg_card_section .button_account_bottn {
  padding: 20px 0px;
  padding-bottom: 20px;
  padding-bottom: 0;
  border-top: 1px solid #23262f;
  margin-top: 20px;
}
.my_fund_content .bg_card_section .button_account_bottn button {
  border: 2px solid #777e90;
  background: transparent;
  padding: 8px 16px;
  border-radius: 30px;
  color: #fff;
  margin-right: 10px;
}
.my_fund_content .bg_card_section .button_account_bottn button.green {
  color: #58bd7d;
}
.my_fund_content .bg_card_section .balanc_avail {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.bg_card_section {
  background: #2b2b40cc;
  padding: 21px 25px;
  margin: 15px 0px;
  border-radius: 6px;
}
.bg_card_section .table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid #23262f;
  border-top: none;
  color: #fff;
}
.bg_card_section .table td,
.bg_card_section .table th {
  border-bottom: 1px solid #23262f;
}
.coin_table_ta {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.coin_table_ta img {
  width: 35px;
}
div.row_eee {
  margin-left: 9px;
}
div.row_eee h3 {
  font-size: 18px;
  margin: 0;
  color: #fff;
}
div.row_eee span {
  color: #8c92ab;
  font-weight: 400;
}
.flex_time_xxx {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.color_box_grrn {
  color: #fff;
  background: #418f5d;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
}
.row_oiu_p h5 {
  margin: 0;
  color: #fff;
  text-align: right;
  font-weight: 600;
  font-size: 17px;
}
.row_oiu_p span {
  width: 100%;
  display: block;
  text-align: right;
  color: #777e90;
  margin-top: 8px;
  font-weight: 600;
}
.kyc_card {
  background: #28283c;
  border-radius: 15.96px;
  padding: 10px 15px;
  margin: 0 auto !important;
}
.kyc_card .ky_c_contnet {
  border-right: 0 solid #525267;
  padding-right: 20px;
  margin: 10px auto;
}
.kyc_card .ky_c_contnet img {
  margin: 0 auto;
  display: block;
  width: 150px;
}
.kyc_card .ky_c_contnet p {
  text-align: center;
  color: #afafaf;
  font-weight: 500;
  margin: 0 0 16px;
  font-size: 13px;
}
.contet_kyc_section > p {
  margin: 0;
  color: #fff;
  padding: 10px 0;
}
.contet_kyc_section .status_done {
  color: green !important;
}
.flex_active_ {
  display: flex;
  justify-content: flex-start;
}
.flex_active_ {
  margin-top: 10px;
}
.kyc_card .select_section p {
  margin: 0;
  padding: 5px 10px;
  text-align: center;
  color: #4e3e3e;
  font-size: 15px;
}
.select_section.active {
  padding: 5px;
  background: url(../src/img/activetab.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.select_section.active p {
  color: #fff;
}
.select_section {
  padding: 5px;
  background: url(../src/img/nonactive.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.flex_inpur {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
}
.foem_flex_input {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: 10px;
  margin-right: 25px;
}
.foem_flex_input label {
  margin: 0;
  padding: 10px 0;
  color: #fff;
}
.input_section_kyc {
  position: relative;
  background: #2c2c2c;
  border-radius: 10px;
  padding: 23px;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 129px;
  height: 129px;
}
.input_section_kyc img {
  width: 100%;
  height: auto;
  max-height: 100%;
  max-width: 100%;
}
.input_section_kyc input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.light .input_section_kyc {
  background: #f6f9fe;
}
.detaile_foem_kyc {
  margin-top: 23px;
}
.detaile_foem_kyc p {
  padding: 10px 0;
  color: #fff;
  margin: 0 0 20px;
}
.detaile_foem_kyc .form-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 20px;
  margin: 0;
  min-height: 129px;
}
.detaile_foem_kyc .form-group label {
  color: #fff;
}
.bg-cover {
  background-size: cover !important;
}
.tab_sectio_wallet .nav-tabs {
  border-bottom: 0 solid #dee2e6;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 0 0;
}
.tab_sectio_wallet .nav-tabs a {
  color: #fff;
  padding: 6px 11px !important;
}
.tab_sectio_wallet .nav-tabs a.active {
  color: #3b82f6;
  font-size: 22px;
}
.wallet_overview .table_section_ {
  padding: 40px 0;
  height: 100vh;
}
.bg_card_section {
  background: #2b2b40cc;
  padding: 21px 25px;
  margin: 15px 0;
  border-radius: 6px;
}
.wallet_overview .bg_card_section {
  padding: 21px 5px;
}
.wallet_overview table {
  table-layout: auto;
}
.wallet_overview .table td,
.wallet_overview .table th {
  border-bottom: 1px solid #23262f;
  padding: 10px 7px;
}
.wallet_overview .table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid #23262f;
  border-top: none;
  color: #5d6588;
  font-weight: 500;
}
.coin_table_ta img {
  width: 35px;
}
div.row_eee {
  margin-left: 9px;
}
.wallet_overview div.row_eee {
  margin-left: 9px;
  display: flex;
}
div.row_eee h3 {
  font-size: 18px;
  margin: 0;
  color: #fff;
}
.wallet_overview div.row_eee h3 {
  margin-right: 12px;
  font-size: 16px;
  line-height: 23px;
}
div.row_eee span {
  color: #777e90;
}
.wallet_overview .row_oiu_p {
  display: flex;
  justify-content: center;
  align-items: center;
}
.row_oiu_p h5 {
  margin: 0;
  color: #fff;
  font-size: 16px;
}
.row_oiu_p h5,
.row_oiu_p span {
  text-align: left;
  font-weight: 500;
}
.wallet_overview .row_oiu_p h5 {
  text-align: left;
  font-weight: 500;
  font-size: 14px;
}
.prime_deposit button {
  border: none;
  background: #3b82f6;
  font-size: 15px;
  color: #fff;
  padding: 8px 10px;
  margin: 0 4px;
  border-radius: 22px;
}
.prime_deposit {
  white-space: nowrap;
}
.prime_deposit button {
  padding: 8px 22px;
}
.prime_deposit button {
  padding: 8px 10px;
  padding: 8px 22px;
  border: none;
  background: #3b82f6;
  font-size: 15px;
  color: #fff;
  padding: 8px 10px;
  margin: 0 4px;
  border-radius: 22px;
  padding: 8px 22px;
  background: linear-gradient(90.15deg, #5c2d91 0.73%, #502abb 101.92%);
  border-radius: 8px;
}
.prime_deposit button {
  padding: 8px 22px;
}
.prime_deposit .deposit_p {
  background: #0000;
  border: 1px solid #fff;
}
.table td,
.table th {
  vertical-align: middle !important;
}
.wallet_overview .bg_card_section {
  padding: 21px 5px;
}
.balance_section {
  padding: 0 20px;
}
.balance_section h3 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 0 0 12px;
  color: #fff;
}
.balance_section h3 img {
  width: 37px;
  margin-right: 3px;
}
.balance_section .padin_cls_00 {
  padding: 10px 0;
}
.balance_section .padin_cls_00 span {
  color: #5d6588;
}
.balance_section .padin_cls_00 h1 {
  margin: 0;
  color: #fff;
  padding: 7px 0;
}
.balance_section .padin_cls_00 p {
  color: #a5adcf;
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 12px;
}
.form_wiuthdrawl p {
  color: #fff;
  margin: 0 0 20px;
}
.form_wiuthdrawl label {
  color: #fff;
  margin: 0 0 5px;
}
.form_wiuthdrawl .prime_deposit {
  margin-bottom: 15px;
}
.form_wiuthdrawl input {
  background: #0000;
  border: none;
  border-bottom: 1px solid #34384c;
  height: 40px;
  color: #fff;
  width: 43%;
}
.wallet_overview .table td {
  border-bottom: 1px solid #23262f;
  padding: 10px 7px;
  color: #fff;
  text-align: center;
}
.profile_section .nav-tabs li {
  display: flex;
  /* padding: 17px 0; */
  margin: 0;
  margin: 5px 0px;
}
.profile_section .nav-tabs li a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #777e91;
  padding: 17px 10px;
  width: 100%;
}
.profile_section .nav-tabs li a:nth-child(2) {
  padding: 0 !important;
}
.profile_section .nav-tabs li a.active {
  color: #fff;
}
.profile_section .nav-tabs li a img {
  width: 24px;
  margin-right: 6px;
}
.profile_section .cars_bg_ss {
  padding: 10px 20px;
  box-shadow: 0 64px 64px -48px #0f0f0f1a;
  background: #2b2b40cc;
  border-radius: 16px;
  min-height: 577px;
}
.profile_section .cars_bg_ss .name_section_profile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.profile_section .cars_bg_ss .name_section_profile h1 {
  font-size: 31px;
  margin: 0 0 15px;
  padding-top: 10px;
  color: #fff;
}
.profile_section .cars_bg_ss .name_section_profile button {
  padding: 5px 13px;
  background: #0000;
  border: 2px solid #353945;
  border-radius: 23px;
  color: #58bd7d;
}
.profile_section .cars_bg_ss .futer_section h1 {
  margin: 0;
  font-size: 24px;
  color: #fff;
  padding: 20px 0;
}
.profile_section .cars_bg_ss .futer_section .tilet_head {
  color: #b1b5c3;
  text-transform: uppercase;
  font-weight: 600;
  padding-bottom: 16px;
  border-bottom: 1px solid #353945;
  margin: 0 0 20px;
}
.profile_section .cars_bg_ss .futer_section .flex_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
.profile_section .cars_bg_ss .futer_section .flex_container p {
  margin: 0;
  color: #fff;
}
.profile_section .cars_bg_ss .futer_section .flex_container span {
  color: #777e90;
  display: flex;
  text-align: right;
}
.sessions__title {
  margin-bottom: 32px;
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600;
  letter-spacing: -0.01em;
  color: #fff;
}
.sessions__table {
  display: table;
  width: 100%;
}
.sessions__row {
  display: table-row;
}
.sessions__col {
  display: table-cell;
  padding-top: 16px;
  padding-bottom: 16px;
}

.sessions__row:first-child .sessions__col {
  border-bottom: 1px solid #4d4e4e;
  font-size: 15px;
  line-height: 1.66667;
  font-weight: 600;
  color: #8b8b8b;
}
.sessions__content {
  font-weight: 500;
  color: #fff;
}
.sessions__note {
  color: #777e90;
}
.sessions__col:last-child {
  text-align: right;
}
.text_wrapaa {
  width: 153px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.security__title {
  margin: 0;
  color: #fff;
  padding: 10px 0;
}
.security__title span {
  color: #58bd7d;
}
.security__text {
  margin-bottom: 40px;
  font-size: 15px;
  line-height: 1.66667;
  color: #a5a6a8;
}
.security__text {
  margin-bottom: 40px;
  font-size: 15px;
  line-height: 1.66667;
  color: #a5a6a8;
}
.security__subtitle {
  margin-bottom: 8px;
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600;
  letter-spacing: -0.01em;
  color: #fff;
}
.security__info {
  margin-bottom: 24px;
  color: #a5a6a8;
}
.security__email {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  color: #fff;
}
.security__email .icon {
  width: 24px;
  height: 24px;
  margin-right: 12px;
  fill: #23262f;
  font-size: 25px;
  color: #fff;
}
.security__form {
  display: flex;
  padding: 16px;
}
.flex_form_clas {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.security__row {
  display: flex;
  margin: 0 -8px;
}
.security__row .field {
  position: relative;
  margin: 0 8px 13px;
}
.field__label {
  margin-bottom: 12px;
  font-size: 12px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  color: #b1b5c3;
}
.field__wrap {
  position: relative;
}
.field_icon .field__input,
.field_view .field__input {
  padding-right: 48px;
}
.field_11 {
  display: flex;
  justify-content: center;
  width: 100%;
}
.field__wrap {
  position: relative;
}
.button_www {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 0 24px;
  background: #3b82f6;
  border-radius: 24px;
  /* font-family: DM Sans, sans-serif; */
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  color: #fcfcfd;
  transition: all 0.2s;
  border: none;
  margin-top: 23px;
  display: block;
  margin: 21px auto;
  background: linear-gradient(
    90.15deg,
    #5c2d91 0.73%,
    #502abb 101.92%
  ) !important;
  border-radius: 6px;
  filter: drop-shadow(1px 2px 2px #828282);
}
.flex_form_clas button:disabled,
.flex_form_clas button[disabled] {
  opacity: 0.4;
}
.field__input {
  height: 48px;
  padding: 0 14px;
  color: #fff;
}
.field__input,
.field__textarea {
  width: 100%;
  border-radius: 12px;
  border: 2px solid #e6e8ec;
  box-shadow: none;
  background: none;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 1.71429;
  font-weight: 500;
  color: #23262f;
  transition: border-color 0.2s;
}
input[type="number"] {
  -moz-appearance: textfield;
  -webkit-appearance: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.change_password_section {
  width: 65%;
  margin: 0 auto;
}
.change_password_section h1 {
  text-align: center;
  color: #fff;
  font-size: 29px;
  margin: 21px 0 0;
}
.change_password_section .register_login {
  padding: 25px 0;
}
.change_password_section label {
  color: #b1b5c3;
}
.change_password_section .register_login .form-control {
  height: 57px;
  background: #0000;
  border: 1px solid #939393;
  color: #fff;
  border-radius: 8px;
}
.homepage-section-banner {
  position: relative;
  padding-top: 0;
  margin-top: -82px;
}
.homepage-section-banner > .container {
  position: absolute;
  z-index: 8;
  top: 100px;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.home_vedio {
  width: 100vw;
  /* height: 100vh; */
  position: relative;
}
.home_vedio::after {
  content: "";
  width: 100%;
  height: 100vh;
  /* background: #15151552; */
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 10%,
    rgb(11, 12, 18) 100%
  );
  position: absolute;
  z-index: 5;
  top: 0;
}
.home_vedio-new::after {
  content: "";
  width: 100%;
  height: 100vh;
  /* background: #15151552; */
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 10%,
    rgb(11, 12, 18) 100%
  );
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
}
.posiieee {
  position: relative;
  z-index: 6;
}
.home_vedio video {
  width: auto !important;
  height: auto !important;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -16%);
}
.section-2-s {
  margin-top: 85px;
}
.section_4 {
  position: relative;
}
.section_4::before {
  content: "";
  background: url(../src/img/circle_1.png);
  width: 100%;
  height: 100%;
  position: absolute;
  background-position: center;
}
.section_4 .card_ {
  background: #2b2b4099;
  border-radius: 20px;
  margin: 71px auto 10px;
  position: relative;
}
.section_4 .card_ .content {
  padding: 70px 38px;
}
.section_4 .card_ .content h2 {
  font-size: 30px;
  color: #fff;
  margin: 0;
}
.section_4 .card_ .content p {
  margin: 0;
  color: #b0c0e0;
  padding: 20px 0;
}
.section_4 .button_section {
  padding: 25px 0;
}
.section_4 .button_section button:first-child {
  background: #3b82f6;
  border: none;
  color: #fff;
  padding: 12px 16px;
  border-radius: 10px;
  margin-right: 10px;
}
.section_4 .button_section button:nth-child(2) {
  background: #0000;
  color: #fff;
  padding: 11px 16px;
  border-radius: 10px;
  margin-right: 10px;
  border: 1px solid #7956e1;
}
.section_4 .card_ img {
  width: 88%;
  margin: 30px auto;
  display: block;
}
.section_6 .table_ {
  background: #2f324180;
  padding: 14px;
  border-radius: 15px;
}
.table_ .table thead th {
  font-weight: 500;
  white-space: nowrap;
}
.table_ .table td,
.table_ .table th {
  vertical-align: middle;
  padding: 10px 0;
  font-weight: 400;
  color: #fff;
}
.table_ .table td,
.table_ .table th {
  border-bottom: 1px solid #0000;
}
.table_ .coin_devlob {
  display: flex;
}
.table_ .coin_devlob h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #fff;
  margin: 0;
}
.table_ .coin_devlob h1 img {
  margin-right: 8px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
}
.table_ .coin_devlob h1 span {
  font-size: 16px;
  margin-left: 10px;
  color: #3b82f6;
}
.table_ .table td,
.table_ .table th {
  border-bottom: 1px solid #0000;
  border-top: 0;
}
.link_more {
  width: 100%;
  text-align: center;
  display: block;
  padding: 10px 0;
  color: #fff;
}
.justify-centernew {
  justify-content: center !important;
}
.coin_devlob span {
  margin-left: 10px;
  color: #999;
}
.pagination {
  justify-content: flex-end;
}
.page-item.disabled .page-link {
  background-color: transparent;
  border-color: #6d7073;
}
.page-item.active .page-link {
  background-color: #4f41a4;
  border-color: #4f41a4;
}
.page-link {
  background-color: transparent;
  border-color: #6d7073;
  color: #fff;
}
.detaile_foem_kyc .form-control {
  background: transparent;
  height: 53px;
  color: #fff;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  color: #bfbfbf;
}
.range_btn {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
}
.foem_section.color_padinr {
  background: #181818;
  padding: 20px;
  padding-top: 0;
  overflow: hidden;
  border-radius: 0px 0px 16px 16px;
  padding-bottom: 0px !important;
}

.data_limit_p2p p:first-child {
  margin-top: 0;
  /* justify-content: space-between; */
}

.range_btn > button.active {
  padding: 4px 6px !important;
  color: #9685ff;
  width: 54px;
  min-width: auto;
  border-color: #4f41a4;
}
.range_btn > button.active:hover {
  color: #9685ff;
  border-color: #4f41a4 !important;
}
.range_btn > button {
  padding: 4px 6px;
  border: 1px solid #3d3d3d;
  background: transparent;
  color: #fff;
  width: 54px;
  min-width: auto;
  margin: 5px 0px;
  font-size: 12px;
  border-radius: 16px;
}
.range_btn > button:hover {
  padding: 4px 6px;
  /* border: 1px solid #3b83f600; */
  /* background: linear-gradient(
    90.15deg,
    #5c2d91 0.73%,
    #502abb 101.92%
  ) !important; */

  /* color: #fff; */
}

.foem_group.market_table .form-group {
  margin-top: 10px !important;
  background: #202020 !important;
  border-color: #202020 !important;
  border-radius: 10px !important;
  padding: 0px 3px !important;
  margin-bottom: 10px !important;
}
.notification {
  color: #000;
  border: 1px solid #ccc;
  border-radius: 50px;
  height: 40px;
  width: 40px;
  /* display: flex; */
  padding: 0px 3px;
}
.staking_title {
  padding: 20px 30px;
  border: 1px solid #383838;
  border-radius: 10px;
  margin-bottom: 25px;
  background: #313545;
  border-radius: 15px;
}
.staking_title h1 {
  font-size: 41px;
  color: #fff;
  padding: 5px 0px;
  /* margin-bottom: 10px; */
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
}
.staking_price {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-allowww {
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: flex-start;
}
.flecx-ssss {
  justify-content: flex-end;
}
.flecx-ssss > div.clocc {
  margin-left: 65px;
  margin-right: 0 !important;
}
.flex-allowww > div.clocc {
  margin-right: 65px;
}
.staking_price > div {
  padding: 14px 10px;
  margin-right: 15px;
}
.staking_price h3 {
  font-size: 20px;
  color: #fff;
}
.staking_price p {
  font-size: 20px;
  color: #3b82f6;
  font-weight: 600;
  margin: 7px 0px;
  margin-bottom: 0;
  color: #fff !important;
  background: -webkit-linear-gradient(91.43deg, #5c2d91 2.55%, #9f02b9 123.64%);
  background-clip: border-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* border-bottom: 3px solid #7a2da4; */
}
.staking_optoikn ul li a.active {
  font-weight: 600;
  margin: 7px 0px;
  margin-bottom: 0;
  color: #fff !important;
  background: -webkit-linear-gradient(91.43deg, #5c2d91 2.55%, #9f02b9 123.64%);
  background-clip: border-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-bottom: 2px solid #7a2da4;
}
#LockedStaking .fixTableHead .minimum p {
  background: transparent;
  border: none;
  color: #3b82f6;
}
.staking_optoikn ul {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}
.staking_optoikn ul li {
  display: flex;
  margin: 0;
}
.staking_optoikn ul li a {
  padding: 5px 20px;
}
.staking_optoikn .fixTableHead th {
  background: #2f3241;
  text-align: center;
  z-index: 6;
}
.staking_optoikn .fixTableHead {
  height: 521px;
}
.fixTableHead .price h1 {
  display: flex;
  font-size: 20px;
  align-items: center;
}
.fixTableHead .price h1 img {
  width: 21px;
  height: 21px;
  margin-right: 8px;
}
.fixTableHead .aPY {
  width: auto;
  min-width: 65px;
  padding: 5px 6px;
  max-width: 75px;
  background: #2a2b3e;
  border-radius: 10px;
  color: #fff;
  border: 1px solid #2a4886;
  text-align: center;
}
.fixTableHead .duration {
  display: flex;
  justify-content: center;
  align-items: center;
}
.fixTableHead .duration button {
  display: flex;
  flex-direction: column;
  background: #2a2b3d;
  padding: 2px 0px;
  font-size: 13px;
  width: auto;
  color: #fff;
  border: 1px solid #082e73;
  margin-right: 10px;
}
.fixTableHead .duration button small {
  margin-left: 5px;
}
.fixTableHead .duration button.active {
  background: linear-gradient(
    90.15deg,
    #5c2d91 0.73%,
    #502abb 101.92%
  ) !important;
  border-color: #3b82f600;
  color: #fff;
}
.fixTableHead .type p {
  width: auto;
  background: #ff000030;
  padding: 4px 0px;
  border: 1px solid #742828;
  border-radius: 28px;
}
.fixTableHead .minimum {
  display: flex;
  justify-content: center;
  align-items: center;
}
.fixTableHead .minimum p {
  font-size: 13px;
  padding: 7px 12px;
  background: #00800040;
  border-radius: 21px;
  border: 1px solid #066606;
  color: #21db21;
  margin-right: 15px;
  width: 100px;
  text-align: center;
}

/* Light mode */

.light .new_buyform .formplac li a.active {
  border: 1px solid #553bf5;
  background: #553bf5;
  border-radius: 16px !important;
  color: #fff;
  -webkit-text-fill-color: snow;
}

.fixTableHead .minimum button {
  text-align: center;
  text-transform: capitalize;
  color: #fff;
  background: linear-gradient(
    90.15deg,
    #5c2d91 0.73%,
    #502abb 101.92%
  ) !important;
  padding: 4px 15px;
}
.h100vh {
  height: 100vh;
}
.foem_group .text-primary {
  color: #fff !important;
  font-size: 13px;
  font-weight: 400;
}
.foem_group {
  padding: 20px 0px;
}
.cl_odere_ee li a {
  padding: 6px 7px;
  margin-right: 0px;
  color: #777e90;
  font-weight: 400;
  font-size: 14px;
}
.cl_odere_ee {
  margin-bottom: 15px;
  margin-top: 0;
}
.tradepage-bg .container {
  max-width: 100%;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.home header {
  background: transparent;
}
.class_pair_table .dropdown-menu {
  min-width: 375px;
  padding: 10px 8px;
  background: rgb(11, 12, 18) none repeat scroll 0% 0%;
}
.class_pair_table .search_option {
  margin-bottom: 14px;
}
#dropdownMenuLink {
  background: transparent;
  color: #fff !important;
}
.modal-content {
  padding: 10px 10px;
  border: 1px solid #383838;
  margin-bottom: 25px;
  background: #2f324180;
  border-radius: 15px;
}
.stakingtext_new {
  display: flex;
}
.stakingtext_new p {
  padding: 3px 9px !important;
  font-size: 15px;
  text-align: center;
  color: red;
  margin-right: 6px;
  font-weight: 400;
}
.fixTableHead .stakingtext_new .minimum p {
  font-size: 13px;
  padding: 3px 9px;
  background: #00800040;
  border-radius: 21px;
  border: 1px solid #066606;
  color: #21db21;
  margin-right: 15px;
  font-weight: 400;
  font-size: 13px;
}
#stacknow .fixTableHead .price h1 {
  display: flex;
  font-size: 20px;
  align-items: center;
  color: #fff;
}
.total_stak_prohress {
  padding: 5px 9px;
  border: 1px solid green;
  margin: 10px 0px;
  border-radius: 8px;
  background: #0080004f;
  margin-top: 20px;
}
.stake_count p {
  text-transform: uppercase;
  font-size: 14px;
  color: #fff;
  letter-spacing: 0.3px;
}
.stake_count h1 {
  font-size: 22px;
  color: #3b82f6;
}
.progresss_s p {
  text-align: right;
  color: #999;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  font-size: 12px;
  margin-bottom: 9px;
}
.progresss_s h3 {
  padding: 5px 0px;
  display: flex;
  justify-content: space-between;
  color: #eaeaea;
}
.progre .progress {
  display: -ms-flexbox;
  display: flex;
  height: 9px;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #1a1d2f;
  border-radius: 0.25rem;
}
.progre .progress-bar {
  width: 15%;
}
.modal-backdrop.show {
  opacity: 0.8;
}
.input_filed_for_stake .title_ {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.input_filed_for_stake .title_ h1 {
  font-size: 20px;
  color: #fff;
}
.input_filed_for_stake .title_ a {
  color: #3b82f6;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}
.input_filed_for_stake .title_ a i {
  margin-top: -11px;
  margin-left: 6px;
}
.input_obx {
}
.inpurrr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #2a2b3e;
  border: 1px solid #2e5ba8;
  border-radius: 5px;
}
.inpurrr input {
  height: 48px;
  background: transparent;
  color: #fff;
  padding: 0 5px;
  width: 89%;
}
.inpurrr .instrucion {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.input_obx p {
  color: #b1b1b1;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0;
}
.instrucion p {
  padding: 3px 10px;
  background: #305536;
  border-radius: 13px;
  font-weight: 400;
  color: #04c204;
  margin: 0 4px;
  cursor: pointer;
}
.instrucion h5 {
  color: #fff;
  font-size: 13px;
  margin: 0 4px;
}
.instrucion small {
  color: #999;
  font-size: 11px;
  font-weight: 400;
  margin: 0 8px;
}
.data_coin_ {
  padding: 20px 0;
}
.summery h1 {
  font-size: 20px;
  color: #fff;
  margin-bottom: 10px;
}
.summery p {
  font-size: 14px;
  color: #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  padding: 3px 0;
}
.summery hr {
  border: 0.5px solid #424242;
  margin: 12px 0px;
}
.summery h4 {
  color: #fff;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  padding: 3px 0px;
}
.summery button,
.summery button:hover {
  width: 100%;
  background: linear-gradient(
    90.15deg,
    #5c2d91 0.73%,
    #502abb 101.92%
  ) !important;
  color: #fff;
  padding: 9px 0;
  margin-top: 15px;
}
header {
  padding: 10px 0px;
  border-bottom: 0px solid #ffffff14;
  position: relative;
  z-index: 9;
}
.launcpad {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.launcpad button {
  padding: 9px 33px;
  border-radius: 12px;
  background: linear-gradient(90.15deg, #5c2d91 0.73%, #502abb 101.92%);
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
}
.launcpad button:hover {
  background: linear-gradient(90.15deg, #5c2d91 0.73%, #502abb 101.92%);
  color: #fff;
}
.launcpad button:hover a {
  color: #fff;
}
.launcpad p {
  color: #999;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #8c92ab;
}
.launch_card {
  padding: 18px 25px;
  border: 0px solid #383838;
  margin-bottom: 25px;
  background: #313545;
  position: relative;
  border-radius: 7px;
}
.launch_card .details p span {
  width: 50%;
}
.launch_card .token_img_ {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.launch_card .token_img_ img {
  width: 46px;
  margin: 0;
  margin-right: 12px;
}
.launch_card .token_img_ h3 {
  font-size: 20px;
  color: #fff;
  padding: 7px 0;
}
.launch_card .token_img_ p {
  color: #999;
}
.input_select_s .input_bg {
  background: transparent !important;
  padding: 3px 1px;
  width: 60px;
  height: fit-content;
  margin-left: 10px;
}
.launch_card button,
.launch_card button:hover {
  margin: 11px auto;
  display: block;
  background: #3b82f6;
  text-transform: capitalize;
  color: #fff;
  padding: 6px 48px;
  font-size: 16px;
  border-radius: 14px;
  background: linear-gradient(90.15deg, #5c2d91 0.73%, #502abb 101.92%);
  border-radius: 8px;
}
.launch_card .details {
  padding: 5px 0;
}
p.balance {
  text-align: center;
  border: none !important;
}
.launch_card .details p {
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.launch_card .details p span.text_uniqwe {
  color: #71a5fc;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
}
.trens {
  background: transparent !important;
  color: #999 !important;
}
.launch_card .details .progress {
  height: 8px;
  background-color: #454556;
}
.launch_card .details .progress-bar {
  background-color: #007bff;
  width: 20%;
  border-radius: 5px;
}
.launch_card label {
  margin: 0;
  position: absolute;
  color: #fff;
  background: #3b82f6;
  padding: 4px 11px;
  top: 18px;
  left: 0;
  font-size: 12px;
  border-radius: 0 15px 15px 0px;
  display: flex;
}
.launch_card label.green_ww {
  background: green;
}
.launch_card label i {
  margin-top: -3px !important;
  margin-right: 4px;
}
.launch_card label {
}
/* .bg-contain {
  background-size: contain !important;
} */
.title_launce {
  font-size: 24px;
  color: #fff;
  margin: 22px 0px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.stacknoww {
}
.upcomming {
  color: #fff !important;
}
.view_now .title_section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.view_now .title_section img {
  width: 89px;
  margin-bottom: 16px;
}
.view_now .title_section h3 {
  font-size: 24px;
  color: #fff;
  margin-bottom: 17px;
}
.view_now .title_section p {
  color: #ccc;
  margin-bottom: 12px;
}
.view_now .details_set {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}
.view_now .details_set a {
  color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 3px 5px;
  font-size: 14px;
  background: #000;
  border: 1px solid #3c3c3c;
  border-radius: 6px;
  margin: 0 10px;
}
.view_now .details_set a i {
  line-height: 0 !important;
  margin-right: 3px;
}
.price_sectiom_ {
  padding: 10px 0px;
}
.price_sectiom_ p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  color: #ccc !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #8c92ab;
}
.price_sectiom_ p span.priceamt {
  font-size: 18px;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  color: #fff;
}
.buttn_section_ {
  padding: 10px 0;
}
.buttn_section_ button {
  background: #3b82f6;
  width: 160px;
  margin-bottom: 19px;
  border-radius: 10px;
  color: #fff;
  text-transform: capitalize;
  font-size: 16px;
}
.buttn_section_ button:hover {
  background: #3b82f6;
  width: 160px;
  margin-bottom: 19px;
  border-radius: 10px;
  color: #fff;
  text-transform: capitalize;
  font-size: 16px;
}
.buttn_section_ .progress {
  height: 10px;
  background-color: #3a3b3c;
}
.buttn_section_ .progress .progress-bar {
  width: 15%;
  background: #3b82f6;
  border-radius: 5px;
}
.launch_card.new_table h3 {
  color: #fff;
  font-size: 22px;
  padding: 5px 0;
}
.launch_card.new_table p {
  margin: 0;
  padding: 10px 0px;
  border-bottom: 1px solid #4e4e4e;
}
.launch_card.new_table p:last-child {
  border: none;
}
.input_section p {
  color: #fff;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 0px !important;
  border: none !important;
}
.input_section .input_select_s {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.input_section .input_select_s > input {
  height: 43px;
  background: transparent;
  color: #fff;
}
.input_section .select_option {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.input_section .select_option > button {
  padding: 0;
}
.input_section .select_option .select_width {
  width: fit-content;
  margin: 0 !important;
}
.select_width .form-select {
  background: transparent !important;
  border: none !important;
  color: #fff;
  text-align: center;
  padding: 0 !important;
}
.select_width .form-select option {
  color: #7a7a7a;
}
.input_section {
  padding: 5px 10px;
  border: 1px solid #404040;
  border-radius: 8px;
  margin: 15px 0px;
}
.fonr_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 0px;
}
.fonr_icon span {
  color: #fff;
  font-size: 28px;
}
.submiot {
  padding: 15px 0px;
}
button.notactive {
  display: flex;
  background: #2a2b3d !important;
  font-size: 15px;
  width: auto;
  color: #fff !important;
  border: 1px solid #082e73 !important;
  margin-right: 10px;
  justify-content: center;
}
.countdownw p {
  color: #fff;
  font-size: 14px;
  border: none !important;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.countdownw .count_section {
  color: #fff;
  text-align: center;
  padding: 6px 0px;
}
.kyc_must_verify {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.kyc_must_verify span {
  font-size: 100px;
  color: #fff;
  margin: 15px 0;
}
.kyc_must_verify h1 {
  font-size: 27px;
  color: #fff;
  text-align: center;
  margin-bottom: 26px;
}
.launchpad_doce .modal-footer {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.launchpad_doce .completed {
  display: flex;
}
.launchpad_doce .completed i {
  font-size: 105px;
  color: #12d812;
  margin: 0 auto;
  display: block;
}
.launchpad_doce h1 {
  font-size: 20px;
  color: #fff;
  text-align: center;
  margin: 12px 0px;
}
.launchpad_doce p {
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 5px 0px;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  color: #fff;
}

.launchpad_doce button.btn {
  background: #3b82f6;
  padding: 8px 28px;
  color: #fff !important;
  background: linear-gradient(
    90.15deg,
    #5c2d91 0.73%,
    #502abb 101.92%
  ) !important;
  border-radius: 6px;
}
.launchpad_doce button.btn:hover {
  background: #3b82f6;
  padding: 8px 28px;
  color: #fff;
}
.pair_section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 7px;
  padding: 8px 3px;
}
.pair_section.active {
  cursor: pointer;
  padding: 8px 3px;
  border-left: 3px solid #3b82f6;
}
.pair_section:hover {
  cursor: pointer;
  padding: 8px 3px;
  border-left: none;
  background: #e2e2e200;
}
.pair_section img {
  width: 18px;
  margin-right: 1px;
  border-radius: 50px;
  height: 18px;
}
.pair_section .first_div_sec {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 5px;
}
.pair_section .first_div_sec h2 {
  font-weight: 600;
  font-size: 12px;
}
.pair_section .first_div_sec h2 small {
  color: #ababab;
  font-weight: bold;
}
.pair_section .first_div_sec p {
  font-weight: 500;
  font-size: 12px;
}
.pair_section .first_div_sec p.green {
  color: #0fbe0f;
}
.pair_section .first_div_sec p.red {
  color: red;
}
.price_symbol {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 11px;
}
.price_symbol small {
  font-size: 12px;
  line-height: 0;
  margin-bottom: -6px;
}
.price_symbol small > span {
  font-size: 16px;
  line-height: 0;
}

#heading {
  text-transform: uppercase;
  color: #673ab7;
  font-weight: normal;
}

#msform {
  text-align: center;
  position: relative;
  margin-top: 20px;
}

#msform fieldset {
  background: white;
  border: 0 none;
  border-radius: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding-bottom: 20px;

  /*stacking fieldsets above each other*/
  position: relative;
}

.form-card {
  text-align: left;
}

/*Hide all except first fieldset*/
#msform fieldset:not(:first-of-type) {
  display: none;
}

#msform input,
#msform textarea,
#msform select {
  padding: 8px 15px 8px 15px;
  border: 1px solid #666565;
  margin-bottom: 25px;
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
  /* font-family: montserrat; */
  color: #fff;
  background-color: #eceff1;
  font-size: 16px;
  letter-spacing: 1px;
  margin: 0;
  background: transparent;
  height: 50px;
  border-radius: 0px;
}
#msform select option {
  color: #000;
  background: #fff;
}
.custom-control-label::before {
  background-color: #2d2c2c !important;
  border: #4b4e51 solid 1px !important;
}
.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 45px;
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: transparent;
  border: 1px solid #7d7d7e;
  border-radius: 1px;
  margin: 0;
}
.custom-file {
  height: 45px;
}
.custom-file-label::after {
  position: absolute;
  top: 0px;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 45px;
  padding: 0.375rem 0.75rem;
  line-height: 31px;
  color: #fff;
  content: "Browse";
  background-color: #3b82f6;
  border-left: inherit;
  border-radius: 0;
}
.fonrm_card_s {
  background: transparent;
  border: none;
  border-radius: 15px;
  margin-bottom: 25px;
  padding: 10px;
  padding: 12px 22px;
  margin: 0;
}
.fonrm_card_s label {
  width: 100%;
  color: #fff;
  text-align: left;
  font-size: 15px;
  font-weight: 300;
  height: 45px;
}
.pro-sidebar.collapsed .header_icon_meniu i {
  margin-right: 0;
}
.submit_butn_s button {
  font-size: 14px;
  text-transform: capitalize;
  padding: 8px 27px;
  margin-left: 13px;
  background: #3b82f6;
  color: #fff;
}
.submit_butn_s {
  display: flex;
  justify-content: flex-end;
  padding: 5px 0px;
}
.fonrm_card_s h2 {
  font-size: 20px;
  color: #fff;
  text-align: left;
  margin-bottom: 20px;
  padding-top: 10px;
  text-transform: uppercase;
}
.fonrm_card_s h2 small {
  text-transform: capitalize;
}

#msform input:focus,
#msform textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #673ab7;
  outline-width: 0;
}

/*Next Buttons*/
#msform .action-button {
  width: 100px;
  background: #673ab7;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 0px 10px 5px;
  float: right;
}

#msform .action-button:hover,
#msform .action-button:focus {
  background-color: #311b92;
}

/*Previous Buttons*/
#msform .action-button-previous {
  width: 100px;
  background: #616161;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px 10px 0px;
  float: right;
}

#msform .action-button-previous:hover,
#msform .action-button-previous:focus {
  background-color: #000000;
}

/*The background card*/
.card {
  z-index: 0;
  border: none;
  position: relative;
}

/*FieldSet headings*/
.fs-title {
  font-size: 25px;
  color: #673ab7;
  margin-bottom: 15px;
  font-weight: normal;
  text-align: left;
}

.purple-text {
  color: #673ab7;
  font-weight: normal;
}

/*Step Count*/
.steps {
  font-size: 25px;
  color: gray;
  margin-bottom: 10px;
  font-weight: normal;
  text-align: right;
}

/*Field names*/
.fieldlabels {
  color: gray;
  text-align: left;
}

/*Icon progressbar*/
#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  color: lightgrey;
}

#progressbar .active {
  color: #3b82f6;
}
#progressbar li {
  list-style-type: none;
  font-size: 15px;
  width: 20%;
  float: left;
  position: relative;
  font-weight: 400;
}
/*Icons in the ProgressBar*/
#progressbar #account:before {
  content: "1";
}

#progressbar #personal:before {
  /* font-family: FontAwesome; */
  content: "2";
}

#progressbar #payment:before {
  content: "3";
}

#progressbar #confirm:before {
  /* font-family: FontAwesome; */
  content: "4";
}
#progressbar #confirm1:before {
  /* font-family: FontAwesome; */
  content: "5";
}
.fonrm_card_s .custom-radio label {
  width: fit-content;
}

/*Icon ProgressBar before any progress*/
#progressbar li:before {
  width: 50px;
  height: 50px;
  line-height: 45px;
  display: block;
  font-size: 20px;
  color: #ffffff;
  background: #444;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  padding: 2px;
}
.need_meanissss {
  padding: 29px 29px;
  border: 1px solid #383838;
  margin-bottom: 25px;
  background: #313545;
  border-radius: 15px;
}
.need_meanissss input,
.need_meanissss select,
.need_meanissss textarea {
  height: 50px;
  background: transparent;
  border: 1px solid #4d4d4d;
  color: #fff;
  background: #2125337d;
}
.need_meanissss textarea {
  width: 100%;
  padding: 10px;
  height: 100px;
}
.title_blocc {
  text-align: center;
  font-size: 27px;
  color: #fff;
  margin-bottom: 30px;
}
.submit_butn_s button:hover {
  font-size: 14px;
  text-transform: capitalize;
  padding: 8px 27px;
  margin-left: 13px;
  background: #3b82f6;
  color: #fff;
}
.height_100 {
  height: 100%;
}
.need_meanissss .table thead th {
  vertical-align: bottom;
  border-bottom: 0px solid #dee2e6;
}
.need_meanissss .fixTableHead th {
  background: #2f3241;
}
.need_meanissss .fixTableHead {
  overflow-y: auto;
  height: 275px;
}
.flexnew_center {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.need_meanissss .form-control:focus {
  color: #fff;
  background-color: #2125337d;
  border-color: #4d4d4d;
  outline: 0;
  box-shadow: none;
}
/*ProgressBar connectors*/
#progressbar li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: #2f314d;
  position: absolute;
  left: 0;
  top: 25px;
  z-index: -1;
}
#progressbar {
  position: relative;
  z-index: 7;
}
/*Color number of the step and the connector before it*/
#progressbar li.active:before,
#progressbar li.active:after {
  background: #3b82f6;
}

/*Animated Progress Bar*/
.progress {
  height: 20px;
}

.progress-bar {
  background-color: #673ab7;
}

/*Fit image in bootstrap div*/
.fit-image {
  width: 100%;
  object-fit: cover;
}
.detaile_foem_kyc .form-control {
  border: 1px solid #676666;
}
.logo_launchpad {
  margin-bottom: 19px;
}

.logo_launchpad img {
  width: 200px;
  margin: 0 auto;
  margin-bottom: 10px;
}
.logo_launchpad h1 {
  color: #fff;
  text-align: center;
  font-size: 30px;
}
.page_dss .custom-control {
  padding: 12px 15px 12px 37px;
  border: 1px solid #666565;
}

.p2ppost li {
  display: flex;
  margin: 0;
  width: 50%;
}
.p2ppost ul {
  margin: 0;
  overflow: hidden;
  background: #282e42;
  border-radius: 10px 10px 0px 0px;
}
.p2ppost {
  padding: 0;
  border: 1px solid #383838;
  margin-bottom: 25px;
  background: #2f324180;
  position: relative;
  border-radius: 15px;
}
.p2ppost li a {
  width: 100%;
  text-align: center;
  padding: 17px 0px;
  color: #999;
}
.p2ppost li a.active {
  background: transparent;
  color: #fff;
  border-bottom: 2px solid #6720a8;
  /* border-bottom: 1px solid; */
}
.buyform_now {
  padding: 14px 23px;
}
.buyform_now .Dropdown-control {
  background: #303236 !important;
  height: 50px;
  border: 1px solid #3e3d3d;
}
.buyform_now .Dropdown-arrow {
  right: 15px;
  top: 23px;
}
.buyform_now .form-control {
  background: #303236 !important;
  height: 50px;
  border: 1px solid #3e3d3d;
  color: #fff;
}
.buyform_now .Dropdown-placeholder {
  padding: 3px 0px;
}
.buyform_now label {
  color: #fff;
  padding: 5px 0px;
}
.buyform_now .Dropdown-option:hover {
  background: #3b82f6 !important;
}
.buyform_now .Dropdown-option.is-selected {
  background-color: #3b82f6;
}
.post_submit {
  display: flex;
  justify-content: center;
  align-items: center;
}
.post_submit button {
  text-transform: capitalize;
  background: #3b82f6;
  color: #fff;
  padding: 7px 22px;
  margin: 9px 0;
  background: linear-gradient(
    90.15deg,
    #5c2d91 0.73%,
    #502abb 101.92%
  ) !important;
  border-radius: 8px;
  color: #fff;
  -webkit-text-fill-color: #fff;
}
.buyform_now .custom-control-label {
  margin-top: 5px;
  padding: 1px 0px;
}
.p2plist li {
  width: fit-content !important;
}
.p2plist li a {
  padding: 8px 14px;
}

.p2plist li a.active {
  background: transparent;
  color: #3b82f6;
  border-bottom: 2px solid #3b82f6;
}
.p2plist ul {
  border-radius: 0;
  margin: 9px 9px;
  background: #262936;
}
.buyform_now .form-group {
  margin-bottom: 0;
}
.buy_sell_s {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}
.peer2peer_section .nav-tabs.primaryNav {
  background: #2a2b2f;
  border-bottom: 0 solid #4d4a4a;
  border-radius: 5px;
  overflow: hidden;
  padding-bottom: 1px !important;
  padding: 0;
}
.buy_sell_s .nav-tabs.primaryNav {
  border: none;
  border-radius: 7px;
  padding: 0;
  padding: 5px 7px !important;
}
.buy_sell_s .primaryNav .nav-link {
  padding: 5px 24px !important;
}
.nav-tabs.primaryNav .nav-link.active,
.nav-tabs.primaryNav .nav-link:hover {
  border: none;
  border-bottom: 2px solid #fcb043;
  color: #fff;
}
.buy_sell_s .nav-tabs.primaryNav .nav-link:hover {
  border: none;
  color: #fff;
  border-color: transparent;
  padding: 5px 24px !important;
  height: 35.6px;
  font-weight: 400;
}
.buy_sell_s .primaryNav .nav-link.color-green.active {
  background: #0ecb81;
  border: none;
  border-radius: 0;
  border-radius: 6px;
  box-shadow: 3px 0 0 #000;
  color: #fff !important;
  -webkit-text-fill-color: #fff;
}
.buy_sell_s .primaryNav .nav-link.color-red.active {
  background: #f6465d;
  border: none;
  border-radius: 0;
  border-radius: 6px;
  box-shadow: 3px 0 0 #000;
  color: #fff !important;
  -webkit-text-fill-color: #fff;
}
.order_type h3 {
  color: #fff;
}
.curroncy_blac {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0px 11px;
}
.curroncy_blac label {
  color: #fff;
  margin-bottom: 0;
  margin-right: 12px;
}
.curroncy_blac .Dropdown-root {
  width: 100%;
}
.curroncy_blac .Dropdown-control {
  background: #282e42 !important;
}
.paymet-mode {
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  font-weight: 300;
  background: #0d042c;
  padding: 6px 17px;
  font-size: 13px;
  border-radius: 5px;
}
.availe_small {
  color: #999;
  margin-right: 10px;
}
button.trade-btn {
  text-transform: capitalize;
  color: #fff;
  padding: 2px 0px;
}
.trade-btn.green {
  background: #0ecb81;
}
.trade-btn.red {
  background: #f6465d;
}
.trade-btn.green:hover {
  background: #0ecb81;
}
.trade-btn.red:hover {
  background: #f6465d;
}
.buyform_now .fixTableHead th {
  background: #262936;
}
.buyform_now .fixTableHead {
  height: 300px;
}
.Dropdown-option.is-selected {
  box-sizing: border-box;
  color: #fff !important;
  background: linear-gradient(
    90.15deg,
    #5c2d91 0.73%,
    #502abb 101.92%
  ) !important;
}
.order_type p span {
  font-size: 15px;
}
.order_type_details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 31px 0px;
  padding-bottom: 31px;
  padding-bottom: 20px;
  /* border-bottom: 1px solid #676767; */
}
.order_type_details label {
  width: 100%;
  text-align: center;
  color: #999;
}
.order_type_details h4 {
  width: 100%;
  font-size: 18px;
  text-align: center;
  margin: 0;
}
.order_type_details p {
  margin: 0;
  font-size: 14px;
  color: #fff;
}
.order_type_details > div {
  padding: 5px 5px;
}
.payment_method_card {
  padding: 15px;
  border: 1px solid #393939;
  border-radius: 8px;
  margin: 10px 0;
}
.payment_method_card h5 {
  margin: 0;
  padding-bottom: 15px;
  border-bottom: 1px solid #393939;
  margin-bottom: 15px;
  color: #fff;
}
.payment_method_card label {
  margin: 0;
  color: #999;
}
.payment_method_card p {
  margin: 0;
  padding: 5px 0px;
  color: #d9d9d9;
}
.payment_method_card p span {
  margin-left: 9px;
}
.payment_method_card div {
  padding: 6px 0px;
}
.timer {
  padding: 10px 3px;
}
.timer h6 {
  margin: 0;
  padding: 10px 8px;
  font-size: 18px;
  color: #fff;
  display: inline-flex;
  text-wrap: nowrap;
}
.timer h6 span {
  font-size: 21px;
  margin-left: 6px;
  color: #3b82f6;
}
.timer p {
  margin: 0;
  color: #999;
}
.confirm_button_p2p {
  padding: 10px 0px;
}
.confirm_button_p2p button {
  text-transform: capitalize;
  font-size: 15px;
  background: #3b82f6;
  color: #fff !important;
  padding: 8px 14px;
  margin-right: 10px;
  background: linear-gradient(
    90.15deg,
    #5c2d91 0.73%,
    #502abb 101.92%
  ) !important;
  border-radius: 8px;
}
.confirm_button_p2p button:hover {
  background: linear-gradient(
    90.15deg,
    #5c2d91 0.73%,
    #502abb 101.92%
  ) !important;
}
.chatbox {
  display: flex;
  flex-direction: column;
  background: #282e42;
  border-radius: 10px;
  overflow: hidden;
  padding: 7px 7px;
}
.chatbox .msg_section {
  flex-grow: 1;
  flex-basis: 500px;
  height: 500px;
  overflow: hidden;
  overflow-y: auto;
}
.chat-text {
  background: #282e42;
  display: flex;
  align-content: center;
  align-items: center;
  padding: 0px 11px;
}
.chat-text input {
  flex-grow: 1;
  width: 100%;
  background: #181a24;
  border: 1px solid #5a5959;
  height: 45px;
  margin: 8px 5px;
  border-radius: 5px;
  color: #fff;
  padding: 0 9px;
}
.file_upload_send {
  display: flex;
  justify-content: center;
  align-items: center;
}
.file_upload_send .file {
  width: 45px;
  height: 50px;
  position: relative;
}
.file_upload_send .file input {
  width: 45px;
  height: 50px;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
  position: relative;
  padding: 0 !important;
  margin: 0 !important;
}
.file_upload_send .file i {
  position: absolute;
  left: 11px;
  top: 17px;
  font-size: 20px;
  color: #3b82f6;
  z-index: 1;
}
.file_upload_send span {
  font-size: 20px;
  cursor: pointer;
  color: #3b82f6;
}
.message {
  display: flex;
  justify-content: flex-end;
  padding: 10px 10px;
  margin-top: 25px;
}
.message div {
  width: -moz-fit-content;
  width: fit-content;
  background: #4d587c;
  padding: 15px 14px;
  border-radius: 10px;
  position: relative;
  color: #fff;
  font-weight: 300;
  min-width: 167px;
}
.message div > span {
  position: absolute;
  top: -22px;
  width: 100%;
  left: 0;
  font-size: 12px;
  color: #ccc;
}
.message div > p {
  margin: 0;
  font-size: 14px;
  /* margin-top: -3px; */
  width: 100%;
  word-break: break-all;
}
.message.recived {
  justify-content: flex-start;
}
.message.recived div > span {
  text-align: right;
}
.message img {
  width: 149px;
  height: 149px;
  border-radius: 10px;
}
.order_type {
  align-items: center;
  display: flex;
  font-size: 22px;
  justify-content: space-between;
  margin: 0;
  color: #fff;
}
.address_content {
  font-size: 13px;
  color: #999;
}
.font_pading_char {
  padding: 21px 0px;
}
.border-none {
  border: none !important;
}
.form-group .text-danger {
  text-align: left;
}
.register_login .form-group {
  min-height: 130px;
  margin-bottom: 0;
}
.warningicon {
  width: 129px;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 129px;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #ccccccba;
  border-radius: 11px;
  z-index: 1;
}
.warningicon i {
  font-size: 46px;
  margin-top: 0px;
  line-height: 0;
}
.detaile_foem_kyc p.text-danger {
  width: 100%;
  margin: 0;
}
.bottom_linnk a {
  margin-left: 4px;
}
.copyicon span {
  background: #3c82f6;
  padding: 8px 13px;
  /* border-radius:10px ; */
  cursor: pointer;
  margin-left: 5px;
}
.copyicon span i {
  margin-top: -3px;
  line-height: 0;
  color: #fff;
  font-size: 22px;
}
.button_centerer {
  margin: 0 auto;
  display: block;
  width: 169px;
  margin-top: 20px;
}
.erroe_launch {
  margin: 0 auto;
  font-size: 16px;
  color: #999 !important;
}
.seclelee .Dropdown-control {
  height: 50px;
  background: transparent !important;
  border: 1px solid #999 !important;
  border-radius: 1px !important;
  border: 1px solid #666565 !important;
  color: #000;
}
.seclelee .Dropdown-control .Dropdown-placeholder {
  /* height: 50px; */
  padding: 3px 0px;
  color: #6f7880;
}
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  left: -15px !important;
}
.react-datepicker__month-container {
  background: #444;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #ebebeb;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 0.3rem;
  background-color: #0d0d0d !important;
}
.react-datepicker {
  border: 1px solid #444 !important;
}
.react-datepicker__day-name {
  color: #070707 !important;
}
.react-datepicker__day {
  color: #eee;
}

@keyframes ldio-pmaw4zkjqw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.ldio-pmaw4zkjqw div {
  position: absolute;
  width: 120px;
  height: 120px;
  border: 20px solid #3b82f6;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-pmaw4zkjqw div {
  animation: ldio-pmaw4zkjqw 1s linear infinite;
  top: 100px;
  left: 100px;
}
.loadingio-spinner-rolling-29xdivqpql3 {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: rgba(NaN, NaN, NaN, 0);
  transform: scale(0.3);
}
.ldio-pmaw4zkjqw {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-pmaw4zkjqw div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */

/* new home */
.btn--blue {
  color: #fff !important;
  background: linear-gradient(90.15deg, #420977 0.73%, #420977 101.92%);
  border-radius: 8px;
  border: none;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.btn--blue::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(90.15deg, #6c5680 0.73%, #644382 101.92%); */
  z-index: -2;
}
.btn--blue::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  left: calc(-100% - 51px);
  width: calc(100% + 50px);
  height: 107px;
  background: #420977;
  z-index: -1;
  transform: translateY(-50%);
  transition: all 0.5s ease;
  border-radius: 107px;
}
.btn--blue:hover::after {
  left: calc(0% - 25px);
}
.main_conten h1 {
  margin: 0 0 28px;
  padding: 0;
  padding-top: 85px;
  line-height: 117.5%;
  font-size: 46px;
  font-weight: 700;
  color: #fff;
}
.main_conten h1 span {
  color: #420977;
}
.hero-intro p {
  margin: 0 0 15px;
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  max-width: 410px;
  color: #f7f8fa;
}
body.light {
  background: #fff !important;
}
.donwload-form {
  background: #fff;
  box-shadow: 0 8px 14px rgba(90, 123, 254, 0.15);
  border-radius: 8px;
  padding: 11px 15px;
  display: inline-flex;
  align-items: center;
  margin: 33px 0 17px;
}
.donwload-form > button {
  margin-left: 13px;
  padding: 10px 22px;
  font-weight: 600;
  font-size: 10px;
  white-space: nowrap;
  margin-right: 7px;
  background: linear-gradient(90.15deg, #5c2d91 0.73%, #502abb 101.92%);
  border-radius: 8px;
}
.donwload-form > button:first-child {
  margin-left: 0px;
}
.appDownload {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #85919c;
  margin: 30px 0 25px;
  display: none;
}
.appDownload li {
  margin: 0 23px 0 0;
}
.video_home {
  transform: scale(0.9);
  margin-top: -65px;
}
.coin_container {
  padding: 11px 20px;
  margin: 0 20px 14px 0;
  margin-bottom: 14px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  transition: all 0.25s ease;
  background-color: transparent;
  height: calc(100% - 14px);
  position: relative;
  color: #0b243a;
  text-decoration: none;
  display: flex;
  margin-bottom: 0;
}
.coin_container:hover {
  cursor: pointer;
  box-shadow: 0px 18px 34px rgba(5, 16, 55, 0.07);
  border-radius: 10px;
  background: #fff;
}
.cmc_links {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ccpw_icon {
  display: block;
  width: 40px;
  height: 40px;
  margin: 0 0 13px;
  margin: 0;
  margin-right: 10px;
  margin-top: -6px;
}
.ccpw_icon img {
  width: 40px;
  height: 40px;
}
.coin-title {
  display: flex;
  /* min-width: 145px; */
  justify-content: space-between;
  font-size: 12px;
  color: #23262f;
  align-items: center;
}
.coin.name {
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 105%;
  letter-spacing: -0.01em;
  color: #777e91;
  margin: 0 0 15px;
  padding: 0 20px 0 0;
  margin-bottom: 0;
}
.coin-shot-name {
  font-size: 12px;
  color: #23262f;
}
.price {
  display: block;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 105%;
  color: #0b243a;
  margin: 0 0 8px;
}
.percentage-change.changesup {
  background: transparent;
  border-radius: 5px;
  margin: 0;
  font-weight: 500;
  font-size: 13px;
  line-height: 105%;
  letter-spacing: -0.01em;
  color: green;
  padding: 5px 9px 4px 6px;
}
.slider_coin {
  /* background: rgba(194, 194, 221, 0.15); */
  padding: 22px 0px;
  margin: 57px 0 0;
  background: #f7f8fa;
  border-radius: 10px;
  /* padding-bottom: 6px; */
  padding: 17px 20px;
  padding-bottom: 0;
}
.diveee {
  margin: 10px 0px;
}
/* .slider_coin .slick-arrow {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0;
  line-height: 0;
  background: #fcfcfd;
  color: #0b243a;
  border-radius: 10px;
  width: 52px;
  height: 100px;
  z-index: 9;
  cursor: pointer;
  border: none;
  box-shadow: 0 8px 14px rgba(90, 123, 254, 0.15);
  transition: all 0.25 ease;
  overflow: hidden;
} */
/* .slider_coin .slick-arrow::after,
.slider_coin .slick-arrow::before {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: calc(100% + 4px);
  background: #005ad3;
  z-index: -1;
  transform: translate3D(-100%, 0, 0%);
  transition: all 0.5s;
  border-radius: 0 100px 100px 0;
  transform: translate3D(-100%, 0%, 0);
} */

/* .slider_coin .slick-arrow:hover::before,
.slider_coin .slick-arrow:hover::after {
  transform: translate3D(0, 0, 0);
  transition: all 0.5s;
  border-radius: 0;
} */
.slider_coin .slick-prev::before,
.slider_coin .slick-next::before {
  font-family: "FontAwesome";
  font-size: 20px;
  color: #000 !important;
}

.slider_coin .slick-next::before {
  content: "\f054" !important;
}
.slider_coin .slick-prev::before {
  content: "\f053" !important;
}
.slider_coin .slick-prev::after,
.slider_coin .slick-next::after {
  font-family: "FontAwesome";
}

.slider_coin .slick-next::after {
  content: "\f054" !important;
}
.slider_coin .slick-prev::after {
  content: "\f053" !important;
}
.slider_coin .slick-prev,
.slider_coin .slick-next {
  width: 50px;
  height: 50px;
  background: transparent;
  box-shadow: none;
  transition: all 0.25 ease;
  border-radius: 50px;
  z-index: 5;
  margin-top: -7px;
}
.slider_coin .slick-prev:hover,
.slider_coin .slick-next:hover {
  background: #fff;
  background: #601c9f;
}
.slider_coin .slick-prev:hover:before,
.slider_coin .slick-next:hover:before {
  color: #fff !important;
}
.slider_coin .slick-prev:focus:before,
.slider_coin .slick-next:focus::before {
  background: #fff;
  color: #000 !important;
}
.slider_coin .slick-prev:focus,
.slider_coin .slick-next:focus {
  background: #fff;
}
.slider_coin .slick-prev {
  left: -70px;
}
.slider_coin .slick-next {
  right: -74px;
}
.THired_secton {
  padding: 168px 0 163px;
}
.THired_secton h1 {
  margin: 0 0 30px;
  padding: 0;
  text-align: center;
  font-size: 46px;
  font-weight: 600;
}
.THired_secton p {
  text-align: center;
  font-size: 18px;
  color: #8c92ab;
  margin-bottom: 20px;
}
.ic__image__sec {
  position: relative;
}
.ic__bg {
  position: absolute;
  width: 100%;
  z-index: 0;
  top: 52px;
  left: 26px;
}
.ic__bg img {
  display: block;
  width: 100%;
  width: 800px;
  margin: 0 auto;
}
.ic__image {
  text-align: center;
  margin: 0 0 -5px;
  position: relative;
}
.ic__image img {
  margin: 0 auto;
}

.ic__card {
  padding: 21px 35px 28px;
  background: #fff;
  box-shadow: 0 5px 30px rgba(133, 145, 156, 0.2);
  border-radius: 8px;
  text-align: center;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  max-height: 310px;
}
.ic__card {
  text-align: center;
}
.ic__card__icon {
  height: 92px;
  margin: 0 0 10px;
}
.ic__card__icon img {
  max-height: 100%;
}
.ic__card {
  text-align: center;
}
.ic__card__heading {
  margin: 0 0 12px;
  letter-spacing: 0.8px;
  color: #181837;
  font-size: 24px;
  line-height: 1.2;
  letter-spacing: -0.02em;
}
.ic__card__description p {
  margin: 0 0 20px;
  font-size: 16px;
  line-height: 1;
  font-weight: 400;
  color: #5b6178;
  line-height: 21px;
  text-align: center;
  margin-top: 24px;
}
.ic__card__btn {
  margin-top: auto;
}
.ic__card__btn .btn {
  padding: 8px 15px;
}

.ic__card__btn .btn {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  display: inline-block;
  padding: 8px 20px;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  letter-spacing: 0.4px;
  text-transform: unset;
  color: #23262f !important;
  border: solid 2px #cfcfed;
  border-radius: 5px;
  text-decoration: none;
  font-weight: 700;
  vertical-align: top;
  background-color: transparent;
  transition: all 0.5s ease;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  z-index: 1;
  min-width: 143px;
}
.ic__card__btn .btn:hover {
  color: #fff !important;
}
.ic__card__btn .btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: 0 0;
  z-index: -2;
}
.ic__card__btn .btn::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  left: calc(-100% - 51px);
  width: calc(100% + 50px);
  height: 107px;
  background: #420977;
  z-index: -1;
  transform: translateY(-50%);
  transition: all 0.5s ease;
  border-radius: 107px;
}
.ic__card__btn .btn:hover::after {
  left: calc(0% - 25px);
}
.ic__card__icon img {
  margin: 16px auto;
}
.ic__card.new {
  position: relative;
  /* top: 92px; */
}
.ic__item {
  padding: 0 23px;
}
.icon__grid {
  background: rgba(194, 194, 221, 0.15);
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  -ms-border-radius: 18px;
  border-radius: 18px;
  padding: 39px 0 37px;
}
.icon__item {
  -webkit-box: 1;
  -moz-box: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  text-align: center;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.icon__item img {
  height: 42px;
  margin: 0 0 24px;
  width: auto;
}
.icon__item__heading {
  margin: 0;
  color: #4e4e4e;
  line-height: 1.085;
  font-weight: 500;
  font-size: 20px;
}
.step__item {
  /* padding: 0 45px; */
}

.step__card {
  /* background: #f6faff; */
  /* border-radius: 15px; */
  height: 100%;
  padding: 34px 25px;
  min-height: 315px;
  margin: 20px 0;
  background: #ffffff;
  box-shadow: 5px 5px 30px rgba(103, 32, 168, 0.07);
  border-radius: 15px;
  text-align: center;
}
.step__card {
  text-align: center;
}
.step__card__icon {
  margin: 0 0 30px;
}
.step__card__icon img {
  margin: 0 auto;
  width: 90px;
}
.step__card__heading {
  font-size: 22px;
  line-height: 130%;
  font-weight: 500;
  margin-bottom: 14px;
  color: #1e202c;
}
.step__card__description p {
  font-weight: 400;
  font-size: 16px;
  line-height: 130.5%;
  color: #5b6178;
}
.step__heading {
  text-align: center;
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 26px;
  color: #181837;
}
.step {
  padding: 100px 0 42px;
}
.step__item::after {
  display: block;
  content: "";
  width: 32px;
  height: 32px;
  background: url(../src/img/newimg/arrowwww.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  right: -55px;
  top: 50%;
  transform: translate(50%, -50%);
  z-index: 9;
}
.step__item00:after {
  display: none;
}
.ics__icon__list {
  padding: 5px 0 0;
}
.ics__icon__item {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin: 0 0 45px;
}
.ics__icon__item__icon {
  width: 28px;
  display: flex;
}
.ics__icon__item__icon img {
  max-width: 100%;
}
.ics__icon__item__heading {
  margin: 0;
  padding: 0 0 0 29px;
  font-size: 20px;
  line-height: 130.5%;
  font-weight: 500;
  width: calc(100% - 28px);
}
.six_secton_s h1 {
  margin-bottom: 37px;
  font-size: 46px;
  font-weight: 700;
  color: #fff;
}
.six_secton_s p {
  color: #d9ddea;
  font-size: 18px;
}
.blue_light_bg.six_secton_s h1 {
  margin-bottom: 50px;
  font-size: 36px;
  font-weight: 700;
  color: #fff;
}
.six_secton_s {
  padding: 0 86px;
  margin: 85px 0px;
  padding-right: 0;
}
.img_pic_s {
  margin: 0 auto;
}
.wp-container-4 {
  display: flex;
  gap: 0.5em;
  flex-wrap: wrap;
  align-items: center;
}
.wp-block-buttons > .wp-block-button {
  display: inline-block;
  margin: 0;
}
.wp-block-button__link::after,
.buy-sell-image-with-text-content
  .wp-block-lazyblock-image-with-text-content
  .image_content__row
  .button
  .btn::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  left: calc(-100% - 51px);
  width: calc(100% + 50px);
  height: 107px;
  background: #420977;
  /* background: linear-gradient(90.15deg, #6c5680 0.73%, #644382 101.92%); */
  z-index: -1;
  transform: translateY(-50%);
  transition: all 0.5s ease;
  border-radius: 107px;
}
.wp-block-button__link::before,
.buy-sell-image-with-text-content
  .wp-block-lazyblock-image-with-text-content
  .image_content__row
  .button
  .btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90.15deg, #6c5680 0.73%, #644382 101.92%);
  z-index: -2;
}
.wp-block-button__link,
.buy-sell-image-with-text-content
  .wp-block-lazyblock-image-with-text-content
  .image_content__row
  .button
  .btn {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  position: relative;
  overflow: hidden;
  min-width: 99px;
  height: 44px;
  color: #fff;
  background: linear-gradient(90.15deg, #5d7bfe 0.73%, #0974ff 101.92%);
  border-radius: 10px;
  box-sizing: border-box;
  text-decoration: none;
  padding: 0 25px;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  z-index: 1;
  transition: all 0.5s ease;
}
.wp-block-button__link:hover,
.buy-sell-image-with-text-content
  .wp-block-lazyblock-image-with-text-content
  .image_content__row
  .button
  .btn:hover {
  color: #fff;
}
.wp-block-button__link:hover::after,
.buy-sell-image-with-text-content
  .wp-block-lazyblock-image-with-text-content
  .image_content__row
  .button
  .btn:hover::after {
  left: calc(0% - 25px);
}
button.wp-block-button__link {
  padding: 0 34px;
  color: #fff;
  border-radius: 10px;
}
.img_pic_s img {
  width: 90%;
  margin: 0 auto;
}
.img_pic_s_11 img {
  width: 404px;
  margin: 10px auto;
}
.gray {
  background: linear-gradient(
    180deg,
    rgba(194, 194, 221, 0.15) 41.15%,
    rgba(194, 194, 221, 0.1) 100%
  );
}
.latest-offer__item__inner {
  padding: 26px 20px 27px;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  margin: 26px 13px;
  height: fit-content;
}
.latest-offer__item .light-blue {
  background: #d9eef4;
}
.latest-offer__title {
  margin: 0 0 24px;
  font-size: 24px;
  line-height: 140%;
  color: #23262f;
  text-align: center;
  font-weight: 600;
}
.latest-offer__description {
  padding: 0 10px;
}
.latest-offer__description p {
  margin: 0 0 20px;
  font-size: 12px;
  line-height: 130%;
  text-align: center;
  letter-spacing: 0.005em;
  color: rgba(11, 36, 58, 0.7);
}
.latest-offer__item__bottom {
  margin-top: 0;
}
.latest-offer__image {
  margin: 0 0 15px;
}
.latest-offer__image img {
  margin: 0 auto;
  max-height: 100px;
  width: auto;
}
.latest-offer__btn {
  margin: 0 0 10px;
  position: relative;
  z-index: 9;
}
.latest-offer__btn .btn {
  display: block;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
  font-size: 15px;
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
  z-index: 1;
  color: #0b243a;
  border-color: #0b243a;
  border-width: 1px;
  border: 1px solid;
}
.latest-offer__btn .btn:hover {
  color: #fff;
  border-color: #005ad3;
}
.latest-offer__btn .btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: 0 0;
  z-index: -2;
}
.latest-offer__btn .btn::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  left: calc(-100% - 51px);
  width: calc(100% + 50px);
  height: 107px;
  background: #420977;
  z-index: -1;
  transform: translateY(-50%);
  transition: all 0.5s ease;
  border-radius: 107px;
}
.latest-offer__btn .btn:hover::after {
  left: calc(0% - 25px);
}
.latest-offer__date {
  margin: 0;
  font-weight: 400;
  font-size: 13px;
  line-height: 117.5%;
  color: #a1a0b9;
  text-align: center;
}
.h2_piopo {
  font-size: 36px;
  line-height: 1.2;
  text-align: center;
  margin-bottom: 30px;
}
.cta__row {
  display: inline-flex;
  align-items: center;
  background: linear-gradient(90.15deg, #6c5680 0.73%, #644382 101.92%);
  border-radius: 15px;
  padding: 15px 16px 17px;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.cta__heading {
  margin: 13px 120px 6px 25px;
  font-size: 20px;
  line-height: 1.2;
  font-weight: 400;
  text-align: left;
  color: #fff;
}
.cta__btn::after {
  display: block;
  content: "";
  width: 268px;
  height: 268px;
  background: rgba(255, 255, 255, 0.05);
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 100%;
}
.cta__btn .btn::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  left: calc(-100% - 51px);
  width: calc(100% + 50px);
  height: 107px;
  background: #420977;
  z-index: -1;
  transform: translateY(-50%);
  transition: all 0.5s ease;
  border-radius: 107px;
}
.cta__btn .btn::before {
  background: #ecf7f9;
}
.cta__btn .btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: 0 0;
  z-index: -2;
}
.cta__btn .btn {
  margin: 0;
  display: block;
  width: 198px;
  position: relative;
  z-index: 9;
  padding: 21px 15px 15px;
  font-size: 15px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  letter-spacing: normal;
  font-weight: 400;
  text-transform: uppercase;
  color: #000 !important;
}
.cta__btn .btn:hover {
  color: #fff !important;
}
.btn--fill {
  border: none;
  background: #ecf7f9;
  border-radius: 15px;
  color: #000;
  border-radius: 15px;
}
.cta__btn .btn:hover::after {
  left: calc(0% - 25px);
}
.seven_secton.step {
  padding: 36px 0px;
}
.ch__row.light-gary {
  background: #e7f1ee;
}
.ch__copy__tag--desktop {
  font-weight: 600;
  font-size: 22px !important;
  line-height: 16px;
  letter-spacing: 0.24em;
  text-transform: uppercase;
  width: calc(100% - 60px);
  margin: 35px 30px -11px;
  padding: 0 30px;
  color: #58b78e;
  text-align: center;
  padding: 24px 0px;
}
.wp-block-lazyblock-card-big .ch__img {
  width: 100%;
  padding-top: 0;
}
.wp-block-lazyblock-card-big .ch__img img {
  display: block;
  margin: 15px auto;
  max-height: 177px;
}
.wp-block-lazyblock-card-big .ch__copy {
  flex: none;
  width: calc(100% - 60px);
  background: #e7f1ee;
  opacity: 0.85;
  backdrop-filter: blur(400px);
  border-radius: 15px;
  margin: 0 30px 30px;
  padding: 0 30px;
  max-width: 85%;
}
.wp-block-lazyblock-card-big .ch__copy__heading {
  margin: 0;
  padding: 0;
  color: #23262f;
  line-height: 1.29166667;
  font-weight: 700;
  font-size: 28px;
}
.wp-block-lazyblock-card-big .ch__row {
  height: 100%;
  /* padding: 16px 0px; */
  border-radius: 16px;
}
.ch__row.light-lavender {
  background: #eadef0;
}
.ch__img {
  width: 38%;
  display: flex;
  align-items: center;
}
.ch__img--bottom {
  align-items: flex-end;
}
.ch__copy {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 25px 20px 15px;
}
.ch__copy__tag {
  margin: 0 0 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 114%;
  letter-spacing: 0.24em;
  text-transform: uppercase;
  color: #41a7c8;
  width: 100%;
  /* padding: 24px 0px; */
}
.ch__row.light-lavender .ch__copy__tag {
  color: #e03573;
}
.ch__copy__heading {
  margin: 0;
  padding: 0;
  color: #23262f;
  line-height: 1.29166667;
  font-size: 23px;
  font-weight: 600;
}
.ch__row.light-lavender {
  background: #eadef0;
  border-radius: 16px;
  overflow: hidden;
}
.take-your-crypto-gameto-the-next-level {
  text-align: center;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 52px;
}
.bg_pink_ss {
  background: #ddf5f6 !important;
}
.classss_imgs img {
  width: 200px;
  margin: 0 10px;
}
.fl__slide__item img {
  margin: 0 36px;
  width: auto;
  height: 64px;
  object-fit: contain;
  object-position: center;
  filter: grayscale(100%);
}
.cta-trading-now h2 {
  margin: 0 0 41px;
  padding: 0;
  font-size: 36px;
  line-height: 83%;
  text-align: center;
  color: #000;
  font-weight: 600;
}
.cta-trading-now h2 .blue {
  color: #420977;
  font-weight: 600;
}
.center_textxx {
  background: #fcfcfd;
  text-align: center;
  padding: 63px 0 59px;
}
button .footer_roeund {
  height: 48px;
  max-width: 37px;
  border-radius: 62px;
  padding: 0;
  min-width: 48px;
  font-size: 20px;
}
.donwload-form.buttondf {
  display: inline-flex;
  padding: 0 75px 0 10px;
  background: transparent;
  border: 0px solid #e6e8ec0a;
  box-sizing: border-box;
  border-radius: 11px;
  position: relative;
  align-items: center;
  padding: 8px 0px;
  box-shadow: none;
  /* width: 100px; */
  width: 63%;
  padding-right: 0;
}
.footer-widget.widget-quicklink {
  margin-left: 0 !important;
  padding-left: 100px;
}
.footer-widget .widget-title {
  text-align: left;
}
.footer-menu a {
  color: #1e202c;
  text-decoration: none;
  outline: none;
  font-size: 14px;
}
.footer-menu {
  display: flex;
  padding: 10px 0px;
}
.footer-widget .widget-title {
  font-size: 19px;
  margin-bottom: 25px;
  color: #fff;
  font-weight: 600;
  margin: 0 0 17px;
  padding: 0;
  font-size: 16px;
  line-height: 1;
  text-transform: capitalize;
  color: #6720a8 !important;
}
.rn-section-gap {
  padding: 69px 0;
  padding-bottom: 0;
}
.footer_newsform label {
  width: 100%;
  text-align: left;
  margin: 0 0 17px;
  padding: 0;
  font-size: 16px;
  line-height: 1;
  text-transform: uppercase;
  color: #3673e7;
  font-weight: 600;
  padding-left: 5px;
}
.footer_newsform .donwload-form {
  /* width: 70%; */
  box-shadow: none;
  border: 1px solid #d5d4d4;
  border-radius: 48px;
  padding: 6px 7px;
  margin: 0;
}
.footer_newsform input {
  width: 71%;
}
.footer_newsform .donwload-form > button {
  padding: 0;
  width: 50px;
  min-width: 50px;
  height: 50px;
  border-radius: 50px;
}
.footer_newsform {
  margin: 31px 0;
}
.widget {
  margin: 0 0 1.5em;
}
.footer-disclaimer-section p {
  color: #777e90;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  text-align: center;
  margin: 0 auto 40px;
  max-width: 73%;
}
.footer-disclaimer-section p strong {
  color: #2072ef;
  font-weight: 600;
}
.site-footer .site-info {
  padding: 26px 0 25px;
  border-top: solid 1px #d3d6db;
  text-align: center;
  font-size: 14px;
  color: #777e91;
}
.bg-trans .site-footer .site-info {
  border-top: solid 0px #d3d6db;
}
.site-footer .site-info__row {
  flex-direction: column;
  justify-content: center;
}
.site-footer .site-info__row .widget {
  margin: 0;
}
.site-footer .site-info__copyright {
  font-size: 13px;
}
.site-footer .site-info__copyright {
  margin: 0 33px 0 0;
  padding: 0;
}
.right {
  float: right;
}
.site-footer .site-info__row .widget:last-child {
  margin-left: auto;
}
.site-footer .site-info {
  text-align: center;
  font-size: 14px;
  color: #777e91;
}
.site-footer .site-info .menu {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}
.site-footer .site-info .menu-social-menu-container .menu li {
  margin: 0 0 0 12px;
}
.site-footer .menu li a {
  text-decoration: none;
  color: #777e90;
  text-decoration: none;
  outline: none;
}
.site-footer .site-info .menu li a {
  color: #777e91;
  padding: 0;
}
.site-footer .site-info .menu li a img {
  display: block;
  opacity: 1;
  transition: all 0.25s ease;
  width: 28px;
  height: 28px;
}
.site-footer .site-info .menu li a:hover img {
  opacity: 0.8;
}
.site-footer .site-info .menu li a .item-label {
  display: none;
}
.center_textxx {
  padding-bottom: 0 !important;
}
.currency-banner-image {
  position: relative;
}
.currency-banner-image img.img_soeurr {
  width: 71%;
  margin: 0 auto;
}
.currency-banner-image .banner-shape-1 {
  position: absolute;
  top: 45px;
  right: 256px;
  -webkit-animation: movebounce2 4s linear infinite;
  animation: movebounce2 4s linear infinite;
  width: 66px;
}
.currency-banner-image .banner-shape-2 {
  position: absolute;
  top: 165px;
  left: 124px;
  width: 66px;
  -webkit-animation: movebounce2 4s linear infinite;
  animation: movebounce2 4s linear infinite;
}
.currency-banner-image .banner-shape-3 {
  position: absolute;
  bottom: 99px;
  left: 258px;
  width: 66px;
  -webkit-animation: movebounce2 4s linear infinite;
  animation: movebounce2 4s linear infinite;
}
@keyframes movebounce2 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 15px);
  }
  100% {
    transform: translate(0, -0px);
  }
}
.currency-banner-image {
  padding: 86px 0px;
}
.blue_light_bg,
.v_blue_pro {
  background-color: #280363;
  /* background-color: #b469ff; */
  position: relative;
  background-repeat: no-repeat;
  background-position-x: -55px;
  background-position-y: 424px;
  background-image: url(../src//img/newimg/Rectangle.png);
}
.blue_light_bg .six_secton_s h1 {
  font-size: 45px;
  color: #fff;
}

.angle_top {
  border-right: 100vw solid rgba(0, 0, 0, 0);
  border-top: 100px solid #ffffff;
  display: block;
  height: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  z-index: 1;
}
.angle_bottom {
  border-bottom: 100px solid #fdfdfd;
  border-right: 100vw solid rgba(0, 0, 0, 0);
  bottom: 0;
  display: block;
  height: 0;
  position: absolute;
  right: 0;
  width: 0;
  z-index: 9;
}
.bg_light .angle_top {
  border-top-color: #f7fafc;
}
.large_divider {
  height: 100px;
}
header {
  box-shadow: none !important;
}
.ics__icon__item__icon i {
  font-size: 25px;
  color: #fff;
}
.blue_light_bg .ics__icon__item__heading {
  margin: 0;
  padding: 10px 0 0 29px;
  font-size: 20px;
  line-height: 130.5%;
  font-weight: 500;
  width: calc(100% - 28px);
  color: #fff;
}
.donwload-form.buttondf .PhoneInput {
  width: 65%;
}
.height-devider {
  height: 20px;
}
.blue_light_bg .step__heading {
  margin-bottom: 45px;
  color: #fff;
}
.six_sectonnew .ics__icon__item__icon i {
  font-size: 25px;
  color: #212529;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  padding: 4px 0 !important;
}
.fixed_header {
  background: #fff !important;
  box-shadow: 0 0 6px #0000008a !important;
  padding: 4px 0;
}
.gradion_text {
  background: -webkit-linear-gradient(91.43deg, #5c2d91 2.55%, #9f02b9 123.64%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.white_text {
  background: -webkit-linear-gradient(91.43deg, #fff 2.55%, #fff 123.64%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.card_colo {
  background: #280363;
  border-radius: 15px;
}
/* .five_secton {
  background: #f6f7f9;
} */
.paddin_clsss {
  padding-bottom: 100px;
  padding-top: 50px;
}
.step__heading.gradion_text {
  font-size: 46px;
  margin-bottom: 25px;
}
.text_des {
  text-align: center;
  color: #8c92ab;
  font-size: 18px;
}
.bottom_maa {
  margin-bottom: -218px;
}
.patop0ee {
  padding-top: 230px;
}
.butn_flw_section {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}
.butn_flw_section .icon-btc {
  margin-right: 5px;
  width: 20px;
}
.butn_flw_section .unit {
  color: #959595;
  font-size: 16px;
  line-height: 0;
  margin-bottom: -4px;
  margin-left: 6px;
}
.coin-list__main {
  width: 76%;
  margin: 40px auto;
}
.take-your-crypto-gameto-the-next-level {
  font-size: 46px;
  margin-bottom: 30px;
}
.img_wwwwwww {
  width: 260px !important;
  margin: 73px auto !important;
}
.padieeee_bg {
  padding: 0;
  background: none;
}
.padieeee_bg p {
  color: #8c92ab;
  font-size: 18px;
}
.five_sectonnew {
  background: #f6f7f9;
}
.footerlogos {
  width: 72px;
}
.dfoodsss {
  text-align: left;
  font-size: 14px;
}
.light .table th {
  color: #8c92ab;
  font-weight: 400;
}
button.treiee {
  color: #6720a8;
  text-transform: capitalize;
}
.fixed-top .darktheme {
  display: block !important;
}
.fixed_header .darktheme {
  display: none !important;
}
.fixed-top .lighttheme {
  display: none !important;
}
.fixed_header .lighttheme {
  display: block !important;
}
.fixed_header .trade111 {
  color: #000 !important;
}
.fixed_header a.btn.btn-primary-alta:hover,
.fixed_header button.btn.btn-primary-alta:hover {
  color: #000 !important;
  border-radius: 8px;
}
.card_logoki {
  background: #ffffff;
  border-radius: 20px;
}
.form_pading_s .form-group {
  min-height: auto;
  margin-bottom: 35px;
}
.form_pading_s {
  padding: 25px 25px;
}
.form_content h1 {
  font-size: 34px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 12px;
}
.form_content p {
  text-align: center;
  font-size: 16px;
  color: #1e202c;
}
.header_pading {
  padding: 80px 0px;
}
.pading_cardd {
  margin: 90px 15px;
  padding: 43px 26px;
}
.site-info__row {
  display: flex;
  flex-direction: row !important;
  align-items: center;
}
.bg-trans {
  background: transparent;
}
.brder-non {
  border: none;
}
.title_blocc {
  font-weight: 500;
}
.container-scroller {
  overflow: hidden;
}
.page-body-wrapper {
  min-height: calc(100vh);
  padding-top: 0px;
  display: flex;
  flex-direction: row;
  padding-left: 0;
  padding-right: 0;
}
.navbar.fixed-top + .page-body-wrapper {
  padding-top: 0px;
}
.main-panel {
  transition: width 0.25s ease, margin 0.25s ease;
  width: 100%;
  min-height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
}
.content-wrapper {
  background: #1e202c;
  padding: 2.75rem 2.25rem;
  width: 100%;
  flex-grow: 1;
}
.card_profile {
  padding: 20px;
  background: #28283c;
  border-radius: 10px;
}
p.text-danger {
  text-align: left;
}
.card_profile p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #8c92ab;
  margin-bottom: 7px;
}
.card_profile h3 {
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  color: #fff;
}
.clorerree {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sideber_header .search_banner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 312px;
  border: 2px solid #5b6178;
  border-radius: 10px;
  overflow: hidden;
}
.sideber_header .search_banner i {
  line-height: 0;
  font-size: 20px;
  padding: 0 10px;
  color: #8c92ab;
}
.sideber_header .search_banner input {
  height: 50px;
  width: 100%;
}
.sideber_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 17px;
}
.menu_profile #dropdownMenuButton {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.menu_profile #dropdownMenuButton img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
}
.menu_profile .dropdown-toggle::after {
  display: none;
}
.menu_profile #dropdownMenuButton:active {
  background: none;
  box-shadow: none;
  outline: none;
}
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}
.show > .btn-secondary.dropdown-toggle {
  background-color: none;
  border-color: none;
}
.menu_profile {
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu_profile i {
  line-height: 0;
  font-size: 19px;
}
.pro-sidebar > .pro-sidebar-inner {
  background: #313545 !important;
}
.logotext .logo.big {
  width: 174px;
  margin: 9px auto;
}
.logotext .logo.small {
  width: 56px;
  margin: 9px auto;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
  border-bottom: 0px solid rgba(173, 173, 173, 0.2) !important;
}
.pro-sidebar-content a {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pro-sidebar-content a .header_icon_meniu {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 80%;
  padding: 16px 10px;
  color: #f7f8fa;
  font-size: 16px;
}
.pro-sidebar-content a .header_icon_meniu i {
  line-height: 0;
  margin-right: 12px;
  font-size: 19px;
}
.pro-sidebar.collapsed .text_inn {
  display: none;
}
.pro-sidebar.collapsed .header_icon_meniu {
  justify-content: center;
}
.closemenu {
  position: absolute;
  right: -15px;
  padding: 0px 0px;
  background: #fff;
  height: 30px;
  width: 30px;
  display: grid;
  place-items: center;
  border-radius: 50px;
  z-index: 1000;
  border: 1px solid #ccc;
  top: 50vh;
  cursor: pointer;
}

.closemenu i {
  line-height: 0;
}
#header {
  position: relative;
}
.pro-sidebar {
  z-index: 100 !important;
}

.content-wrapper .my_fund_content {
  padding: 0;
}
.clorerree button {
  border: 1px solid #8c92ab;
  text-transform: capitalize;
  border-radius: 8px;
  font-size: 14px;
  color: #fff;
  margin-right: 10px;
}
.textsswallet {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #1e202c !important;
  margin-bottom: 15px !important;
}
.content-wrapper .bg_card_section .table thead th {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #8c92ab !important;
  border-bottom: 1px solid #313131;
  text-align: left;
}
.content-wrapper .view_now .details_set {
  display: none;
}
.content-wrapper .view_now {
  background: #313546;
  border-radius: 10px;
  padding: 10px 20px;
}
.content-wrapper .launch_card {
  padding: 18px 16px;
}
.content-wrapper .launch_card:hover {
  box-shadow: none !important;
}

.content-wrapper .input_section .Dropdown-control {
  background: transparent !important;
  color: #fff !important;
  height: 57px;
}
.content-wrapper .input_section .Dropdown-control {
  padding: 17px 52px 12px 10px !important;
}
.content-wrapper .input_section .Dropdown-arrow {
  top: 27px !important;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-content {
  padding: 20px 0px !important;
}
.sideber_header {
  background-color: #313545;
}
.sideber_header .search_banner input {
  background: #313545;
  color: #fff;
}
.fixed_header .notification:hover > i {
  color: #280363 !important;
}
div.home {
  background: #fff;
}
.onlywhitee.tradepage-bg {
  background: #280363;
  background-image: url(../src/img/newimg/login.png);
  height: 100%;
  background-size: auto !important;
  background-repeat: no-repeat;
  background-position: center;
  /* min-height: 100vh; */
}
.onlywhitee .register_login .form-control {
  background: #ebebeb;
  color: #000;
}
.onlywhitee .otp_content_e input {
  background: #ebebeb;
  color: #000;
}
.padin_cls_00 > span {
  color: #999;
}
.menu_profile > button i {
  color: #fff;
}
.kyc_card .form-group input {
  height: 50px;
  border: 1px solid #5b6178;
  background: transparent;
  color: #fff;
}
.kyc_card .form-group label {
  color: #fff;
}
.ky_c_contnet p {
  color: #999;
}
.contet_kyc_section {
  margin: 71px 0px;
  margin-bottom: 10px;
}
button.btn.connectBtn.netword {
  background: transparent !important;
  padding: 0;
  min-width: 13px;
}
.netword img {
  width: 28px;
}
.kyc_colorr p:first-child {
  font-size: 20px;
  margin-bottom: 12px !important;
  font-weight: 500;
  color: #fff;
}
.light .kyc_colorr p.banskksss {
  font-size: 20px;
  margin-bottom: 12px !important;
  font-weight: 500;
  color: #000;
}
.kyc_colorr p {
  color: #fff !important;
}
.postion_reletitt {
  position: relative;
}
.postion_reletitt .input-group-addon {
  color: #000;
  cursor: pointer;
  position: absolute;
  right: 0;
  right: 15px;
  top: 10px;
}
.change_password_section .input-group-addon {
  top: 14px !important;
  color: #fff;
}
.text-width_wrwpp {
  color: #fff !important;
  font-size: 17px !important;
  width: 209px;
  overflow: hidden;
  white-space: break-spaces;
  word-break: break-all;
  text-align: left;
}
.order-typr_ee .dropdown-toggle {
  padding: 3px 17px;
  font-size: 14px;
  border-radius: 5px;
  text-transform: capitalize;
  background: linear-gradient(
    90.15deg,
    #5c2d91 0.73%,
    #502abb 101.92%
  ) !important;
  font-weight: 500;
  margin: 6px 0px;
  margin-bottom: 13px;
}
.order-typr_ee {
  display: flex;
  justify-content: flex-end;
}
p.balance {
  color: #fff;
}

.notification_section {
  position: relative;
}
.notification_section #notification {
  background: #fff;
  border-radius: 10px;
  left: -201px;
  padding: 5px;
  position: absolute;
  top: 52px;
  width: 249px;
}
.content_notification h3 {
  align-items: center;
  display: flex;
  font-size: 18px;
  font-weight: 500;
  justify-content: space-between;
  padding: 7px 0;
}
.content_notification h3 a {
  color: #3b82f6;
  font-size: 13px;
  padding: 0 3px;
}
.overflow_seee {
  height: 200px;
  overflow: hidden;
  overflow-y: scroll;
  text-align: center;
  color: #bfbfbf;
}
.content_notification .msg-noti {
  border-bottom: 1px solid #ccc;
  display: block;
  padding: 7px 3px;
}
.content_notification .msg-noti:last-child {
  border-bottom: 0 solid #ccc;
}
.content_notification .msg-noti small {
  color: #4f4d4d;
  display: block;
  font-size: 11px;
  text-align: right;
  width: 100%;
}
.content_notification .msg-noti p {
  font-size: 14px;
}
.under_maintaine {
  padding: 25vh 0;
}
.under_maintaine h1 {
  font-size: 100px;
  color: #fff;
  font-weight: bold;
  margin-bottom: 22px;
}
.under_maintaine h3 {
  font-size: 50px;
  color: #fff;
}
.server_image img {
  margin-top: 64px;
}
.notifycount {
  position: relative;
}
.notifycount .count_data {
  position: absolute;
  left: 33px;
  color: #fff;
  font-size: 12px;
  background: linear-gradient(
    90.15deg,
    #5c2d91 0.73%,
    #502abb 101.92%
  ) !important;
  height: 26px;
  width: 26px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  top: -8px;
  font-weight: 500;
}
.down_ssss {
  overflow: hidden;
  overflow-x: auto;
}
.down_ssss .sessions__row {
  cursor: pointer;
}
.down_ssss table td {
  white-space: nowrap;
}
.down_ssss .table thead th {
  white-space: nowrap;
}
.down_ssss table tr {
  cursor: pointer;
}
.down_ssss .sessions__content {
  font-weight: 500;
  color: #fff;
  white-space: nowrap;
  padding: 0px 8px;
}
.option_new_order {
  display: flex;
  padding: 10px 10px;
  justify-content: flex-end;
  margin-top: 10px;
}
.option_new_order a {
  color: #fff !important;
  background: linear-gradient(
    90.15deg,
    #5c2d91 0.73%,
    #502abb 101.92%
  ) !important;
  border-radius: 6px;
  filter: none;
  padding: 11px 15px;
  justify-content: flex-end;
  margin-top: 0px;
  margin-left: 14px;
}
.option_new_order a i {
  display: flex;
  width: fit-content;
  float: left;
  margin-right: 9px;
  margin-top: 3px;
  margin: 0px 10px;
  margin-top: 4px;
  margin-left: 0px;
}
.card_aa {
  box-shadow: 0px 0px 3px #ccc;
  padding: 20px;
  margin: 30px 0px;
  border-radius: 10px;
}
.tilte_contact {
  font-size: 30px;
  font-weight: 600;
}
.card_aa .form-control {
  height: 55px;
}
.text_areaaa {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.text_areaaa textarea {
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 5px;
}
.card_aa button,
.card_aa button:hover {
  background: linear-gradient(
    90.15deg,
    #5c2d91 0.73%,
    #502abb 101.92%
  ) !important;
  border-radius: 8px;
  color: #fff;
  text-transform: capitalize;
  width: 100%;
  padding: 15px 0px;
  margin-top: 12px;
  margin-bottom: 12px;
}
.contact_us {
  padding: 100px 0px;
}
.form-swap .custom-control {
  margin-bottom: 24px;
}
.form-swap label {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-swap .custom-control-label {
  color: #fff;
  font-weight: 400;
}
.form-swap .custom-control-label a {
  margin-left: 5px;
  color: #3b82f6;
}
.form-swap .form-group {
  height: auto;
  min-height: auto;
  margin-bottom: 26px;
}
.form-swap label {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-swap span {
  width: auto;
  padding: 0;
}
.swap_form_s {
  display: flex;
  justify-content: center;
  align-items: center;
}
.swap_form_s select {
  width: -moz-fit-content;
  width: fit-content;
  margin-left: 14px;
}
.swap_form_s select {
  width: -moz-fit-content;
  width: fit-content;
  /* height: 48px; */
  margin-left: 5px;
  background: #1a223f !important;
  padding: 0px 13px;
  border-radius: 5px;
  background: #ebebeb;
  color: #fff;
  border: 1px solid #6e7073;
}
.swap_form_s input {
  border: 1px solid #6e7073 !important;
}
.span_swap {
  color: #fff;
  display: block;
  margin: 0 auto;
  text-align: center;
  font-size: 27px;
  cursor: pointer;
}
.span_swap i {
  display: flex;
  justify-content: center;
  line-height: 0;
  transform: rotate(90deg);
}
.form-swap .buton button {
  width: 100% !important;
  padding: 16px 0px;
  font-size: 14px !important;
  color: #fff !important;
  background: linear-gradient(
    90.15deg,
    #5c2d91 0.73%,
    #502abb 101.92%
  ) !important;
  border-radius: 6px;
  filter: none;
  margin-top: 10px;
}
.summer_swap h4 {
  color: #fff;
  padding: 5px 0px;
  border-bottom: 1px solid #636363;
  padding-bottom: 10px;
  margin-bottom: 13px;
}
.summer_swap p {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* .swap_form_s select {
  background: #ebebeb;
  color: #000;
} */
.collapse {
  visibility: inherit !important;
}
.swap_img_section {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1a223f;
  padding: 5px 8px;
  border-radius: 5px;
  border: 1px solid #cccccc94;
  margin-left: 7px;
}
.swap_img_section img {
  width: 27px;
}
.swap_img_section .Dropdown-control {
  padding: 10px 31px;
  padding-left: 5px;
  background: transparent !important;
}
.referla_contenyt h2 {
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  color: #fff;
}
.refral_contene h1 {
  color: #fff;
  font-weight: 700;
  margin-bottom: 19px;
  font-size: 25px;
  text-align: center;
}
.refral_contene p {
  color: #999;
  font-size: 14px;
  text-align: center;
}
.refral_contene {
  padding: 50px 0px;
}
.referal_card {
  padding: 20px;
  background: #313545;
  border-radius: 10px;
  margin: 40px 0px;
}
.tilet_refer {
  margin-bottom: 14px;
  text-align: center;
}
.tilet_refer span {
  font-weight: 700;
  color: #fff;
  font-size: 18px;
}
.refer_linlk_ss {
  display: flex;
  justify-content: center;
  align-items: center;
}
.refer_linlk_ss .input_card_refer {
  display: flex;
  width: 90%;
  justify-content: center;
  align-items: center;
  background: #1e202c;
  border-radius: 10px;
  overflow: hidden;
}
.refer_linlk_ss .input_card_refer input {
  flex-grow: 1;
  height: 50px;
  background: transparent;
  border: none;
  color: #fff;
  padding: 0px 10px;
}
.refer_linlk_ss .input_card_refer i {
  color: #fff;
  padding: 0px 15px;
}
.refer_linlk_ss button {
  height: 50px;
  color: #fff;
  background: #1e202c;
  border-radius: 10px;
  margin-left: 8px;
}
.referal_inner_card {
  padding: 22px 11px;
  background: #313545;
  margin: 20px 13px;
  border-radius: 13px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.referal_inner_card h3 {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
}
.referal_inner_card p {
  margin: 0;
  font-weight: 900;
  color: #6922aa;
  line-height: 35px;
  font-size: 34px;
}
.swap_img_section .Dropdown-arrow {
  top: 19px;
}
.referal_card ul {
  display: flex;
  justify-content: center;
}
.referal_card ul li a {
  display: block;
  padding: 5px 10px;
  font-size: 24px;
  color: #999;
}
.referal_card ul > div {
  margin: 0px 5px;
}
.nodatat {
  color: #fff;
  padding: 15px 0px;
}
.yiled_tabs {
  background: #313545;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 10px 0px;
}
.yiled_tabs ul.nav-pills li {
  width: 33%;
}
.yiled_tabs ul li a {
  width: 100%;
  text-align: center;
  padding: 10px 0px;
}
.yiled_tabs ul.pagination li {
  display: flex;
  margin: 0;
  width: 3%;
}
.max-btn_serction {
  flex-grow: 1;
  position: relative;
}
.max-btn_serction button {
  background: linear-gradient(
    90.15deg,
    #5c2d91 0.73%,
    #502abb 101.92%
  ) !important;
  color: #fff;
  font-size: 13px;
  padding: 3px 10px;
  position: absolute;
  top: 14px;
  right: 8px;
  border-radius: 10px;
}
.select_optin > div:first-child {
  background-color: transparent;
}
.css-qc6sy-singleValue,
.css-1pndypt-Input {
  color: #fff !important;
}
.css-1pndypt-Input {
  color: #fff !important;
}
.input_widyjad {
  width: 64%;
}
.seap_form_ .form_input_section {
  background: #111414;
  padding: 10px 20px;
  border-radius: 10px;
}
.seap_form_ .form_input_section p {
  display: flex;
  justify-content: space-between;
  color: #b5b8bc;
  padding: 10px 0px;
  margin-bottom: 9px;
}
.seap_form_ .form_input_section .input_seclect {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.seap_form_ .form_input_section .input_seclect input {
  flex-grow: 1;
  height: 50px;
  background: transparent;
  color: #fff;
  font-weight: 600;
  font-size: 23px;
  letter-spacing: 0.3px;
}
.seap_form_ .form_input_section .input_seclect select {
  height: 38px;
  padding: 0px 12px;
  background: #21262a;
  color: #fff;
  font-weight: 600;
  border-radius: 19px;
}
.seap_form_ .form_input_section .input_seclect .tex_clas_ {
  height: 38px;
  padding: 0px 5px;
  background: #21262a;
  color: #fff;
  font-weight: 600;
  border-radius: 19px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 30px !important;
}
.seap_form_ .ui.selection.dropdown .menu > .item {
  display: flex;
  padding: 13px 10px !important;
}
.seap_form_ .ui.selection.visible.dropdown > .text:not(.default) {
  color: rgb(255 255 255 / 80%);
}
.icon_transdfer {
  display: flex;
  justify-content: center;
  padding: 20px;
}
.seap_form_ {
  padding: 40px 0px;
  margin-bottom: 0px;
}
.icon_transdfer span {
  color: #fff;
  transform: rotate(90deg);
  font-size: 23px;
  background: #111414;
  width: 50px;
  display: block;
  display: flex;
  padding: 15px 21px;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-radius: 50px;
}

.buttons_edit {
  display: flex;
  gap: 25px;
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  height: 80vh;
}
#tFA .form_login_section {
  background: transparent;
}
.loading i {
  font-size: 50px;
  color: #553bf5;
}
.loading_icon {
  font-size: 50px;
  color: #553bf5;
}
.light .overflow_seee {
  color: #121212;
}

.light .seap_form_ .form_input_section {
  background: #f9f9f9 !important;
  color: #000;
}
.light .seap_form_ .form_input_section .input_seclect .tex_clas_ {
  background: #21262a33;
  color: #000;
}
.light .seap_form_ .ui.selection.visible.dropdown > .text:not(.default) {
  color: rgb(0 0 0 / 80%);
}
.light .seap_form_ .form_input_section .input_seclect input {
  color: #000;
}
.seap_form_ .form_input_section .input_seclect .tex_clas_ {
  min-width: 114px;
}
.seap_form_ .ui.selection.dropdown .menu > .item span.text {
  display: none;
}
.swap_img_section .tex_clas_ {
  background: transparent !important;
  border: none !important;
}
.ui.active.selection.dropdown > .dropdown.icon,
.ui.visible.selection.dropdown > .dropdown.icon {
  color: #fff !important;
}
.ui.active.selection.dropdown {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  width: 122px;
}
.ui.selection.dropdown {
  background: transparent !important;
  border: none !important;
}
.ui.fluid.dropdown {
  display: block !important;
  width: 100% !important;
  min-width: 125px !important;
  background: transparent !important;
  color: #fff !important;
}
.fiter_drobdown .dropdown.drop_list {
  color: #fff;
  background: #1e202c;
  padding: 8px 11px;
  text-align: left;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 100px;
}
.fiter_drobdown {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.fiter_drobdown .ui.dropdown .menu {
  left: -38px !important;
}

/* Light mode */
.light .fiter_drobdown .dropdown.drop_list {
  color: #000;
  background: #f7f8fa;
}
.light .ui.active.selection.dropdown > .dropdown.icon,
.light .ui.visible.selection.dropdown > .dropdown.icon {
  color: #000 !important;
}
.light .ui.search.dropdown > .text {
  color: #000 !important;
}
.light .ui.fluid.dropdown > .dropdown.icon {
  color: #000 !important;
}
.light .nodatat {
  color: #000;
  padding: 15px 0px;
}
.light .ui.search.dropdown.active > input.search,
.light .ui.search.dropdown.visible > input.search {
  cursor: auto;
  color: #000 !important;
}
.light .css-qc6sy-singleValue {
  color: #000 !important;
}
.light .css-1pndypt-Input {
  color: #000 !important;
}

.light .refral_contene h1 {
  color: #000;
}
.light .referal_card {
  background: #fff;
}
.light .tilet_refer span {
  color: #000;
}
.light .register_login button > span {
  color: #fff !important;
}
.light .refer_linlk_ss .input_card_refer {
  background: #f7f8fa;
}
.light .register_login .summer_swap span {
  color: #000 !important;
}
.light .summer_swap h4 {
  color: #000;
  border-bottom: 1px solid #6363635c;
}
.light .refer_linlk_ss .input_card_refer input {
  color: #000;
}
.light .refer_linlk_ss .input_card_refer i {
  color: #000;
}
.light .refer_linlk_ss button {
  color: #000;
  background: #f7f8fa;
}
.light .referal_inner_card {
  background: #ffffff;
}
.light .referal_inner_card h3 {
  color: #000;

  margin-bottom: 9px;
}
.light .swap_img_section {
  background: #ebebeb;
  color: #000;
  border: 1px solid #6e7073 !important;
}
.tranbg {
  background-color: transparent;
}
.light .under_maintaine h1 {
  color: #000;
}
.light .under_maintaine h3 {
  color: #000;
}
.light .content-wrapper .view_now {
  background: #ffffff;
}
.light .content-wrapper .input_section .Dropdown-control {
  color: #000 !important;
}
.light p.balance {
  color: #000;
}
.light .price_sectiom_ p span.priceamt {
  color: #000;
}
.light .kyc_colorr p.banskksss {
  font-size: 20px;
  margin-bottom: 12px !important;
  font-weight: 500;
  color: #000;
}
.light .text-width_wrwpp {
  color: #000 !important;
}
.light .change_password_section .input-group-addon {
  top: 14px !important;
  color: #000;
}
.light .kyc_colorr p {
  color: #000 !important;
}
.light .title_blocc {
  color: #000;
}
.light .padin_cls_00 > span {
  color: #000;
}
.light .ky_c_contnet p {
  color: #000;
}
.light .kyc_card .form-group input {
  height: 50px;
  border: 1px solid #5b6178;
  background: transparent;
  color: #000;
}
.light .kyc_card .form-group label {
  color: #000;
}
.light .search_theme {
  border: 1px solid #e4e4e4;
  background: #fff;
}
.light .search_theme input {
  padding: 5px 11px;
  background: transparent;
  color: #000;
}
.light .menu_profile > button i {
  color: #000;
}
.light
  .pro-sidebar
  .pro-menu
  .pro-menu-item
  > .pro-inner-item
  > .pro-item-content {
  color: #000;
}
.light .profile_section {
  background: #ffffff;
}
.light .card_profile h3 {
  color: #1e202c;
}
.light .clorerree button {
  color: #1e202c;
}
.light .content-wrapper {
  background: #f7f8fa;
}
.light .card_profile {
  background: #ffffff;
}
.light .sideber_header .search_banner input {
  background: #fff;
  color: #000;
}
.light .sideber_header .search_banner {
  border: 2px solid #e4e4e4;
}
.light a.active .header_icon_meniu {
  color: #fff !important;
  background: linear-gradient(
    90.15deg,
    #5c2d91 0.73%,
    #502abb 101.92%
  ) !important;
  border-radius: 6px;
  filter: drop-shadow(1px 2px 2px #828282);
}
.light .sideber_header {
  background-color: #fff;
}
.light .pro-sidebar-content a .header_icon_meniu {
  color: #000;
}
.light .pro-sidebar > .pro-sidebar-inner {
  background: #fff !important;
}
.light .launchpad_doce h1 {
  color: #000;
}
.light .launchpad_doce p {
  color: #000;
}
.light .profile_section .staking_title {
  box-shadow: none;
}
.light .content-wrapper .bg_card_section {
  background: #fff;
  box-shadow: none;
  background: #ffffff;
  border-radius: 10px;
}
.light .content-wrapper .tradepage-bg {
  background: transparent;
  background-image: none;
}
.light .submit_butn_s button {
  background: linear-gradient(
    90.15deg,
    #5c2d91 0.73%,
    #502abb 101.92%
  ) !important;
  border-radius: 8px;
}

.my_fund_content {
}
.light .need_meanissss input,
.light .need_meanissss select,
.light .need_meanissss textarea {
  border: 1px solid #cbcbcb;
  color: #000;
  background: #f4f4f47d;
}
.light .need_meanissss .form-control:focus {
  border: 1px solid #cbcbcb;
  color: #000;
  background: #c7c7ca7d;
}
.light .field__label {
  color: #777e91;
}
.light .sessions__row:first-child .sessions__col {
  border-bottom: 1px solid #cacaca;
  color: #777e91;
}
.light .seclelee .Dropdown-control {
  height: 50px;
  background: transparent !important;
  border: 1px solid #999 !important;
  border-radius: 1px !important;
  border: 1px solid #666565 !important;
  color: #000;
}
.light .seclelee .Dropdown-control .Dropdown-placeholder {
  /* height: 50px; */
  padding: 3px 0px;
  color: #6f7880;
}
.light #dropdownMenuLink {
  background: transparent;
  color: #252525 !important;
}
.light header {
  background: transparent;
  box-shadow: 0px 0px 3px #ccc;
}
.light .class_pair_table .dropdown-menu {
  background: #fff !important;
}
.light .tradepage-bg {
  background: transparent;
  background-image: none;
  height: 100%;
  background-size: auto !important;
  background-repeat: no-repeat;
  background-position: center;
  /* min-height: 100vh; */
}
.light .kycdetaile p {
  color: #000;
}

.light .kycdetaile p:first-child {
  color: #000;
}
.light .home_vedio::after {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 10%,
    rgba(241, 250, 254, 0.334) 100%
  );
}
.light .home_vedio-new::after {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 10%,
    rgba(241, 250, 254, 0.334) 100%
  );
}
.light .section_4::before {
  display: none;
}
.light .foem_group .text-primary {
  color: #000 !important;
}
.light .section_4 .card_ {
  background: #fff;
  box-shadow: 0px 2px 3px #bcbcbc;
}
.light .section_4 .card_ .content h2 {
  color: #000;
}
.light .section_4 .card_ .content p {
  color: #607297;
}
.light .section_4 .button_section button:nth-child(2) {
  color: #7956e1;
  border: 1px solid #7956e1;
}
.light .section_6 .table_ {
  background: #fff;
  box-shadow: 0px 2px 3px #bcbcbc;
}
.light .table_ .coin_devlob h1 {
  color: #000;
}
.light .table_ .table td,
.table_ .table th {
  color: #4e4e4e;
}
.light footer .first_content .social {
  filter: none;
}
.light .table_ .table thead th {
  color: #000;
}
.light .trade_chart222 {
  background: #fff !important;
  box-shadow: none;
  border-radius: 0;
}
.light .market_palace {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 5px !important;
  margin: 5px 0px;
  background: #f3f3f3 !important;
}
body.light {
  background-color: #fff !important;
}
.card_light-w {
  width: 100%;
}

.light .card_light {
  background: #f3f3f3;
  margin: 2px 2.5px;
  padding: 7px 5px;
  box-shadow: none;
  border-radius: 0;
  border: 1px solid #cfcfcf;
  border-radius: 10px;
}
.light .card_light-w.transe {
  padding: 0 !important;
  border: none;
}
.light .order_book_ .card_light {
  border: none;
}
.light .trade-colpading {
  padding-right: 5px;
  padding-left: 5px;
  padding: 0;
}
.light .trade-colpadingheader {
  padding-right: 5px;
  padding-left: 5px;
}
.light .my_fund_content .title_aa {
  margin: 0;
  color: #777e90 !important;
}
.order-table {
  width: 100%;
}
.light .order-table a.active {
  color: #553bf5;
  border-radius: 24px;
  border: 1px solid #553bf5;
  padding: 6px 12px;
  background: -webkit-linear-gradient(91.43deg, #5c2d91 2.55%, #9f02b9 123.64%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #553bf5;
}
.light .order-table a {
  padding: 6px 12px;
}
.light .coinpair li a.active {
  color: #3b9bfb;
  background: -webkit-linear-gradient(91.43deg, #5c2d91 2.55%, #9f02b9 123.64%);
  background-clip: border-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.light .foem_group .form-group input {
  border: 2px solid #a8a8a8;
  color: #252525;
  border: none;
}
.light .rc-slider-rail {
  height: 2px !important;
  background-color: #a8a8a8 !important;
}
.light .price_section button {
  color: #000;
}
.light .price_section .text_red {
  color: #1dbbb3;
}
.light .col-green {
  color: #1dbbb3 !important;
}
.light .coinpair .fixTableHead tr:hover {
  background: #dbdbdb75;
}
.light .market_price_secrtiom h5 {
  color: #000;
}
.light .position_rel_over .red_overlay {
  background: #ff38984f;
}
.light .order-table .table td {
  color: rgb(71, 77, 87);
  font-size: 12px;
  font-weight: 500;
  border: none !important;
}
.light .position_rel_over .red_overlay {
  height: 30px;
}
.light .position_rel_over .green_overlay {
  height: 30px;
}
.light .curront_price .text-redc {
  color: #000;
}
.light .curront_price {
  display: flex;
  border: 1px solid #c4c4c4;
  border-left: 0;
  border-right: 0;
}
.light .fixTableHead th {
  background: #f3f3f3;
  color: #000;
}
.light .img_pair {
  color: #000;
}
.light .title-table h4 {
  color: #000;
}
.light .fixTableHead td {
  color: #000;
}
.light .title-table li a.active {
  color: #3b82f6;
  background: -webkit-linear-gradient(91.43deg, #5c2d91 2.55%, #9f02b9 123.64%);
  background-clip: border-box;
  background-clip: border-box;
  background-clip: border-box;
  -webkit-background-clip: text;
}
.light .search_option {
  border: 1px solid #a5a5a5;
  color: #000;
}
.light .search_option > input {
  color: #000;
}
.light .order_type h3 {
  color: #000;
}
.light .search_option i {
  color: #000;
}
.light .notification {
  color: #000;
  border: 1px solid #ccc;
  border-radius: 50px;
  height: 40px;
  width: 40px;
  /* display: flex; */
  padding: 0px 3px;
}
.light .notification > i {
  color: #553bf5 !important;
}
.lighttheme {
  display: none;
}

.light .trade111 {
  color: #fff;
}
.light .trade111:hover {
  color: #fff;
}
.light .darktheme {
  display: none;
}
.light .lighttheme {
  display: block;
}

body.light {
  background-image: url(../src/img/wavewhite.png);
}
.light .first_content h1 {
  color: #fff;
}
.light .first_content .social a img {
  /* filter: brightness(0) invert(0);
  -webkit-filter: brightness(0) invert(0); */
  filter: none;
}
.light .login_content_section h1 {
  color: #000;
}
.light .login_content_section p {
  color: #363636;
}
.light .form_login_section h1 {
  color: #000;
}
.light .form_login_section p {
  color: #686868;
}
.light .register_login label {
  color: #252525;
}
.light .register_login h1 {
  color: #000;
}
.light .range_btn > button {
  color: #3b82f6;
}
.light .register_login .form-control {
  background: #ebebeb;
  color: #000;
}
.test_center {
  text-align: center;
  display: block;
  color: #fff;
}
.light .test_center {
  text-align: center;
  display: block;
  color: #000;
}
.chart_loader {
  font-size: 50px;
  color: #553bf5;
  text-align: center;
  display: block !important;
  margin: 132px;
}
.light .button.class_buutn_resend {
  background: transparent !important;
  border: 1px solid #00a3ff !important;
}
.light .register_login span {
  color: #252525 !important;
}
.light .otp_content_e input {
  background: #ebebeb;
  color: #000;
}
.light .my_fund_content .bg_card_section {
  background: #fff;
  box-shadow: 0px 2px 3px #bcbcbc;
}
.light .my_fund_content .my_fund_sectin_ h1 {
  color: #000;
}
.light .my_fund_content .balance_section p {
  color: #7f818a;
}
.light .my_fund_content .balance_section h3 {
  color: #000;
}
.light .my_fund_content .bg_card_section .balanc_avail p {
  color: #000;
}
.light .my_fund_content .bg_card_section .button_account_bottn button {
  color: #3b82f6;
}
.light .my_fund_content .bg_card_section .balanc_avail h5 {
  color: #000;
}
.light .bg_card_section {
  background: #fff;
  box-shadow: 0px 2px 3px #bcbcbc;
}
.light .bg_card_section .table thead th {
  color: #000;
}
.light .page-link {
  color: #000;
}
.light .profile_section .nav-tabs li a.active {
  color: #5c2d91;
  background: bottom;
  border-radius: 0;
  filter: none;
  border-bottom: 0px solid #5c2d91;
}
.light .profile_section .nav-tabs li a.active img {
  /* filter: brightness(0) invert(1);
  -webkit-filter: brightness(0) invert(1); */
}

.light .profile_section .cars_bg_ss {
  background: #fff;
  box-shadow: none;
}
.light .profile_section .cars_bg_ss .name_section_profile h1 {
  color: #000;
}
.light .profile_section .cars_bg_ss .name_section_profile button {
  border: 2px solid #c1c1c1;
}
.light .profile_section .cars_bg_ss .futer_section h1 {
  color: #000;
}
.light .why-card .card-transparant {
  background: #fff;
  box-shadow: 0px 2px 3px #bcbcbc;
}
.light .profile_section .cars_bg_ss .futer_section .tilet_head {
  border-bottom: 1px solid #a0a1a4;
}
.light .profile_section .cars_bg_ss .futer_section .flex_container p,
.light .sessions__title {
  color: #000;
  background: -webkit-linear-gradient(91.43deg, #5c2d91 2.55%, #9f02b9 123.64%);
  background-clip: border-box;
  background-clip: border-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.light .profile_section .cars_bg_ss .futer_section .flex_container span {
  color: #777e90;
}
.light .row_oiu_p h5,
.light .sessions__content,
.light div.row_eee h3 {
  color: #1e202c;
}
.light .transaction .table td,
.light .transaction .table th {
  vertical-align: middle;
  color: #000;
}
.light .transaction .table td,
.light .transaction .table th {
  vertical-align: middle;
  color: #000;
  color: #777e91;
  border-bottom: 1px solid #cbcbcb !important;
}
.light .security__title {
  color: #000;
}
.light .security__info,
.light .security__text {
  color: #717070;
}
.light .security__email .icon {
  color: #000;
}
.light .security__email,
.light .security__subtitle {
  color: #000;
}
.light input#tfa_code {
  color: #000;
}
.light .change_password_section h1 {
  color: #000;
}
.light .why-card .card-transparant h5 {
  color: #000;
}
.light .why-card .card-transparant p {
  color: #999;
  text-align: center;
}
.light .kyc_card {
  background: #fff;
  box-shadow: none;
}
.light .contet_kyc_section > p {
  color: #000;
}
.light .foem_flex_input label {
  margin: 0;
  padding: 10px 0;
  color: #999;
}
.light .detaile_foem_kyc p {
  color: #000;
}
.light .detaile_foem_kyc .form-control {
  color: #000;
}
.light .tab_sectio_wallet .nav-tabs a {
  color: #000;
}
.light .detaile_foem_kyc .form-group label {
  color: #999;
}
.light .table td,
.light .table th {
  border-bottom: 1px solid #c7cad3;
}
.light .prime_deposit .deposit_p {
  background: #0000;
  border: 1px solid #3b82f6;
  color: #3b82f6;
}
.light .tab_sectio_wallet .nav-tabs a.active {
  color: #d95fbd;
}
.light .wallet_overview .table td {
  color: #000;
}
.light .balance_section .padin_cls_00 h1,
.light .balance_section h3,
.light .copy_code_section input,
.light .copy_code_section label,
.light .discimer ul li,
.light .form_wiuthdrawl input,
.light .form_wiuthdrawl label,
.light .form_wiuthdrawl p {
  color: #000;
}
.light .why-chooks h3 {
  color: #000;
}
.light .abute h4 {
  color: #000;
}
.light .abute h5 {
  color: #211c1c;
}
.light .abute p {
  font-size: 17px;
  color: #999;
}
.section-4 {
  background-image: url("../src/img/Ellipse8.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.light .tocken_details h4 {
  color: #000;
}
.light .title_advantage p {
  color: #000;
}
.light .partercc {
  filter: brightness(0) invert(0);
  -webkit-filter: brightness(0) invert(0);
}

.light .bg-color--1 {
  background: #fff;
}
.light .footer-widget .widget-title {
  color: #000;
}
.light .rn-footer-one .footer-left .rn-footer-describe {
  color: #252525;
}

.light .footer-list-one .single-list a {
  color: #252525;
}
.light .navbar-toggler-icon {
  color: #000;
  font-size: 28px;
}
.light .staking_title {
  border: 0px solid #383838;
  background: #fff;
  box-shadow: none;
  background: #ffffff;
  border-radius: 10px;
}
.price_section button {
  color: #fff;
}
.field__input,
.field__textarea {
  color: #fff;
}
.light .staking_title h1 {
  color: #1e202c;
}
.light .staking_price h3 {
  /* color: #999; */
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #737992;
}
.light .fixTableHead .aPY {
  background: #fff;
  color: #000;
  border: 1px solid #d8d6d6;
}
.light .fixTableHead .duration button {
  background: #fff;
  color: #000;
  border: 1px solid #d8d6d6;
}
.light button.notactive {
  padding: 10px 14px !important;
  border-radius: 12px;
  border: 1.5px solid #553bf5 !important;
  background: transparent !important;
  color: #553bf5 !important;
}

.light .fixTableHead .minimum p {
  background: #00800040;
  border: 1px solid #8fad8f;
  color: #009200;
}
.light .fixTableHead .type p {
  background: #ff000030;
  border: 1px solid #cd9393;
  color: #d23535;
}
.light .modal-content {
  background: #fff;
}
.light #stacknow .fixTableHead .price h1 {
  color: #000;
}
.light .input_filed_for_stake .title_ h1 {
  color: #000;
}
.light .input_obx p {
  color: #737373;
}
.light .summery p {
  color: #737373;
}
.light .summery h4 {
  color: #4f4d4d;
}
.light .summery hr {
  border: 0.5px solid #cacaca;
}
.light .inpurrr {
  background: #cdcdcd;
  border: 1px solid #979797;
}
.light .instrucion h5 {
  color: #000;
}
.light .instrucion p {
  background: #4aa258;
  color: #8afd8a;
}
.light .inpurrr input {
  color: #000;
}
.light .fixTableHead .duration button.active {
  color: #fff;
  background: linear-gradient(
    90.15deg,
    #5c2d91 0.73%,
    #502abb 101.92%
  ) !important;
  border-color: transparent;
  background: #3b82f6;
  border-color: transparent;
}
.light .fixTableHead .duration button.active {
  background: #3b82f6;
  border-color: transparent;
}

/* .light .pair_section:hover .first_div_sec h2 {
  color: #fff;
} */
.light .pair_section:hover {
  background: #dbdbdb;
}
.light .launch_card {
  border: 0px solid #383838;
  background: #fff;
  box-shadow: none;
  transition-duration: 0.3s;
  border-radius: 0;
  border-radius: 0;
}
.light .view_now .launch_card {
  border-top: 3px dotted #999;
}
.light .launch_card:hover {
  cursor: pointer;
  box-shadow: 0px 0px 16px #ccc;
}
.light .launch_card .token_img_ h3 {
  color: #000;
}
.light .launch_card .details p {
  color: #8c92ab;
}
.light .launch_card .details p span.text_uniqwe {
  color: #1e202c;
}
.light .launch_card .details .progress {
  background-color: #c0c0c0;
}
.light .title_launce {
  color: #1e202c;
}
.light .view_now .title_section h3 {
  color: #000;
}
.light .view_now .title_section p {
  color: #534f4f;
}
.light .price_sectiom_ p {
  color: #8c92ab !important;
}
.light .view_now .details_set a {
  color: #141313;
  background: #c1c1c1;
  border: 1px solid #b7b7b7;
  transition-duration: 0.3s;
}
.light .view_now .details_set a:hover {
  box-shadow: 0px 0px 2px #494949;
}
.light .buttn_section_ .progress {
  background-color: #c0c0c0;
}
.light .launch_card.new_table h3 {
  color: #0b0b0b;
}
.light .launch_card.new_table p {
  border-bottom: 1px solid #dedada;
}
.light .input_section p {
  color: #555;
}
.light .input_section .input_select_s > input {
  color: #000;
}
.light .input_section {
  border: 1px solid #e4e4e4;
}
.light .select_width .form-select {
  color: #141414;
}
.light .countdown .countdown-container .countdown-value::after {
  color: #382e2e00;
}
.light .countdown .countdown-container .countdown-value {
  color: #272727;
  background-color: rgba(202, 202, 202, 0.3);
}
.light .countdown .countdown-container .countdown-heading {
  color: #585656;
}
.light .countdownw p {
  color: #373636;
}
.light .countdownw .count_section {
  color: #000;
}
.light #progressbar li::before {
  color: #000;
  background: #c8c8c8;
}
#progressbar li.active::before,
#progressbar li.active::after {
  background: linear-gradient(
    90.15deg,
    #5c2d91 0.73%,
    #502abb 101.92%
  ) !important;
}
.light #progressbar li::after {
  background: #c8c8c8;
}
.light .fonrm_card_s h2 {
  color: #000;
}
.light .fonrm_card_s label {
  color: #000;
}
.light #msform input,
.light #msform textarea,
.light #msform select {
  border: 1px solid #666565;
  color: #000;
}
.light .custom-control-label::before {
  background-color: #b3b0b0 !important;
  border: #4b4e51 solid 1px !important;
}
.light
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::after {
  filter: brightness(0) invert(0);
  -webkit-filter: brightness(0) invert(0);
}
.light .logo_launchpad h1 {
  color: #000;
}
.light .p2ppost {
  padding: 0px 0px;
  border: 0px solid #383838;
  background: #fff;
  box-shadow: none;
  border-radius: 10px;
}
.light .p2plist ul {
  background: #e5e5e5;
}

.light .p2ppost li a {
  color: #737373;
}
.light .buy_sell_s .nav-tabs.primaryNav .nav-link:hover {
  color: #737373;
}
.light .buy_sell_s .primaryNav .nav-link.color-green.active {
  box-shadow: 3px 0 0 #a29d9d;
}
.light .buy_sell_s .primaryNav .nav-link.color-red.active {
  box-shadow: 3px 0 0 #a29d9d;
}
.light .paymet-mode {
  background: #e5e5e5;
}
.light .curroncy_blac label {
  color: #646363;
}
.light .curroncy_blac .Dropdown-control {
  background: #e5e5e5 !important;
  color: #818181 !important;
}
.light .peer2peer_section {
  padding: 17px 0px;
}
.light .address_content {
  color: #736d6d;
}
.light .order_type_details label {
  color: #736d6d;
}
.light .order_type_details p {
  color: #000;
}
.light .payment_method_card {
  border: 1px solid #b1aeae;
}
.light .payment_method_card h5 {
  border-bottom: 1px solid #b1aeae;
  color: #000;
}
.light .payment_method_card p {
  color: #000;
}
.light .payment_method_card label {
  color: #736d6d;
}
.light .chatbox {
  background: #e5e5e5;
}
.light .chat-text {
  background: #fff;
  border-radius: 10px;
}
.light .chat-text input {
  background: #e5e5e5;
  border: 1px solid #c2c1c1;
  color: #000;
}
.light .message div > span {
  color: #868686;
}
.prime_deposit img {
  width: 114px;
  margin: 0 0;
}
.light .message div {
  background: #fff;
  color: #000;
  min-width: 100px;
  box-shadow: 0px 0px 4px #ccc;
}
.light .p2ppost ul {
  background: #fff;
  border-bottom: 1px solid #ccc;
}
.light .p2ppost li a.active {
  background: #313545;
  color: #fff !important;
  background: -webkit-linear-gradient(91.43deg, #5c2d91 2.55%, #9f02b9 123.64%);
  background-clip: border-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-bottom: 3px solid #7a2da4;
}
.light .buyform_now .Dropdown-control {
  background: transparent !important;
  border: 1px solid #e4e4e4 !important;
}
.light .buyform_now label {
  color: #737373;
}
.light .Dropdown-option {
  background: #ccc;
  color: #000 !important;
}
.light .buyform_now .Dropdown-placeholder {
  color: #6c757d;
}
.light .buyform_now .Dropdown-option:hover {
  background: linear-gradient(
    90.15deg,
    #5c2d91 0.73%,
    #502abb 101.92%
  ) !important;
  color: #fff !important;
}
.light .buyform_now .form-control {
  background: transparent !important;
  border: 1px solid #e4e4e4;
  color: #000;
}
.light .bg-coverneww {
  background: #f7f8fa !important;
}
.light .Dropdown-option.is-selected {
  background: #ccc;
  color: #fff !important;
}
.header-non header.navbar-custom {
  display: none;
}
.bg-none {
  background: transparent !important;
  padding: 0;
}
.button_view_modee {
  display: block !important;
  width: 18% !important;
  padding: 14px 0px;
}
.launcpad button a {
  color: #fff;
}
.widith_statksind {
  width: 80% !important;
}

.dex_default img {
  width: 64px;
  height: 64px;
  border-radius: 50%;
}

.progredsss_launch .processs {
  width: fit-content;
  color: #21a75f;
  background: #12281c;
  padding: 6px 15px;
  border-radius: 30px;
}
.progredsss_launch .date_section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}
.progredsss_launch .date_section p {
  font-weight: 500;
  color: #cecece;
  font-size: 16px;
  margin: 0;
}
.progredsss_launch .date_section p span {
  font-weight: 200;
  margin-right: 10px;
  color: #949393;
  font-size: 14px;
}
.progredsss_launch {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.price_pair {
  background: #202020;
  padding: 10px;
  height: 130px;
  display: grid;
  place-content: center;
  border-radius: 8px;
}
.price_pair .paitr_content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.price_pair .paitr_content {
  font-size: 33px;
  color: #cecece;
}
.price_pair .paitr_content img {
  height: 32px;
  width: 32px;
}
.contant_details {
  padding: 30px 0px;
}
.contant_details h3 {
  font-size: 34px;
  font-weight: 500;
  color: #cecece;
  margin-bottom: 0;
  margin-bottom: 12px;
}
.contant_details p {
  text-align: left;
  color: #cecece;
  font-weight: 300;
  font-size: 17px;
}
.innaer_details {
  padding: 10px 0px;
}
.innaer_details label {
  color: #cecece;
  margin-bottom: 9px;
}
.innaer_details h5 {
  margin: 0;
  font-size: 20px;
  color: #cecece;
  font-weight: 500;
}

.section_tabs_active {
  display: flex;
  align-items: center;
  gap: 10px;
}
.section_tabs_active .icons_ {
  width: 50px;
  height: 50px;
  display: grid;
  place-content: center;
  font-size: 24px;
  color: #fff;
  background-image: url("./img/New_images/countbg.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.contant_collect h6 {
  font-size: 16px;
  color: #cecece;
  margin-bottom: 5px;
}
.contant_collect p {
  font-weight: 300;
  font-size: 14px;
  color: #949393;
}
.mgs_content {
  padding-left: 60px;
  padding-top: 10px;
  position: relative;
  margin-bottom: 20px;
}
.mgs_content::before {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  background: transparent;
  left: 23px;
  top: 7px;
  border-left: 2px dashed #3d3d3d;
}
.mgs_content p {
  font-size: 14px;
}
.mgs_content .card_bg {
  width: -moz-fit-content;
  width: fit-content;
  padding: 15px 20px;
  background: #202020;
  border-radius: 10px;
  min-width: 40%;
}
.mgs_content .card_bg label {
  color: #cecece;
  margin-bottom: 0px;
}
.mgs_content .card_bg h4 {
  margin: 0;
  font-size: 34px;
  padding: 20px 0px;
  font-weight: 500;
  color: #cecece;
  padding-bottom: 10px;
}
.mgs_content .card_bg button,
.mgs_content .card_bg button:hover {
  background: transparent;
  color: #9685ff;
  border: 1px solid #9685ff;
  border-radius: 10px;
}
.hid_content {
  height: 40px;
  opacity: 0;
}
.active .hid_content {
  height: auto;
  opacity: 1;
}
.span_graycolor {
  color: #9685ff;
}
.contecnt_main {
  color: #cecece;
}
.teble_primary_color {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 60%;
}
.teble_primary_color.coloe {
  background: #202020;
}
.teble_primary_color div {
  width: 48%;
  padding: 14px 22px;
}

/*  */

.stake_box {
  margin-top: -17px !important;
  padding: 14px 25px;
  background: #202020;
  border-radius: 16px;
}
.light .stake_box {
  background: #ededed;
}

.light_color {
  font-weight: 300;
  font-size: 14px;
  color: #949393;
}
.bold_fonr {
  font-weight: 400;
  color: #cecece;
  font-size: 16px;
}

.checkbox {
  opacity: 0;
  position: absolute;
}

.checkbox-label {
  background-color: #3f3b3b;
  width: 50px;
  height: 26px;
  border-radius: 50px;
  position: relative;
  padding: 6px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
}

/*Light mode  */
.light .checkbox-label {
  background-color: #ffffff;
}
.light .checkbox-label .ball {
  background-color: #553bf5;
}

.light .mgs_content .card_bg label {
  color: #252525;
}

.light .modal-title {
  color: #252525;
}

.light .modal-text1 span {
  color: #252525;
}

.light .modal-text1 p {
  color: #252525;
}

.light .modal-text span {
  color: #252525;
}

.light .amt p {
  color: #252525;
}

.light #input {
  background: #ededed;
  color: #252525;
}

.light .modal-text {
  color: #252525;
}

.light .mgs_content .card_bg button:hover {
  background: #553bf5;
  color: #fff;
}

.light #cancel-btn:hover {
  background: #553bf5;
  color: #fff;
}

/*  */
.ri-moon-fill {
  color: #8875ff;
}

.ri-sun-fill {
  color: #8875ff;
}

.checkbox-label .ball {
  background-color: #fff;
  width: 22px;
  height: 22px;
  position: absolute;
  left: 2px;
  top: 2px;
  border-radius: 50%;
  transition: transform 0.2s linear;
}

.checkbox:checked + .checkbox-label .ball {
  transform: translateX(24px);
}

/*  Support me if you like it */
.support {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.support a {
  color: #292c35;
  font-size: 32px;
  backface-visibility: hidden;
  display: inline-block;
  transition: transform 0.2s ease;
}

.support a:hover {
  transform: scale(1.1);
}

.timer_section1 .timer-sect {
  display: flex;
  width: fit-content;
}

/* .download_icon {
  background: #fff;
  margin: 10px;
  padding: 10px !important;


  .



/* new pages light mode css */
.light .Card_dashboard p span {
  color: #242424;
}
.light .button_footer .button {
  border: 1px solid #553bf5 !important;
  color: #553bf5 !important;
}

.light .Card_dashboard p {
  color: #242424;
}

.light .page-item.active .page-link {
  background-color: #553bf5;
  border-color: #553bf5;
}

.light .content_section h3 {
  color: #242424;
}

.light .text-color-pur {
  color: #242424 !important;
}
.light .font_color {
  color: #242424;
}

.light .hr_line {
  background: none;
}

.light .new_login_bb {
  background: #f8f8f8 !important;
}
.light .custom-appbar {
  background-color: #f3f3f3 !important;
  border-bottom: 1px solid rgb(255 255 255 / 49%);
}
.light .trade_page_new .pair_selelction a {
  border-radius: 8px;
  border: 1px solid #a9a9a9;
  background: #fff !important;
  width: -moz-fit-content;
  /* width: fit-content; */
  text-align: left;
  color: #000;
}
.light .trade_page_new .class_pair_table .fixTableHead th {
  background: #fff !important;
  color: #000 !important;
}

/* 
.light .trade_page_new .pair_selelction a {
  background: #f8f8f8 !important;
  width: 200px;
  text-align: left;
} */

.light .trade_page_new .market_price_secrtiom span {
  color: #252525;
}

.light .pink_text {
  color: #e84747 !important;
}

.light .trade_page_new .order-table a {
  color: #252525;
}
.light .trade_page_new .trade_card {
  border: 1px solid #cfcfcf;
  background: #f8f8f8 !important;
}
.light .trade_page_new .order-table .table thead th {
  color: #252525;
  background: #f8f8f8;
  font-size: 15px;
}
.light .inner_form li a {
  border-bottom: 0px solid #252525 !important;
}
.light .foem_group .form-group {
  border: 1px solid #cfcfcf;
  color: #000;
  background: #fff !important;
  border-color: #cfcfcf !important;
}
.light .trade_page_new .foem_group .form-group .currency {
  right: 14px;
  padding: 0;
  background: transparent;
}
.light .trade_page_new .order_book_ .tab-content {
  background: #90909000;
  padding: 8px 16px;
  border-radius: 16px;
  border: 1px solid #cfcfcf;
}

.light .color_padinr {
  background: #f3f3f3;
  border: 1px solid #cfcfcf;
}
.light .formtext label {
  color: #8c8c8c;
}

.light .new_buyform .formplac li a {
  display: flex;
  color: #252525 !important;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex: 1 0;
  border-radius: 12px;
  font-weight: 500;
  border: 1.5px solid #553bf5;
}

.light .trade_page_new .border_botton .title-table li a {
  color: #252525;
  font-weight: 500;
}
.light .trade_page_new .border_botton .title-table li a.active {
  color: #553bf5 !important;
  border-bottom: 2px solid #553bf5;
}

.light .range_btn > button.active {
  background: none !important;
  border: 1px solid #553bf5;
}
.light .range_btn > button:hover {
  background: none !important;
  border: 1px solid #553bf5;
}

.light .range_btn > button {
  border: 1px solid #cfcfcf;
  color: #252525;
}

.light .heading_card_new {
  background: lightgray 50%;
}
.light .heading_card_new h1 {
  color: #252525;
}

.light .heading_card_new::before {
  background: #553bf5;
}

.light .bootsrab_tabs .nav li a.active {
  color: #fff;
  background: #553bf5;
}

.light .bootsrab_tabs .nav li a {
  border: 1.5px solid #553bf5;
  color: #553bf5;
}

.light .histroy_tabs .nav li a.active {
  color: #131313;
  background: #e3e3e3;
}

.light .histroy_tabs .nav li a {
  font-weight: 500;
  color: #252525;
  border: 1px solid #cfcfcf;
  border-radius: 16px;
}

.light .small_search.bar label {
  color: #252525;
}

.light .small_search.bar .ui.fluid.dropdown {
  border: 1px solid #cfcfcf !important;
}

.light .ui.default.dropdown:not(.button) > .text,
.ui.dropdown:not(.button) > .default.text {
  color: #131313;
}

.light .small_search.bar .form-control {
  border: 1px solid #cfcfcf !important;
  color: #131313 !important;
  font-weight: 500;
}

.light .border_table_row {
  background: #ededed;
  border: none;
}

.light .data_inner .name_details h5 {
  color: #131313;
}

.light .data_inner .name_details p {
  color: #131313;
}

.light .data_limit_p2p p span {
  color: #131313;
  font-weight: 300;
}

.light .data_limit_p2p p {
  color: #131313;
  font-weight: 500;
}

.light .buttonddd .data_inner {
  color: #131313;
  font-weight: 500;
}

.light .data_inner button.p2p2 {
  border: 1.5px solid #553bf5;
  color: #553bf5;
  font-weight: 500;
  background: none;
}

.light .ui.fluid.dropdown {
  color: #131313 !important;
  font-weight: 400;
}

.light button.primary_butn {
  background: #553bf5 !important;
  color: #fff !important;
}

.light .table-cell {
  color: #131313;
  font-weight: 400;
}

.light .card_launchpad {
  background: #f3f3f3;
  border-radius: 16px;
}

.light .launch_status span {
  color: #07a550;
}

.light .launch_status {
  background: #69c29142;
}

.light .coin_launch h5 {
  color: #252525;
}

.light .data_launc p {
  color: #252525;
  font-weight: 400;
}

.light .data_launc span {
  font-weight: 500;
  color: #252525;
}

.light .card_launchpad hr {
  background: #cfcfcf;
}

.light .search_bar {
  border-radius: 8px;
  border: 1px solid #e2e2e2;
  background: #f9fafa;
}

.light .new_login_bb .card_logoki {
  border-radius: 32px;
  border: 1px solid #d1d1d1;
  background: #f8f8f8;
}

.light .new_login_bb .form_pading_s {
  padding: 22px 25px;
  border-radius: 16px;
  background: #ededed;
  padding-bottom: 20px;
}

.light .new_login_bb .register_login .btn-primary {
  border-radius: 12px;
  background: #553bf5 !important;
}

.light a.forget {
  color: #553bf5;
}

.light .new_login_bb .card_logoki h1 {
  color: #252525;
  font-weight: 400;
}

.light .onlywhitee.new_login_bb .register_login .form-control {
  color: #000;
  border-radius: 12px;
  border: 1px solid #cfcfcf;
  background: #f9fafa;
}

.light .asidemeni {
  border: 1px solid #d1d1d1;
  background: #f8f8f8;
}

.light .navlink_new.active,
.light .navlink_new:hover {
  background: #553bf5;
  color: #ffffff !important;
}

.light .icon_menu {
  color: #252525;
}

.light .menu_items_fex .navlink_new {
  font-size: 19px;
  font-weight: 400;
  display: flex;
  gap: 10px;
  padding-left: 15px;
  width: 100%;
  color: #252525;
}
.notification_cls {
  justify-content: center !important;
  color: #fff;
}

.light .logo_new {
  display: none;
}
.light .light_logo {
  display: inline;
  width: 140px;
  cursor: pointer;
  padding: 12px;
}

.taikonz-logo img,
.dark_taikonz-logo img {
  width: 180px;
}

.light_logo {
  display: none;
  cursor: pointer;
}

.light button.primary_header_button {
  background: #553bf5;
  color: #fff;
}

.light .profile_content_image h3 span.verifyed {
  color: #26aa0a;
}

.light .profile_content h3 {
  color: #252525;
  font-weight: 600;
}

.light .profile_content p {
  color: #252525;
}

.light .form-group p {
  color: #252525 !important;
}

.light .form-group span {
  color: #000 !important;
  font-weight: 500;
}

.light .navlink_new {
  color: #242424;
}

.light button.link {
  color: #131313 !important;
}

.light .new_login_bb .form-group .text-danger {
  color: #dc3545 !important;
}

.light .a.accepte {
  border: 1px solid #f3f3f3;
  background: #fff;
}

.light .tabs_inside .nav li a.active {
  border-bottom: 2px solid #553bf5;
  color: #553bf5;
}

.light .tfa_box h3 {
  color: #242424;
}

.light .light .form_login_section p {
  color: #242424;
}

.light .tabs_inside .nav li a {
  color: #242424;
}

.light .tabs_inside .nav {
  border-bottom: 2px solid #cfcfcf;
}

.light .edit-button {
  color: #9685ff !important;
}
.light .payment_form {
  border-bottom: 2px solid #cfcfcf;
}

.light .data_inner {
  color: #242424;
}

.light .light .form-group span {
  font-weight: 500;
}

/* .light .foem_group .form-group.vuttond > button span {
  background: #553bf5 !important;
  color: #ffffff !important;
} */

.light .trade_page_new .border_botton .title-table li {
  border-bottom: 2px solid #d9d9d9;
}

.light .form_pading_s .ui.fluid.dropdown.text_memu {
  border-radius: 12px;
  border: 1px solid #cfcfcf !important;
  background: #ffffff !important;
}

.light .h2_bottomn {
  color: #242424;
}

.light .ui.dropdown > .text {
  color: #242424;
}

.light .form_pading_s .ui.selection.visible.dropdown > .text:not(.default) {
  color: #242424 !important;
}

.light .bg_tra_cades {
  background: #e7ebff;
}

.light .section_class_grs h5 {
  color: #131313;
}

.light .text_color_imhd {
  color: #242424 !important;
}

.light .new_login_bb .register_login .btn-second {
  color: #242424;
}

.light .text_area_input {
  /* background: #F9FAFA; */
  color: #242424;
}

.light #box .form-group {
  background: #ededed;
}

.light .cardEdeded {
  background: #f9f9f9;
}

.light .ne_bg_txt {
  background: #fff;
  border: 1px solid #cfcfcf;
}

.light .color_border {
  background: #fff;
}

.light .devision_clas {
  border-bottom: 1px solid #cfcfcf;
}
.light .chat_conent .chart_card {
  color: #131313;
}

.light .chat_contianer .chat_footer input {
  color: #8d8885 !important;
  border-radius: 8px;
  border: 1px solid #cfcfcf;
  background: #f3f3f3;
}
.light .button_cirle a {
  background: #fff;
}

.light a.btn.btn-primary,
.light .btn.btn-primary {
  background: #553bf5;
  color: #fff;
}

.light .Card_dashboard {
  background: #fff;
}

.light .button_footer {
  border-top: 1px solid #cfcfcf;
}

.light .spinloader {
  color: #242424 !important;
}

.light .light .spinloader {
  color: #242424 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.light #box {
  background: none;
}

.light #box::before {
  background-image: none;
}

.question {
  width: 500px;
  height: 500px;
}

.question-container {
  width: 100%;
  height: 100%;
}

.chart {
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
}
.light .new_buyform .formplac .new_color li a.active {
  border-bottom: 2px solid #6046ff !important;
  background: transparent;
  border-radius: 0px !important;
  -webkit-text-fill-color: #6046ff;
}
.inner_form.new_color li a {
  border-bottom: 0px solid #252525 !important;
}
.form-group.border-none.button_trans {
  background: transparent !important;
}
.form-group.border-none.button_trans button {
  width: 100%;
  background: #553bf5 !important;
  color: #ffffff !important;
  padding: 11px 0px;
}
.form-group.border-none.button_trans button:hover {
  width: 100%;
  background: #553bf5 !important;
  color: #ffffff !important;
  padding: 11px 0px;
}

.light .alert_red p {
  color: red !important;
}

.slick-slide img {
  display: block;
  border-radius: 50%;
}

.light .devision_clas {
  color: #686868 !important;
}

.register_login2 p {
  color: #7a7a7a !important;
}

.light .form_login_section .red_alert p {
  color: #dc3545 !important;
}

.grow_1.license span {
  margin-left: -11px;
}

.login_btn button {
  color: white !important;
}
button.btn.btn-primary-alta.login_btn {
  background: #4f41a4 !important;
  margin-left: 150px;
}
button.btn.btn-primary-alta.login_btn a {
  color: #fff;
}
button.btn.btn-primary-alta.login_btn:hover {
  color: #fff !important;
  background: #4f41a4 !important;
  border: 1px solid #553bf5 !important;
}

button.btn.btn-primary-alta.login_btn {
  background: #4f41a4 !important;
  margin-left: 150px;
}

.light button.btn.btn-primary-alta.login_btn {
  background: #553bf5 !important;
}

.light .register_login .red_alert p {
  color: #dc3545 !important;
}

button.notactive {
  padding: 10px 14px !important;
  display: flex;
  background: transparent !important;
  font-size: 15px;
  width: auto;
  color: #9685ff !important;
  border: 1px solid #9685ff !important;
  border-radius: 12px !important;
  margin-right: 10px;
  justify-content: center;
}

button.notactive:hover {
  color: #fff !important;
  background: #4f41a4 !important;
  border: 1p x solid #4f41a4 !important;
}
.light button .notactive:hover {
  color: #fff !important;
  background: #553bf5 !important;
  border: 1px solid #553bf5 !important;
}

.light .color_border.chat_theme {
  background: #e7ebff;
}
.register_login.form-bg {
  padding: 20px 20px !important;
  border-radius: 16px;
  background: #202020;
}

.light .register_login.form-bg {
  background: #f3f3f3;
}
.light .register_login.form-bg p {
  color: #252525 !important;
}

.minimum button {
  margin-right: 10px !important;
}

.table-row.header.text .table-cell {
  color: #7a7a7a !important;
}

.btn.btn-primary-alta.connectBtn.login_btn {
  width: 30%;
  margin-left: 44%;
}

.table-cell.data_msg {
  text-align: center;
}

.data_inner.flex_image_coloe.advertiser {
  width: 100%;
  justify-content: flex-end;
}

.data_inner.flex_image_coloe.asdfas {
  justify-content: center;
}

.dashboard_ .table-cell.asdfas {
  margin-left: 40px;
  justify-content: flex-start !important;
}

.light .padidnr_color {
  color: #553bf5 !important;
}

.light .text_icon_swap .cicle_section {
  background: #553bf5;
  color: #fff;
}

button.colo-fff > span,
button.colo-fff > span:hover {
  color: #fff !important;
}

.fixed_header button.colo-fff > span {
  color: #fff;
}
.fixed_header button.colo-fff > span:hover {
  color: #611da1 !important;
}
a.btn.clooee:hover {
  color: #fff !important;
}
.tradepage-bg.tradeee {
  height: 100% !important;
}

.col-red {
  color: #ef3e9e !important;
}

.model_confirms .form-control {
  height: 53px;
  background: transparent;
  color: #fff;
  border: 1px solid #6d6969;
}
.model_confirms label {
  color: #fff;
}

.light .model_confirms .form-control {
  height: 53px;
  background: transparent;
  color: #000;
  border: 1px solid #6d6969;
}
.light .model_confirms label {
  color: #000;
}

.heading_mwmu {
  padding: 0 !important;
}
.heading_mwmu h4 {
  font-size: 21px;
  color: #fff;
}
.order_eee {
  width: 27px;
  float: left;
  max-width: 19px;
  margin-right: 10px;
  filter: invert(100%) sepia(0%) saturate(7455%) hue-rotate(143deg)
    brightness(95%) contrast(103%);
}
.w-22px {
  width: 22px;
}

.tfa-bg {
  min-height: 100vh;
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #fff;
}
.light .menu_icon {
  color: #000;
}

/* p2p light */

.light .text-white {
  color: #252525 !important;
}

.slick-prev:before {
  content: url("../src/img/New_images/taikonx/Slider_arrow1.png") !important;
}
.slick-next:before {
  content: url("../src/img/New_images/taikonx/slider_arrow2.png") !important;
}

.notification_section #notification {
  background: #181818;
  border: 1px solid #3b82f640;
  z-index: 1;
}

.notification {
  color: #4f41a4;
}
.content_notification h3 {
  padding: 18px;
}
.light .content_notification h3 {
  color: #131313;
}

.light .notification.padinggrepos {
  color: #553bf5 !important;
}
.light .notification_section #notification {
  background: #f3f3f3;
  box-shadow: #f3f3f3 5px 5px !important;
}

.price_section .dropdown:hover .dropdown-menu {
  display: block;
}

.light p.text-danger {
  color: #dc3545 !important;
}

.light .agrreement p {
  color: #252525 !important;
}
input[type="checkbox"] {
  margin-left: 3px;
}

.table_message {
  width: 260px;
  overflow: hidden;
  word-break: break-word;
}
.light .secondary_header_button {
  border: 1px solid #553bf5;
  color: #553bf5 !important;
}

.light .new_login_bb .register_login .btn-primary.burdas_buttnd.cancel_burdas {
  background: none !important;
  border: 1px solid #553bf5;
  color: #553bf5;
}

.form_pading_s.summary_box {
  margin-top: -40px;
}

.card_logoki.pading_cardd.post_card {
  padding: 20px 80px !important;
}

.form_pading_s.buy-padding {
  padding: 24px 50px !important;
}

.table-cell.number {
  display: flex;
  justify-content: flex-start !important;
  margin-left: 34px;
}

.imgblur {
  filter: blur(15px) !important;
}
.porel {
  position: relative !important;
  top: 20px;
  left: 7px;
}
.posab {
  position: absolute !important;
  top: 25px;
  left: 134px;
}

.dashboard_ .table-cell {
  justify-content: start !important;
}

.account_setting .form-control {
  height: 40px !important;
}
.relati {
  position: relative !important;
}
.absolut {
  position: absolute !important;
  background-color: #000 !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  background-color: #202020 !important;
  border: 1px solid #5a5959 !important;
  color: white !important;
  border-radius: 5px !important;
}

.light .css-6hp17o-MuiList-root-MuiMenu-list {
  background-color: #fff !important;
  color: #202020 !important;
  border-radius: 5px !important;
  border: 1px solid #fff !important;
}

/* table */

.history-table-header .table-cell {
  border: none !important ;
  text-wrap: nowrap;
}

/* .css-1y942vo-MuiButtonBase-root-MuiButton-root.active,:active  {
  color: #4f41a4;
} */
.data_inner.all-history-data {
  text-wrap: wrap;
  margin-left: 20px;
}
.all-history-data {
  text-wrap: wrap;
  margin-left: 20px;
}
@media only screen and (min-width: 1382px) and (max-width: 3617px) {
  .widith_statksind {
    width: 100% !important;
  }
}
@media (max-width: 1280px) {
  .card_logoki.pading_cardd.post_card {
    padding: 20px 30px !important;
  }
  .dash_board_search_box {
    left: 38%;
  }
}

@media (max-width: 1100px) {
  .navbar-toggler-icon {
    color: #fff !important;
    font-size: 28px;
  }
  .fixed_header .navbar-toggler-icon {
    color: #000 !important;
    font-size: 28px;
  }
  .wallet_overview .table td {
    padding: 10px 27px;
    white-space: nowrap;
  }
  .home_vedio {
    display: none;
  }
  .homepage-section-banner > .container {
    position: static;
  }
  .section-2-s {
    margin-top: 0;
  }
  .padingrespos {
    padding: 20px 0px;
  }
  .order_type {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .padingrespos {
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .light .navbar-toggler-icon {
    color: #000 !important;
    font-size: 28px;
  }

  .padingrespos a.connectBtn {
    width: 40%;
  }
  .trade111 {
    margin: 6px auto;
    display: block;
    width: 100%;
    text-align: center;
  }
  .coinpair .fixTableHead {
    height: auto;
    max-height: 470px;
  }
  .uniqu_height {
    height: 472px;
    max-height: 472px;
    height: auto;
  }
  .fixTableHead th,
  .fixTableHead td {
    font-size: 14px;
    font-weight: 500;
  }
  .first_content h1 {
    font-size: 50px;
  }

  .first_content {
    padding: 59px 0px;
    padding-bottom: 0;
  }
  .section-2-s {
    padding-top: 0;
  }
  .why-card .card-transparant {
    margin: 0;
    margin-bottom: 25px;
  }
  .title_advantage {
    padding: 50px;
    padding: 25px 0px;
  }
  .partercc {
    width: 100%;
  }
  .homepage-section-banner {
    height: auto;
  }

  /* David */
  .int-per-cycle {
  }
  .coin_launch img {
    width: 60px;
  }

  .coin_launch h5 span {
    font-size: 20px;
  }
  .coin_launch h5 {
    font-size: 24px;
  }

  .Srake_status {
    font-size: 22px;
  }

  .css-1n5bjej-MuiGrid-root > .MuiGrid-item {
    padding-left: 2px !important;
  }

  .banner-text {
    font-size: 45px;
  }
  .y-taikonz-content h1 {
    font-size: 45px;
  }
  .currency-content h1 {
    font-size: 45px;
  }
  .investing-heading {
    font-size: 45px;
  }
  .crypto-platform-content h1 {
    font-size: 45px;
  }
  .crypto-platform-content p {
    font-size: 16px;
  }
}
@media (max-width: 1024px) {
  .history-table-header .table-cell {
    padding-left: 38px;
  }
  .stake-cell .minimum {
    display: flex;
    flex-direction: column;
  }
  .history-table-header.yield .table-cell {
    padding-left: 22px;
  }
  .history-table-header.fix .table-cell {
    padding-left: 20px;
  }
  .history-table-header.all .table-cell {
    padding-left: 20px;
  }
  .stake-tabel .buttonddd {
    background: transparent;
    border: none !important;
    justify-content: space-around;
  }
  .stake-tabel .stake-cell {
    padding-left: 30px;
    padding-right: 20px;
  }
  .int-per-cycle{
    padding-left: 55px !important;
  }
  .stake-cell .minimum{
    margin-bottom: -20px;
  }
}

@media (max-width: 900px) {
  .teble_primary_color {
    width: 100%;
  }
  .foem_group .form-group input {
    width: 85%;
  }
  .sideber_header {
    display: none;
  }
  .tradeee {
    padding: 0px 19px;
    padding-top: 15px;
  }
  .header-non header.navbar-custom {
    display: block;
  }
  #header {
    position: relative;
    display: none;
  }
  /* .content-wrapper .tradepage-bg {
    padding-top: 85px;
  } */
  .header-non .navbar-collapse {
    background: #fff;
  }
  .light .header-non .trade111 {
    color: #000;
  }
  .light .header-non .trade111:hover {
    color: #000;
  }
  .header-non header {
    background: #fff;
    box-shadow: 0px 0px 4px #ccc !important;
  }
  .header-non .darktheme {
    display: none !important;
  }
  .header-non .lighttheme {
    display: block !important;
  }
  .light .header-non .navbar-toggler-icon {
    color: #000 !important;
  }
  .stakingtext_new {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 0px;
  }
  .fixTableHead .duration {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    justify-content: center;
    padding: 8px 0px;
  }
  .fixTableHead .aPY {
    display: block;
    margin: 0 auto;
  }
  #stacknow .fixTableHead .price h1 {
    text-align: center;
    justify-content: center;
    margin-bottom: 13px;
  }
  .profile_section .nav-tabs {
    display: flex;
    flex-direction: row;
    border: none;
  }
  .notification {
    margin: 13px auto;
  }
  .staking_optoikn .fixTableHead th {
    white-space: nowrap;
  }
  header button.btn.connectBtn {
    width: fit-content;
    margin: 8px auto;
  }
  .fixTableHead .type p {
    padding: 4px 14px;
  }
  .fixTableHead .minimum button {
    white-space: nowrap;
  }
  .profile_section .nav-tabs li {
    display: flex;
    padding: 15px 16px;
  }
  .profile_section .nav-tabs li a {
    color: #90939a;
  }
  .first_content h1 {
    font-size: 38px;
  }
  .why-card .card-transparant {
    margin: 0;
    margin-bottom: 25px;
  }
  .section-2-s {
    padding: 0;
  }
  .experiance img {
    width: 90%;
    margin: 0 auto;
  }
  .abute {
    padding: 0;
  }
  .boiconi_min {
    width: 95%;
    margin: 58px auto;
  }
  .abute h4 {
    font-size: 30px;
  }
  .abute h5 {
    margin-bottom: 15px;
    font-size: 19px;
  }
  .abute p {
    font-size: 16px;
  }
  .why-chooks h3 {
    font-size: 35px;
  }
  .roadmap_img {
    width: 95%;
  }
  .tocken_details h2 {
    font-size: 25px;
  }
  .title_advantage p {
    font-size: 17px;
  }
  .section-8 {
    padding-top: 0;
  }
  .partercc {
    width: 95%;
  }
  .footer-widget.widget-quicklink {
    margin-left: 100px;
    margin-left: 0;
  }
  footer {
    padding-bottom: 0 !important;
  }
  .homepage-section-banner {
    height: auto;
  }
  .navbar-toggler-icon {
    color: #fff !important;
    font-size: 28px;
  }
  .fixed_header .navbar-toggler-icon {
    color: #000 !important;
    font-size: 28px;
  }
  .padingrespos {
    padding: 20px 0px;
  }
  .padingrespos {
    display: flex;
    justify-content: center;
  }
  .light .navbar-toggler-icon {
    color: #000 !important;
    font-size: 28px;
  }
  .card_light {
    background: #0d042c;
    margin: 2px;
    padding: 7px 5px;
    box-shadow: 0px 0px 0px #292929;
    border-radius: 0px;
  }
  .market_palace {
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 15px !important;
  }
  .title-table {
    flex-wrap: wrap;
  }
  .title-table .nav-tabs {
    margin: 17px 0px;
    margin-bottom: 10px;
  }
  .section-8 {
    margin-top: 40px;
  }
  .section_4 .card_ img {
    width: 50%;
  }
  .section_4 .button_section {
    padding: 25px 0;
    text-align: center;
  }
  .home_vedio {
    display: none;
  }
  .homepage-section-banner > .container {
    position: static;
  }
  .section-2-s {
    margin-top: 0;
  }
  .padingrespos {
    padding: 20px 0px;
  }
  .padingrespos {
    display: flex;
    justify-content: center;
    justify-content: center;
    flex-direction: column;
    align-content: baseline;
  }
  .light .navbar-toggler-icon {
    color: #000 !important;
    font-size: 28px;
  }
  .padingrespos a.connectBtn {
    margin: 13px auto !important;
    width: 40%;
  }
  .trade111 {
    margin: 0 auto;
    display: block;
    width: 100%;
    text-align: center;
  }
  .staking_price {
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
  }
  .staking_price h3 {
    font-size: 16px;
    text-align: center;
  }
  .staking_price p {
    font-size: 24px;
    color: #3b82f6;
    text-align: center;
  }
  .staking_title h1 {
    font-size: 31px;
    text-align: center;
  }
  .flex-allowww > div.clocc {
    margin-right: 20px;
    margin-left: 11px;
  }

  /* David */
  .dash_board_search_box {
    left: 32%;
  }

  .new_login_bb .pading_cardd {
    padding: 30px 50px !important;
  }

  .new_login_bb .card_logoki h1 {
    font-size: 35px;
  }
  .heading_card_new h1 {
    padding: 0px 10px;
    font-size: 24px;
  }

  .asidemeni {
    height: auto;
  }

  .new_login_bb .form_pading_s .form-group {
    flex-wrap: wrap;
  }

  .section_class_grs h4 {
    font-size: 20px;
  }
  .new_login_bb .register_login label {
    font-size: 20px;
  }
  .banner-text {
    font-size: 38px;
  }
  .banner-btn {
    padding: 15px 40px;
  }
  .y-taikonz-content h1 {
    font-size: 38px;
  }
  .currency-content h1 {
    font-size: 38px;
  }
  .currency-content p {
    font-size: 16px;
  }
  .investing-heading {
    font-size: 38px;
  }
  .crypto-platform-content h1 {
    font-size: 38px;
  }
  .crypto-platform-content p {
    font-size: 16px;
  }
  .currency-btn {
    margin-bottom: 25px;
  }
  .history-table-header.yield .table-cell {
    padding-left: 22px;
  }
  .history-table-header.fix .table-cell {
    padding-left: 20px;
  }
  .history-table-header.all .table-cell {
    padding-left: 20px;
  }
  .margin_summary {
    padding-top: 0px !important;
  }

  .Card_dashboard p {
    font-size: 26px;
  }
  .banner-text {
    font-size: 36px;
  }
  .banner-content {
    font-size: 15px;
  }
  .banner-btn {
    padding: 15px 40px;
  }

  .y-taikonz-content h1 {
    font-size: 36px;
  }

  .y-taikonz-content p {
    font-size: 15px;
  }
  .x-taikonz-content h3 {
    font-size: 25px;
  }
  .currency-content h1 {
    font-size: 36px;
  }
  .currency-content p {
    font-size: 15px;
  }
  .investing-heading {
    font-size: 36px;
  }
  .investing-text {
    font-size: 15px;
  }
  .x-investing-heading {
    font-size: 22px;
  }
  .crypto-platform-content h1 {
    font-size: 36px;
  }
  .crypto-platform-content p {
    font-size: 15px;
  }
}
@media (max-width: 768px) {
  .dash_board_search_box {
    left: 30%;
  }
}

@media (max-width: 700px) {
  .main-panel {
    padding-top: 74px;
  }
  .ky_c_contnet img {
    margin: 0 auto;
  }
  .ky_c_contnet p {
    text-align: center;
  }
  .summery {
    margin-top: 17px;
  }

  .erroe_launch {
    font-size: 13px;
  }
  .profile_section .heading_mwmu {
    width: 100%;
  }
  .notification_section #notification {
    position: static;
    width: 94%;
    margin: 0 auto;
    box-shadow: 0px 0px 6px #ccc;
  }
  .option_new_order {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 0 !important;
  }
  .option_new_order a {
    width: 48%;
    margin: 0 !important;
  }
  .coin_table_ta {
    margin-right: 25px;
  }
  .search_theme input {
    width: 215px;
  }
  .page_mobile_pading .content-wrapper {
    padding-top: 90px;
  }
  .market_price_secrtiom span {
    text-align: center;
  }
  .pading-zeero {
    padding: 0;
  }
  .p2ppost {
    margin-top: 10px;
  }
  .order_type {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .profile_section .nav-tabs li {
    display: flex;
    padding: 0;
  }
  .profile_section .nav-tabs li a {
    font-size: 14px;
  }
  .security__email {
    font-size: 13px;
  }
  .p2ppost {
    padding: 1px 10px;
  }
  .confirm_button_p2p {
    display: flex;
    justify-content: center;
  }
  .detaile_foem_kyc p {
    font-size: 14px;
  }
  .kyc_card {
    margin-top: 19px !important;
  }
  .dashboard-card {
    padding: 0px 12px;
  }
  .content-wrapper {
    padding: 3px 15px;
  }
  .login_body_ {
    overflow: visible;
    max-height: 100%;
    min-height: 100%;
  }
  .erroe_launch {
    text-align: center;
  }
  .tradepage-bg .container {
    max-width: 100%;
    padding: 0;
  }
  .logo_launchpad h1 {
    font-size: 23px;
  }
  .menu_profile button {
    max-width: 10px;
    min-width: 10px;
    padding: 0px 25px;
  }
  .title-table li a {
    font-weight: 500;
    font-size: 14px;
  }
  .card_profile {
    margin-bottom: 11px;
  }
  .clorerree {
    flex-wrap: wrap;
    justify-content: center;
  }
  .clorerree > div {
    flex-grow: 1;
  }
  .menu_profile #dropdownMenuButton img {
    width: 25px;
    height: 27px;
    border-radius: 50px;
    margin: 0 auto;
  }
  .market_palace {
    padding: 15px 5px !important;
    padding-bottom: 0 !important;
  }
  .otp_content_e input {
    margin: 0 7px !important;
  }
  .form_content p {
    font-size: 14px;
  }
  .form_content h1 {
    font-size: 30px;
  }
  .navbar-collapse {
    background: #280363;
  }
  .fixed_header .navbar-collapse {
    background: #fff;
  }
  .donwload-form.buttondf {
    width: 100%;
  }
  .six_secton_s {
    padding: 0 25px;
  }
  .coin-list__main {
    width: 100%;
  }
  .img_wwwwwww {
    margin: 20px auto !important;
  }
  .footer-widget.widget-quicklink {
    margin-left: 0 !important;
    padding-left: 0;
  }
  .six_secton_s h1 {
    font-size: 35px;
    text-align: center;
  }
  .six_secton_s p {
    font-size: 16px;
    text-align: center;
  }
  .step__heading.gradion_text {
    font-size: 36px;
  }
  .text_des {
    font-size: 16px;
  }
  .main_conten h1 {
    text-align: center;
  }
  .hero-intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .hero-intro p {
    text-align: center;
  }
  .staking_price {
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
  }
  #navbarSupportedContent a.mr-3 {
    margin: 0 !important;
    padding: 5px 0px !important;
    margin: 0 auto !important;
  }
  .flex-allowww {
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
  .flex-allowww > div.clocc {
    margin: 0;
  }
  .staking_optoikn ul {
    gap: 20px;
  }
  .yiled_tabs ul {
    gap: 0;
  }
  .balance_section {
    padding: 0;
  }
  .section-2-s {
    position: relative;
    z-index: 5;
  }
  .row_oiu_p h5,
  .row_oiu_p span {
    white-space: nowrap;
  }
  .my_fund_content .balance_section h3 {
    word-wrap: anywhere;
    word-wrap: break-word;
    word-break: break-all;
    font-size: 20px;
  }
  .my_fund_content .balance_section h3 span {
    white-space: nowrap;
    font-size: 14px;
  }
  .form_wiuthdrawl p {
    text-align: left;
  }
  .wallet_overview .table thead th {
    white-space: nowrap;
  }
  .profile_section {
    padding: 0;
  }
  .change_password_section {
    width: 100%;
  }
  .profile_section .cars_bg_ss .futer_section .flex_container span {
    word-wrap: break-word;
    word-break: break-all;
    font-size: 13px;
  }
  .sessions__content {
    font-weight: 500;
    color: #fff;
    font-size: 14px;
  }
  .sessions__section {
    width: 100%;
    overflow: hidden;
    overflow-x: hidden;
    overflow-x: scroll;
  }

  .padin_zero {
    padding: 0 !important;
  }
  .launch_card.new_table p {
    text-align: center;
  }
  .launcpad {
    flex-direction: column;
  }
  .launcpad p {
    text-align: center;
    margin-bottom: 10px;
  }
  .view_now .title_section p {
    text-align: center;
  }
  .login_content_section {
    padding: 10px 0px;
  }
  .stakingtext_new {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 0px;
  }
  .fixTableHead .duration {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    justify-content: center;
    padding: 8px 0px;
  }
  .fixTableHead .aPY {
    display: block;
    margin: 0 auto;
  }
  #stacknow .fixTableHead .price h1 {
    text-align: center;
    justify-content: center;
    margin-bottom: 13px;
  }
  .wallet_overview .table td {
    padding: 11px 24px;
    white-space: nowrap;
  }
  .form_wiuthdrawl input {
    width: 86%;
  }
  .form_wiuthdrawl p {
    text-align: center;
  }
  .width_100 input {
    width: 100%;
  }
  .prime_deposit img {
    margin: 0 auto;
  }
  .bg_card_section .table thead th {
    white-space: nowrap;
  }
  .flex_inpur {
    flex-wrap: wrap;
    justify-content: center;
  }
  .otp_content_e .form-group {
    /* width: 25%; */
    display: flex;
  }
  .register_login .btn-primary {
    width: 80%;
  }
  .login_content_section h1 {
    font-size: 42px;
    text-align: center;
  }
  .login_content_section .btn-primary {
    width: 90%;
    justify-content: center;
    align-items: center;
  }

  .login_content_section p {
    font-size: 17px;
    text-align: center;
  }
  .section_4 .card_ .content {
    padding: 33px 15px;
  }
  .section_4 .card_ .content h2 {
    text-align: center;
  }
  .home_vedio {
    display: none;
  }
  .homepage-section-banner > .container {
    position: static;
  }
  .section-2-s {
    margin-top: 0;
  }
  .table_ .table td,
  .table_ .table th {
    border-bottom: 1px solid #0000;
    border-top: 0;
    white-space: nowrap;
    padding: 10px 14px;
    font-size: 14px;
  }

  .first_content {
    padding: 0;
  }
  .market_price_secrtiom > div,
  .trade_header_11 > div {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    flex-direction: revert;
    justify-content: center;
  }
  .market_price_secrtiom h5 {
    margin-left: 13px;
  }
  .first_content h1 {
    font-size: 38px;
  }
  .first_content .social {
    justify-content: center;
  }
  .why-chooks {
    margin-top: 72px;
  }
  .why-chooks h3 {
    font-size: 30px;
  }
  .why-card .card-transparant {
    margin: 0px 0px;
    margin-bottom: 20px;
  }
  .airdrop_btn {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }
  .experiance img {
    width: 90%;
  }
  .boiconi_min {
    width: 95%;
  }
  .abute {
    padding: 0;
  }
  .abute h4 {
    text-align: center;
    font-size: 30px;
    margin-bottom: 25px;
  }
  .abute h5 {
    margin-bottom: 15px;
    font-size: 17px;
    text-align: center;
  }
  .abute p {
    font-size: 16px;
    text-align: center;
  }
  .roadmap_img {
    width: 100%;
  }
  .section {
    padding: 40px 0px;
    padding: 0;
  }
  .section-7 {
    padding: 0 !important;
  }
  .section-8 {
    padding: 0 !important;
  }
  .partercc {
    width: 100%;
  }
  footer {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  footer .logo {
    width: 69px;
  }
  .rn-footer-one .footer-left .rn-footer-describe {
    padding: 0;
    text-align: center;
    margin-bottom: 20px;
  }
  .footer-widget.widget-quicklink {
    margin-left: 100px;
    margin-left: 6px;
  }
  .footer-widget .widget-title {
    text-align: left;
  }
  .footer-list-one .single-list {
    text-align: center;
  }
  .partercc {
    width: 100%;
  }
  .homepage-section-banner {
    height: auto;
  }
  .navbar-toggler-icon {
    color: #fff !important;
    font-size: 28px;
  }
  .fixed_header .navbar-toggler-icon {
    color: #000 !important;
    font-size: 28px;
  }
  .padingrespos {
    padding: 20px 0px;
  }
  .padingrespos {
    display: flex;
    justify-content: center;
    justify-content: center;
    flex-direction: column;
    align-content: baseline;
  }
  .light .navbar-toggler-icon {
    color: #fff !important;
    font-size: 28px;
  }
  .light .fixed_header .navbar-toggler-icon {
    color: #000 !important;
    font-size: 28px;
  }
  .padingrespos a.connectBtn {
    margin: 13px auto !important;
    width: 40%;
  }
  .trade111 {
    margin: 0 auto;
    display: block;
    width: 100%;
    text-align: center;
  }
  .homepage-section-banner {
    margin-top: 0;
  }
  .staking_price {
    flex-wrap: wrap;
    justify-content: center;
  }
  .staking_price h3 {
    text-align: center;
    font-size: 13px;
  }
  .staking_price p {
    font-size: 21px;
  }
  .staking_price > div {
    padding-bottom: 5px;
  }
  .padinf_ {
    padding: 0 !important;
  }
  .fixTableHead .type p {
    padding: 4px 13px;
  }
  .staking_optoikn ul li a.page-link {
    padding: 5px 10px;
  }
  .staking_title h1 {
    font-size: 16px !important;
    text-align: center;
  }
  .staking_optoikn .fixTableHead th {
    white-space: nowrap;
  }
  .fixTableHead th,
  .fixTableHead td {
    white-space: nowrap;
  }
  .heading_card_new::before {
    top: 50%;
    left: 5px;
    transform: translate(-50%, -50%);
  }

  /* David*/

  .new_login_bb .card_logoki h1 {
    font-size: 28px;
  }

  .new_login_bb .pading_cardd {
    padding: 20px 60px !important;
  }

  /* .heading_card_new::before {
    top: 30px;
  } */
  .card_launchpad {
    padding: 40px;
  }
  .nav-tabs {
    gap: 10px;
  }
  .coin_launch img {
    width: 50px;
  }
  .coin_launch h5 span {
    font-size: 18px;
  }
  .coin_launch h5 {
    font-size: 22px;
  }

  .Srake_status {
    font-size: 20px;
  }

  .tabs_inside .nav li {
    width: 40% !important;
  }

  .tabs_inside .nav {
    display: flex;
    flex-direction: row;
  }

  .asidemeni {
    height: auto;
  }

  .tfa_box h3 {
    font-size: 30px;
  }

  .tfa_box p {
    font-size: 14px;
  }

  .new_login_bb .form_pading_s .form-group {
    flex-wrap: wrap;
  }

  /*  */

  .margin_summary {
    padding-top: 0px !important;
  }

  .Card_dashboard p {
    font-size: 26px;
  }
  .banner-text {
    font-size: 36px;
  }
  .banner-content {
    font-size: 15px;
  }
  .banner-btn {
    padding: 15px 40px;
  }

  .y-taikonz-content h1 {
    font-size: 36px;
  }

  .y-taikonz-content p {
    font-size: 15px;
  }
  .x-taikonz-content h3 {
    font-size: 25px;
  }
  .currency-content h1 {
    font-size: 36px;
  }
  .currency-content p {
    font-size: 15px;
  }
  .investing-heading {
    font-size: 36px;
  }
  .investing-text {
    font-size: 15px;
  }
  .x-investing-heading {
    font-size: 22px;
  }
  .crypto-platform-content h1 {
    font-size: 36px;
  }
  .crypto-platform-content p {
    font-size: 15px;
  }
}
@media (max-width: 600px) {
  .dash_board_search_box {
    left: 20%;
  }
  .timer h6 {
    text-wrap: wrap;
  }
  .history-table-header .table-cell {
    padding-left: 38px;
  }
  .stake-cell .minimum {
    display: flex;
    flex-direction: column;
  }
  .history-table-header.yield .table-cell {
    padding-left: 22px;
  }
  .history-table-header.fix .table-cell {
    padding-left: 20px;
  }
  .history-table-header.all .table-cell {
    padding-left: 25px;
  }
}
@media (max-width: 500px) {
  .align-buttons .aling_caseds {
    display: flex;
    gap: 0px;
    align-items: center;
    flex-direction: column;
  }
  .progredsss_launch {
    flex-direction: column;
    gap: 10px;
  }
  .price_pair {
    height: 70px;
  }
  .contant_details h3 {
    font-size: 27px;
  }
  .contant_details h3 {
    font-size: 27px;

    margin-bottom: 12px;
  }
  .mgs_content .card_bg h4 {
    font-size: 23px;
  }
  .teble_primary_color {
    width: 100%;
  }
  .contecnt_main h2 {
    color: #cecece;
  }
  .pro-sidebar {
    position: absolute !important;
    box-shadow: 0px 0px 8px #323232;
  }
  .pro-sidebar.collapsed {
    width: 0 !important;
    min-width: 0 !important;
  }
  .diveee {
    margin: 10px 0px;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  .slick-prev,
  .slick-next {
    display: none !important;
  }
  .site-info__row {
    flex-direction: column !important;
  }
  .site-footer .site-info__row .widget:last-child {
    margin: 15px auto;
  }
  .site-footer .site-info__copyright {
    margin: 0;
  }
  .step__item::after {
    display: none;
  }

  .home main {
    overflow: hidden;
  }

  /* David */
  .dash_board_search_box {
    left: -5%;
    padding: 10px 10px;
  }
  .bootsrab_tabs .nav li a {
    padding: 6px 8px;
    margin-right: 1px;
  }

  .new_login_bb .pading_cardd {
    padding: 20px 30px !important;
  }

  .new_login_bb .card_logoki h1 {
    font-size: 25px;
  }

  .heading_card_new {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .heading_card_new::before {
    display: none;
  }

  .heading_card_new h1 {
    margin-bottom: 10px;
    font-size: 22px;
  }

  .tag_staking_yiedl.nav li {
    min-width: 150px;
  }

  .nav-tabs {
    gap: 10px;
  }

  .asidemeni {
    padding: 50px;
  }

  .tfa_box h3 {
    font-size: 27px;
  }
  .groow-1_widut {
    width: 100%;
  }

  .new_login_bb .form_pading_s .form-group {
    flex-wrap: wrap;
  }

  .Card_dashboard p {
    font-size: 22px;
  }

  .banner-text {
    font-size: 30px;
  }
  .banner-content {
    font-size: 14px;
  }
  .y-taikonz-content h1 {
    font-size: 30px;
  }

  .y-taikonz-content p {
    font-size: 14px;
  }
  .x-taikonz-content h3 {
    font-size: 24px;
  }
  .currency-content h1 {
    font-size: 30px;
  }
  .currency-content p {
    font-size: 14px;
  }
  .y-bitcoin-content {
    font-size: 20px;
  }
  .btc {
    font-size: 15px;
  }

  .investing-heading {
    font-size: 30px;
  }
  .investing-text {
    font-size: 13px;
  }

  .x-investing-heading {
    font-size: 22px;
  }
  .crypto-platform-content h1 {
    font-size: 30px;
  }

  .crypto-platform-content p {
    font-size: 14px;
  }

  .banner-btn {
    padding: 10px 20px;
  }
  .copyright {
    font-size: 16px;
  }
  .page {
    font-size: 18.315px;
  }
  .footer ul li {
    font-size: 14px;
  }
  .new_login_bb .register_login label {
    font-size: 16px;
  }
  .card_logoki.pading_cardd.post_card {
    padding: 10px 20px !important;
  }
  .launch_status {
    margin-right: -15px;
  }
  .tabs_collection span {
    padding: 6px 10px;
  }
  .currency-btn {
    margin-bottom: 25px;
  }
  .timer h6 {
    font-size: 14px;
    text-wrap: wrap;
  }
  .timer h6 span {
    font-size: 18px;
  }
  .class_pair_table .dropdown-menu {
    min-width: max-content;
  }
  /*  */

  .chat-profile {
    margin: 0 20px;
  }
  .payment-cancel-confirm {
    gap: 10px;
  }
  .btn.btn-primary.cancel-payment {
    padding: 12px !important;
    margin-top: 0px !important;
  }
  .class_pair_table .dropdown-menu {
    margin-top: 20px;
    min-width: 300px;
  }
  .chat_contianer .chat_footer input {
    width: 100%;
  }
  .chat_contianer .chat_footer {
    flex-direction: column;
  }
  .chat_contianer .chat_footer button {
    width: 100%;
  }
  .price_section .dropdown:hover .dropdown-menu {
    max-width: 300px;
  }
}
@media (max-width: 400px) {
  .slick-prev,
  .slick-next {
    display: none !important;
  }
  .diveee {
    margin: 10px 0px;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  .page-item.disabled .page-link {
    padding: 8px 6px !important;
  }
  .balance_section {
    padding: 0;
  }
  .page-item.active .page-link {
    padding: 8px 6px !important;
  }
  .my_fund_content .bg_card_section .balanc_avail {
    flex-wrap: wrap;
  }
  .page-link {
    padding: 8px 6px !important;
  }
  .pagination {
    justify-content: center;
  }
  .row_oiu_p h5,
  .row_oiu_p span {
    white-space: nowrap;
  }
  .my_fund_content .balance_section h3 {
    word-wrap: anywhere;
    word-wrap: break-word;
    word-break: break-all;
    font-size: 20px;
  }
  .my_fund_content .balance_section h3 span {
    white-space: nowrap;
    font-size: 14px;
  }
  .buy_sell_s {
    justify-content: center;
    margin-bottom: 12px;
  }
  .input_section .input_select_s > input {
    width: 50%;
  }
  .change_password_section {
    width: 100%;
  }
  .form_wiuthdrawl {
    padding: 0px 10px;
  }
  .profile_section {
    padding: 0;
  }
  .profile_section .cars_bg_ss .futer_section .flex_container span {
    word-wrap: break-word;
    word-break: break-all;
    font-size: 13px;
  }
  .sessions__section {
    width: 100%;
    overflow: hidden;
    overflow-x: hidden;
    overflow-x: scroll;
  }
  .sessions__content {
    font-weight: 500;
    color: #fff;
    font-size: 14px;
  }
  .wallet_overview .table thead th {
    white-space: nowrap;
  }

  /* David */
  .chat-profile {
    margin: 0 20px;
  }
  .payment-cancel-confirm {
    gap: 10px;
  }
  .btn.btn-primary.cancel-payment {
    padding: 12px !important;
    margin-top: 0px !important;
  }
  .class_pair_table .dropdown-menu {
    margin-top: 20px;
    min-width: 300px;
  }
  .chat_contianer .chat_footer input {
    width: 100%;
  }
  .chat_contianer .chat_footer {
    flex-direction: column;
  }
  .chat_contianer .chat_footer button {
    width: 100%;
  }
  .price_section .dropdown:hover .dropdown-menu {
    max-width: 300px;
  }
  .input_section_kyc.user_img {
    width: 190px !important;
  }
  .mobile_taikonz_logo {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  .card_logoki.mobileapp {
    margin-top: 10% !important;
  }
  .coin_launch h5 span {
    font-size: 18px;
  }
  .coin_launch h5 {
    font-size: 20px;
  }

  .new_login_bb .pading_cardd {
    padding: 22px 23px !important;
  }

  .new_login_bb .card_logoki h1 {
    font-size: 24px;
  }

  .heading_card_new h1 {
    font-size: 18px;
  }

  .bootsrab_tabs .nav li a {
    padding: 6px 8px;
    margin-right: 1px;
  }

  .heading_card_new {
    padding: 25px 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    text-align: center;
  }

  .heading_card_new::before {
    display: none;
  }

  .flex_content_stake span {
    font-size: 20px;
  }

  .colo_bold small {
    font-size: 23px;
  }

  .nav-tabs {
    gap: 10px;
  }

  .select_duration ul li a {
    padding: 5px 12px;
    font-size: 14px;
  }

  .select_duration p {
    font-size: 14px;
  }

  .tag_staking_yiedl.nav li {
    min-width: 95px;
  }

  .marhing_pading {
    padding: 24px 15px !important;
  }

  .button_accorndi,
  .button_accorndi:hover {
    font-size: 16px !important;
  }

  .menu_items_fex .navlink_new {
    font-size: 16px;
  }

  .profile_content_image .profile_content h3 {
    font-size: 14px;
  }

  .Card_dashboard p span {
    font-size: 14px;
  }

  .Card_dashboard p {
    font-size: 18px;
  }

  .tfa_box h3 {
    font-size: 25px;
  }

  .tfa_box p {
    font-size: 12px;
  }

  .preview span {
    font-size: 12px !important;
  }

  .preview {
    font-size: 12px;
  }

  .new_login_bb .form_pading_s .form-group {
    flex-wrap: wrap;
  }

  .section_class_grs h4 {
    font-size: 16px;
  }

  .crypto-platform-content h1 {
    font-size: 30px;
  }

  .page {
    font-size: 16.315px;
  }

  .footer ul li {
    font-size: 14px;
  }

  .copyright {
    font-size: 15px;
  }

  .crypto-platform-content p {
    font-size: 14px;
  }

  .banner-btn {
    padding: 10px;
  }

  .x-investing-content {
    font-size: 14px;
  }

  .x-investing-heading {
    font-size: 22px;
  }

  .investing-heading {
    font-size: 30px;
  }

  .investing-text {
    font-size: 14px;
  }

  .currency-content h1 {
    font-size: 30px;
  }

  .currency-content p {
    font-size: 14px;
  }

  .x-taikonz-content h3 {
    font-size: 18px;
  }

  .y-taikonz-content h1 {
    font-size: 30px;
  }

  .y-taikonz-content p {
    font-size: 14px;
  }

  .banner-text {
    font-size: 30px;
  }
  .banner-content {
    font-size: 14px;
  }

  .dex_default img {
    width: 45px;
    height: 45px;
  }
  .y-bitcoin-content {
    font-size: 18px;
  }
  .btc {
    font-size: 14px;
  }
  button.back_butn {
    font-size: 14px !important;
  }

  .launch_status {
    padding: 5px 8px;
    margin-right: -10px;
  }

  .coin_launch h5 span {
    font-size: 16px;
  }
  .coin_launch h5 {
    font-size: 18px;
  }
  .ui.dropdown > .text {
    margin-top: -6px;
  }
  .new_login_bb .form_pading_s {
    padding: 20px 8px !important;
  }
  .form-control {
    font-size: 12px;
  }
  .card_logoki.pading_cardd.post_card {
    padding: 22px 11px !important;
  }
  .tabs_collection span {
    padding: 2px 7px;
  }
  .new_login_bb .form_pading_s.box {
    padding: 8px 8px !important;
  }
}
@media (max-width: 360px) {
  .cancel-payment-butns {
    padding: 24px 9px !important;
  }
  .chat-profile {
    margin: 0 15px;
  }
}

@media (max-width: 300px) {
  .card_launchpad {
    padding: 20px;
  }
  .new_login_bb .pading_cardd {
    padding: 15px 6px !important;
  }
  .progredsss_launch .date_section {
    flex-direction: column;
  }
  .bold_fonr {
    font-size: 14px;
  }
  .tabs_collection span {
    padding: 2px 6px;
  }
  .new_login_bb .form_pading_s {
    padding: 6px 8px !important;
  }
  .asidemeni {
    padding: 20px;
  }
  .payment_form {
    flex-direction: column;
  }
  .dash_board_search_box {
    left: 0%;
  }
  .cancel-payment-butns {
    padding: 24px 9px !important;
  }
}
