@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  font-family: "Roboto", sans-serif !important;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: "Roboto", sans-serif !important;
}
button,
input,
optgroup,
select,
textarea {
  font-family: "Roboto", sans-serif !important;
}

.qr_img {
  border-radius: 20px;
}

.custom-appbar {
  background-color: #131313 !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.17);
  padding: 0 25px;
}
.logo_new {
  width: 160px;
  cursor: pointer;
  padding: 20px;
}
.new_login_bb {
  background: #131313 !important;
  background-image: none !important;

  min-height: 100vh !important;
}
.new_login_bb .card_logoki {
  border-radius: 20px;
  background: #181818;
  border: 1px solid #252525;
}
.new_login_bb .pading_cardd {
  padding: 50px 50px !important;
}
.new_login_bb .card_logoki h1 {
  text-align: left;
  color: #b3b3b3;
  font-size: 34px;
  font-weight: 400;
  margin-bottom: 35px;
  text-transform: capitalize;
}
.text_new_cls {
  text-align: left;
  color: #b3b3b3;
  font-size: 34px;
  font-weight: 400;
  text-transform: capitalize;
}
.new_login_bb .form_pading_s {
  padding: 24px 25px;
  background: #202020;
  border-radius: 20px;
}
.new_login_bb .register_login label {
  font-size: 14px;
  color: #b3b3b3;
  margin-bottom: 10px;
  font-weight: 400;
}
.onlywhitee.new_login_bb .register_login .form-control {
  background: #2c2c2c;
  color: #fff;
  border: none;
  border-radius: 10px;
}
.onlywhitee.new_login_bb .register_login .form-textarea {
  background: #2c2c2c;
  color: #fff;
  border: none;
  padding: 1rem;
  width: 100% !important;
  border-radius: 10px;
}

.new_login_bb .form-group .text-danger {
  font-size: 11px;
  position: absolute;
  padding: 0;
  bottom: -18px;
}
.new_login_bb .form-group {
  position: relative;
}
.new_login_bb .form_pading_s .form-group {
  min-height: auto;
  margin-bottom: 15px;
}
.agrreement p {
  text-align: left !important;
  color: #7a7a7a !important;
}
.agrreement a {
  color: #9685ff;
}
.agrreement {
  margin-top: 30px;
}
.new_login_bb .register_login .btn-primary {
  padding: 10px 10px;
  display: block;
  background: #4f41a4 !important;
  margin-top: 36px;
}

button.back_butn {
  background: transparent !important;
  color: #7a7a7a !important;
  font-weight: 500 !important;
  padding: 16px 0px !important;
  font-size: 18px !important;
}
p.form_lable_botton {
  font-size: 14px !important;
  text-align: left !important;
  color: #b3b3b3 !important;
  margin-bottom: 19px !important;
}
.form_pading_s p.form_lable_botton {
  font-size: 14px;
  text-align: left;
  color: #b3b3b3;
  margin-bottom: 19px;
}
.new_login_bb .postion_reletitt .input-group-addon {
  color: #7b7b7b;
}
.agrreement.check {
  margin-top: 40px;
}
.agrreement.check p {
  color: #000;
  text-align: center;
  padding: 4px 0px;
}
.agrreement.check i {
  font-size: 19px;
}
.agrreement.check p.check {
  color: #2aa50b !important;
}
.Proprer_center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.form_pading_s .ui.fluid.dropdown.text_memu {
  background: #2c2c2c !important;
  color: #fff !important;
  border: none !important;
  height: 44px !important;
  border-radius: 10px !important;
  padding-top: 15px !important;
}
.form_pading_s .ui.fluid.dropdown.text_memu.swapmenui {
  background: #2c2c2c !important;
  color: #fff !important;
  border: none !important;
  height: 53px !important;
  border-radius: 10px !important;
  padding-top: 21px !important;
}
.new_login_bb .pading_cardd.swap {
  padding: 0px 60px;
}

.form_pading_s .ui.selection.dropdown .menu > .item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.form_pading_s .ui.selection.dropdown .menu > .item span {
  width: fit-content;
  display: block;
  text-align: center;
  color: #000;
  font-size: 13px;
  padding-bottom: 0;
}
.form_pading_s .ui.selection.visible.dropdown > .text:not(.default) {
  font-weight: 400;
  color: #fff !important;
}
p.right-margjs {
  margin-bottom: 0px !important;
  margin-top: 40px !important;
}
.cancel_butn {
  display: block;
  width: 100%;
  text-align: center;
  color: #9685ff;
  font-size: 16px;
}
.input_flex_propery {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.text_inner_card {
  font-weight: 500;
  color: #b3b3b3;
  font-size: 23px;
}
.a.accepte {
  display: block;
  background: #2c2c2c;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.a.accepte:active {
  display: block;
  background: #4f41a4;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
a.accepte:focus {
  display: block;
  background: #4f41a4;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
a.accepte:focus {
  display: block;
  background: #4f41a4;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.a.accepte .grow_1 p {
  text-align: left;
  color: #b3b3b3;
  font-size: 17px;
  padding: 0;
  margin-bottom: 5px;
}
.a.accepte .grow_1 span {
  color: #7a7a7a;
  padding: 0;
}
.a.accepte i {
  font-size: 30px;
  color: #b3b3b3;
}
.image-marker li {
  position: relative;
  padding-left: 20px;
}
.image-marker li::before {
  position: absolute;
  left: 0;
  content: url("../src/img/New_images/Star.png");
  display: inline-block;
  background: transparent;
  width: 20px;
  height: 20px;
}
.hr_line {
  background: #252525;
  height: 1px;
  margin: 50px 0px;
}
button.primary_header_button,
button.primary_header_button:hover {
  background: #4f41a4;
  text-transform: capitalize;
  padding: 8px 25px;
  font-size: 14px;
  border-radius: 12px;
  margin-right: 10px;
}
button.secondary_header_button {
  border: 2px solid #4f41a4;
  color: #8d81d4;
  text-transform: capitalize;
  font-size: 14px;
  padding: 7px 27px;
  border-radius: 12px;
}
button.link {
  font-weight: 500;
  text-transform: capitalize;
  font-size: 14px;
  color: #b3b3b3;
}
button.link.active {
  color: #9685ff;
}
.heading_card_new {
  padding: 25px 20px;
  background: #202020;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-left: 40px;
}
.heading_card_new h1 {
  margin-bottom: 0;
  margin-bottom: 0;
  color: #b3b3b3;
  font-weight: 400;
}
.heading_card_new::before {
  content: "";
  height: 60px;
  width: 10px;
  background: #4f41a4;
  position: absolute;
  left: 0;
  border-radius: 0px 10px 10px 0px;
  top: 50%;
  left: 6px;
  transform: translate(-50%, -50%);
}

button.primary_butn {
  background: #4f41a4 !important;
  text-transform: capitalize !important;
  padding: 14px 25px !important;
  font-size: 17px !important;
  color: #fff !important;
  font-weight: 400 !important;
  border-radius: 10px !important;
}

.bootsrab_tabs .nav li {
  margin: 0;
  display: flex;
  align-items: center;
}
.bootsrab_tabs .nav li a {
  display: block;
  color: #9685ff;
  padding: 10px 33px;
  border: 1px solid #9685ff;
  border-radius: 10px;
  margin-right: 20px;
}
.bootsrab_tabs .nav li a.active {
  color: #fff;
  background: #4f41a4;
  border: 1px solid #4f41a4;
}
.search_bar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #181818;
  padding: 10px 20px;
  border-radius: 10px;
  margin: 20px 0px;
}
.search_bar i {
  color: #7a7a7a;
  font-size: 20px;
}
.search_bar input {
  background: transparent;
  color: #7a7a7a;
  padding: 4px 10px;
  font-size: 17px;
  flex-grow: 1;
}
.card_launchpad {
  background: #252525;
  padding: 20px;
  border-radius: 20px;
  cursor: pointer;
}
.css-1dyfeew-MuiGrid-root > .MuiGrid-item {
  padding-left: 25px !important;
}
.css-1n5bjej-MuiGrid-root > .MuiGrid-item {
  padding-left: 0px !important;
}
.header_launc {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.coin_launch {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 13px;
}
.coin_launch img {
  width: 40px;
}
.coin_launch h5 {
  margin: 0;
  color: #b3b3b3;
  font-size: 18px;
  font-weight: 400;
}
.coin_launch h5 span {
  font-size: 14px;
  color: #7a7a7a;
  margin-left: 5px;
}
.launch_status {
  background: #12281c;
  padding: 6px 20px;
  border-radius: 20px;
}
.launch_status span {
  color: #21a75f;
}
.data_launc {
  padding: 16px 0px;
}
.data_launc p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #7a7a7a;
  font-size: 16px;
  font-weight: 400;
}
.data_launc span {
  color: #b3b3b3;
  font-weight: 500;
}
.card_launchpad hr {
  background: #7a7a7a;
  margin-top: 20px;
  margin-bottom: 10px;
}
.bootsrab_tabs {
  padding: 20px 0px;
}
.Launch_pad_steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Launch_pad_steps span {
  font-size: 20px;
  color: #9685ff;
  font-weight: 300;
}
.class-padding {
  padding: 0 80px;
}
.new_login_bb .register_login .btn-primary {
  padding: 12px 10px;
  display: block;
  margin-top: 36px;
}
.new_login_bb .register_login .btn-second {
  padding: 10px 10px;
  display: block;
  background: #4f41a4 !important;
  margin-top: 36px;
  border: 1px solid #4f41a4;
  background: transparent !important;
  color: #4f41a4;
}
.button_launch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
}
.button_checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
}
.button_launch button {
  margin: 0 !important;
}
.text_area_input {
  width: 100%;
  padding: 20px;
  /* background: #fff; */
  border-radius: 10px;
}
.checkbox_lable {
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px !important;
}
.checkbox_lable label {
  margin: 0 !important;
  color: #999 !important;
  font-size: 16px !important;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
.preview {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  color: #7a7a7a !important;
  font-size: 15px;
  padding: 0 !important;
}
.preview span {
  width: -moz-fit-content !important;
  width: fit-content !important;
  padding: 0 !important;
  font-size: 17px !important;
  color: #b3b3b3 !important;
}
.w-fit {
  width: auto !important;
}
.pading_bott_mae {
  padding-bottom: 15px;
  margin-bottom: 10px;
  border-bottom: 1px solid #cccc;
}
.asidemeni {
  height: 100vh;
  background: #181818;
  padding: 20px;
  margin: 40px 0px;
  border-radius: 20px;
  position: sticky;
  top: 20px;
}
.dropdown_butn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 19px;
}
.button_accorndi,
.button_accorndi:hover {
  color: #b3b3b3 !important;
  font-size: 19px !important;
  font-weight: 400 !important;
}
.navlink_new.active,
.navlink_new:hover {
  padding: 14px 12px;
  background: #1c1a26;
  font-size: 17px;
  font-weight: 300;
  color: #9685ff;
  margin-bottom: 10px;
  border-radius: 10px;
}
.navlink_new {
  padding: 14px 20px;
  background: transparent;
  font-size: 17px;
  font-weight: 300;
  color: #b3b3b3;
  margin-bottom: 10px;
}
.bg_tras {
  background: transparent !important;
}
.menu_items_fex {
  display: flex;
  color: #b3b3b3;
  font-size: 19px;
  font-weight: 400;
}
.menu_items_fex .navlink_new {
  color: #b3b3b3;
  font-size: 19px;
  font-weight: 400;
  display: flex;
  gap: 10px;
  padding-left: 15px;
  width: 100%;
}
.profile_content_image {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px dashed #5d5d5d;
  margin-bottom: 20px;
}
.profile_content_image .profile_content {
  flex-grow: 1;
  padding-left: 10px;
}
.profile_content_image .profile_content h3 {
  color: #b3b3b3;
  font-size: 20px;
  margin-bottom: 5px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profile_content_image .profile_content p {
  margin-bottom: 5px;
  color: #8d8d8d;
  font-size: 14px;
  font-weight: 500;
}
.profile_content_image img {
  height: 70px;
  width: 70px;
  border-radius: 50%;
}
.profile_content_image h3 span.verifyed {
  font-size: 16px;
  font-weight: 300;
  color: #21a75f;
}
.tabs_inside .nav {
  width: 100%;
  border-bottom: 2px solid #252525;
}
.tabs_inside .nav li {
  margin: 0;
  flex-grow: 1;
  width: 50%;
}
.tabs_inside .nav li a {
  display: block;
  text-align: center;
  padding: 13px 0px;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: -2px;
  color: #b3b3b3;
}

.tabs_inside .nav li a.active {
  border-bottom: 2px solid #4f41a4;
  color: #9685ff;
}
.tfa_box h3 {
  font-size: 34px;
  color: #b3b3b3;
  font-weight: 400;
  margin-bottom: 10px;
}
.tfa_box h3 span.alret_tfa {
  font-size: 24px;
  color: #e84747;
}
.tfa_box p {
  font-size: 14px;
  font-weight: 300;
  color: #b3b3b3;
}
.font_inner {
  font-size: 27px !important;
  font-weight: 300;
  margin: 24px 0px !important;
}
.menu_items_fex .navlink_new.active {
  color: #9685ff;
}
.gap_button {
  display: flex;
  gap: 30px;
}
.gap_button .form_pading_s span {
  text-align: left;
  padding-bottom: 10px;
}
.gap_button .form_pading_s h6 {
  font-size: 34px;
  font-weight: 500;
  color: #9685ff;
}
.gap_button .groow-1 {
  flex-grow: 1;
}
.text-color-pur {
  color: #9685ff !important;
}

.form_pading_s.box {
  background: linear-gradient(93deg, #9685ff 3.79%, #683018 104.96%),
    linear-gradient(180deg, #d9d9d9 0%, rgba(217, 217, 217, 0) 100%);
  padding: 0 !important;
  border-radius: 11px;
  padding: 4px 4px !important;
}
/* #box::before {
  position: absolute;
  background-image: url("../src/img/New_images/bg_blur.png");
  content: "";
  width: 100%;
  height: 200px;
  background-size: contain;
  bottom: -13px;
} */

.form_pading_s.box::before {
  position: absolute;
  background-image: url("./img/New_images/ref_bg.png");
  background-repeat: no-repeat;
  content: "";
  height: 229px;
  background-size: contain;
  width: 25%;
  z-index: 1;
  /* bottom: 256px; */
}
.light .onlywhitee.new_login_bb .register_login .form-control-ref {
  color: #252525 !important;
}

.form_pading_s.box .form-group {
  background: #202020;
  border-radius: 8px;
  margin: 0;
  padding: 19px 10px !important;
}
.custom-table {
  width: 100%;
  margin: 20px 0;
  overflow: hidden;
  overflow-x: auto;
}

.table-row {
  display: flex;
  border: 0px solid #ddd;
  margin: 5px 0px;
}
.table-cell_no {
  flex: 1 1;
  padding: 10px;
  border: 0px solid #ddd;
  color: #7a7a7a;
  vertical-align: middle;
  align-items: center;
  display: flex;
  justify-content: center;
}
.table-cell {
  flex: 1 1;
  padding: 10px;
  border: 0px solid #ddd;
  color: #7a7a7a;
  vertical-align: middle;
  align-items: center;
  display: flex;
  justify-content: center;
}

.table-row .header {
  font-weight: 500;
  background-color: transparent;
}
.border_table_row {
  background: #181818;
  border: 1px solid #252525;
  border-radius: 21px;
  padding: 18px 20px;
  margin-bottom: 15px;
}

.content_section h3 {
  color: #b3b3b3;
  font-weight: 400;
  margin-bottom: 5px;
}
.content_section p {
  color: #7a7a7a;
}
.data_inner {
  color: #b3b3b3;
  font-weight: 300;
  font-size: 17px;
}

.payment_form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 24px;
  border-bottom: 2px solid #252525;
  margin-bottom: 30px;
}
.payment_form button {
  background: transparent !important;
  color: #9685ff !important;
  font-weight: 400 !important;
  color: #7a7a7a !important;

  font-size: 17px !important;
}
.payment_form button.primary_one {
  color: #9685ff !important;
}
.marhing_pading {
  padding: 24px 24px !important;
}
.account_setting {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.groow-1_widut {
  width: 47%;
}
.padding_botn {
  font-size: 25px;
  margin-bottom: 0px !important;
}
.font_color {
  color: #b3b3b3;
  font-size: 34px;
  font-weight: 400;
}
.heights {
  height: 100px;
}
.history .tabs_inside .nav li {
  margin: 0;
  flex-grow: 1;
  width: 16%;
}
.stake_button h4 {
  color: #7a7a7a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  padding: 10px 0px;
  font-size: 14px;
}
.stake_button h4 a {
  display: block;
  color: #9685ff;
  /* padding: 10px 33px; */
  /* border: 1px solid #9685ff; */
  border-radius: 10px;
  /* margin-right: 20px; */
}
.colo_bold {
  font-size: 25px;
  font-weight: 500;
  color: #9685ff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.select_duration {
  color: #7a7a7a;
  margin-bottom: 0;
  font-size: 18px;
}
.select_duration p {
  color: #7a7a7a;
  margin-bottom: 17px;
  font-size: 17px;
}
.select_duration ul {
  margin: 0;
  display: flex;
  gap: 8px;
}
.select_duration ul li {
  margin: 0;
  align-items: center;
  display: flex;
}

.stake_button h4 span {
  color: #7a7a7a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  padding: 10px 0px;
  font-size: 14px;
}
.select_duration ul li a {
  padding: 4px 8px;
  border: 1px solid #333333;
  border-radius: 30px;
  color: #7a7a7a;
  font-size: 16px;
}
.select_duration ul li a.active {
  padding: 4px 8px;
  border: 1px solid #fff;
  border-radius: 30px;
  color: #fff;
}
.Srake_status {
  font-size: 14px;
  text-transform: capitalize;
  color: #7a7a7a;
}
.flex_content_stake {
  justify-content: flex-start;
  gap: 10px;
}
.flex_content_stake span {
  font-size: 16px;
}
.colo_bold small {
  font-size: 25px;
  font-weight: 500;
  color: #9685ff;
  margin-left: 6px;
}

.APY p {
  color: #b3b3b3;
  font-size: 14px;
  display: flex;
  gap: 10px;
}
.APY p span {
  color: #9685ff;
  font-size: 20px;
  font-weight: 400;
}
.tag_staking_yiedl.nav li {
  width: fit-content;
  flex-grow: initial;
  min-width: 150px;
  margin: 0;
}
.tag_staking_yiedl.nav li a {
  width: 100%;
  display: block;
  text-align: center;
  font-size: 16px;
  padding: 10px 0px;
  color: #7a7a7a;
}
.tag_staking_yiedl.nav li a.active {
  border-bottom: 1px solid #9685ff;
  color: #9685ff;
}
.font_normal {
  font-style: normal;
}
.line_border {
  width: 100%;
  height: 2px;
  border-bottom: 1px dashed #999;
  margin-top: 13px;
}
.flex_start_sae p {
  justify-content: flex-start;
  gap: 10px;
}
.h2_bottomn {
  color: #b3b3b3;
  font-size: 20px;
  font-weight: 400;
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid #767676;
}
.color_sec {
  color: #9685ff;
}
.aling_flexx {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.section_class_grs h4 {
  margin: 0;
  color: #9685ff;
  font-size: 20px;
}
.section_class_grs h5 {
  margin: 0;
  color: #b3b3b3;
  font-size: 20px;
}
.section_class_grs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  color: #b3b3b3;
}
.bg_tra_cades {
  background: #262241;
  padding: 25px 0px;
  padding-bottom: 10px;
  border-radius: 10px;
  border: 1px dashed #8978e1;
}
.cardEdeded {
  padding: 25px 20px;
  border-radius: 10px;
  background: #2c2c2c;
}
.aling_caseds {
  display: flex;
  gap: 10px;
  align-items: center;
}
.flex_image_coloe {
  display: flex;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
}
.flex_image_coloe {
  width: 25px;
}
.new_login_bb .register_login .btn-primary.burdas_buttnd {
  background: transparent !important;
  color: #9685ff;
  border: 1px solid #9685ff;
}
.padidnr_color {
  font-size: 20px !important;
  color: #9685ff !important;
}
.paddinte_ledy_o {
  padding-left: 0 !important;
}
.text_icon_swap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -40px;
  margin-bottom: -40px;
}
.text_icon_swap .cicle_section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 11px;
  background: #9684f8;
  font-size: 27px;
  border-radius: 40px;
  color: #fff;
}
.data_inner span {
  color: #9685ff;
  margin-right: 10px;
}
.bi-trash-fill::before {
  content: "\f5a7";
  font-size: 16px;
}
.data_inner button {
  display: block;
  color: #9685ff;
  padding: 10px 14px;
  border: 1px solid #9685ff;
  border-radius: 10px;
  min-width: 130px;
}
.buttonddd .data_inner {
  color: #b3b3b3;
  font-weight: 300;
  font-size: 15px;
  text-wrap: nowrap;
}
.buttonddd .border_table_row {
  padding: 10px 7px;
}
.histroy_tabs .nav li {
  margin: 0;
  display: flex;
  align-items: center;
}
.histroy_tabs .nav li a {
  padding: 6px 20px;
  margin-right: 10px;
  color: #999;
  border: 1px solid #252525;
  border-radius: 30px;
}
.text_color_imhd {
  color: #b3b3b3 !important;
}
.histroy_tabs .nav li a.active {
  color: #000 !important;
  background: #fff;
  border: 1px solid #fff;
}
.bg_trans {
  background: transparent !important;
}
.small_search.bar label {
  color: #7a7a7a;
  font-size: 14px;
}
.small_search.bar .ui.fluid.dropdown {
  display: block;
  width: 100%;
  min-width: 0;
  border: 1px solid #252525 !important;
  padding: 16px 12px;
}
.small_search.bar .form-control {
  border: 1px solid #252525 !important;
  padding: 16px 12px;
  background: transparent;
  height: 48px;
  color: #fff;
}
.small_search.bar .postion_reletitt .input-group-addon {
  right: 15px;
  top: 15px;
}
.data_inner .profile_image {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: -60px; */
}
.data_inner .profile_image img {
  max-width: 55px;
  margin-right: 10px;
  height: 55px;
  width: 55px;
  border-radius: 50%;
}
.data_inner .name_details {
  min-width: 150px;
}
.data_inner .name_details h5 {
  margin-bottom: 2px;
  font-size: 16px;
  font-weight: 400;
  color: #b3b3b3;
}
.data_inner .name_details p {
  font-size: 14px;
  color: #7a7a7a;
  font-weight: 300;
}
.font_bold_s span {
  font-size: 20px;
}

.data_limit_p2p p {
  margin: 0;
  display: flex;
  gap: 10px;
  color: #b3b3b3;
  font-weight: 400;
}
.data_limit_p2p p span {
  color: #7a7a7a;
}
.color_tet {
  color: #b3b3b3;
}
.data_inner button.p2p2 {
  display: block;
  color: #fff;
  padding: 12px 14px;
  border: 0px solid #9685ff;
  border-radius: 10px;
  min-width: 110px;
  margin-left: auto;
  background: #4f41a4;
  font-weight: 400;
}
.text_primary_txt {
  color: #b3b3b3;
}
.colo_textDa {
  color: #7a7a7a;
}
.font_14 {
  font-size: 14px;
}
.font_16 {
  font-size: 16px;
}
.max-width_tavke {
  min-width: 227px;
}
.primary_green {
  color: #21a75f;
}
.primary_red {
  color: #e84747 !important;
}
span.primary_red {
  color: #e84747 !important;
}
.opacity_02 {
  opacity: 0.3;
}
.button_cirle a {
  padding: 17px 16px;
  background: #2c2c2c;
  font-size: 23px;
  border-radius: 50%;
  color: #b3b3b3;
  display: block;
}
.color_border {
  background: #232036;
  padding: 22px 20px;
  border: 1px dashed #4f41a4;
  border-radius: 10px;
}
.ne_bg_txt {
  background: #2c2c2c;
  border: 1px solid #3d3d3d;
  margin-top: 20px;
  padding: 0;
}
.devision_clas {
  padding: 10px 10px;
  border-bottom: 1px solid #3d3d3d;
  margin-bottom: 12px;
}
.devision_clas .preview,
.timer_element {
  justify-content: flex-start;
  gap: 6px;
}
.right_pading {
  padding-right: 0px !important;
}
.chat_contianer .chat_section {
  min-height: 200px;
  max-height: 500px;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 20px;
}
.chat_contianer .chat_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.chat_contianer .chat_footer input {
  height: 46px;
  flex-grow: 1;
  padding: 0px 10px;
  background: #2c2c2c;
  border-radius: 9px;
  color: #fff;
}
.chat_contianer .chat_footer button {
  padding: 14px 16px;
  border: 1px solid #4f41a4;
  color: #7360e6;
  border-radius: 9px;
}
.chat_conent p {
  display: flex;
  padding: 0;
  justify-content: space-between;
  align-items: center;
  color: #7a7a7a !important;
  padding-bottom: 0px !important;
}
.chat_conent p span {
  padding: 0;
  width: fit-content;
  color: #7a7a7a;
}
.chat_conent .chart_card {
  padding: 7px 11px;
  background: #2c2c2c;
  border-radius: 10px;
  margin-top: 5px;
  color: #b3b3b3;
}
.chat_section .char_recive {
  width: 90%;
}
.char_send {
  width: 90%;
}
.flex_confirm_p2p {
  display: flex;
  align-items: center;
  gap: 40px;
  padding: 30px 20px;
  justify-content: space-evenly;
}
.flex_confirm_p2p .section_class_grs {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.car_tredtsdee {
  padding: 12px 20px !important;
}
.flex_confirm_p2p .section_class_grs label {
  margin: 0;
  margin-bottom: 0 !important;
}
.max-width_tavke {
  min-width: 227px;
}
.primary_green {
  color: #21a75f;
}
.primary_red {
  color: #e84747;
}
.opacity_02 {
  opacity: 0.3;
}
.trade_page_new {
  padding-top: 20px;
}
.trade_page_new .tradepage-bg {
  background: transparent;
}
.trade_page_new .market_price_secrtiom h5 {
  margin-top: 0px;
}
.trade_page_new .market_palace {
  background: #181818 !important;
  border-radius: 16px;
  margin-bottom: 10px;
}
.trade_page_new .card_light {
  background: #181818;
  border-radius: 16px;
}
.trade_page_new .trade_card {
  border: 1px solid #252525;
}
.trade_page_new .trade-colpading {
  padding-right: 5px !important;
  padding-left: 5px !important;
}
.trade_page_new .order_book_ .card_light {
  padding-left: 0;
  padding-right: 0;
  background: transparent;
}
.trade_page_new .order_book_ .tab-content {
  background: #181818;
  padding: 10px 10px;
  border-radius: 16px;
}
.trade_page_new .order-table ul {
  padding: 0px 0px;
  margin-bottom: 11px;
}
.trade_page_new .order-table a {
  font-size: 14px;
  font-weight: 400;
  color: #b3b3b3;
  padding: 7px 17px;
  border-radius: 40px;
  margin-right: 10px;
  border: 1px solid #252525;
}
.trade_page_new .order-table a.active {
  color: #9685ff;
  border-color: #4f41a4;
}
.trade_page_new .order-table .table thead th {
  color: #b3b3b3;
}
.trade_page_new .fixTableHead th {
  background: #181818;
}
.trade_page_new .color-green {
  color: #e84747;
}
.trade_page_new .order-table .table td {
  padding: 7px 0px !important;
  color: #b3b3b3;
  font-size: 12px;
}
.trade_page_new .red-green {
  color: #21a75f;
}
.trade_page_new .curront_price .curret0green {
  color: #21a75f;
}
.trade_page_new .curront_price i {
  color: #21a75f;
}
.trade_page_new .price_section .text_red {
  font-size: 16px;
  margin-left: 20px;
  color: #4ca860;
  font-weight: 600;
}
.trade_page_new .market_price_secrtiom span {
  font-size: 14px;
  color: #cecece;
}
.trade_page_new .market_price_secrtiom h5 {
  color: #cecece;
  font-size: 16px;
}
.trade_page_new .col-green {
  color: #4ca860 !important;
}
.trade_page_new .pair_selelction {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-left: 15px;
}
.trade_page_new .pair_selelction a {
  background: #202020 !important;
  width: 200px;
  text-align: left;
}
.trade_page_new .pair_selelction a.dropdown-toggle::after {
  position: absolute;
  right: 17px;
  top: 20px;
}
.trade_page_new .class_pair_table .dropdown-menu {
  padding: 13px 15px;
  background: #181818 none repeat scroll 0% 0%;
  border: 1px solid #3d3d3d;
  border-radius: 10px;
}
.tabs_secion_nre_search .nav li {
  margin: 0;
  display: flex;
}
.tabs_secion_nre_search .nav li a {
  width: fit-content;
  display: flex;
  align-items: center;
  color: #cecece;
  padding: 0px 10px;
  background: transparent;
  border: 1px solid #3d3d3d;
  border-radius: 20px;
  line-height: 0;
  height: 31px;
  margin-right: 10px;
}
.tabs_secion_nre_search .nav li a.active {
  color: #9685ff;
  border-color: #4f41a4;
}
.d-none {
  display: none !important;
}
.trade_page_new .border_botton .title-table .nav-tabs {
  width: 100%;
}
.trade_page_new .border_botton .title-table li a {
  color: #cecece;
  font-weight: 400;
  margin-right: 12px;
  padding: 10px 10px;
  width: 100%;
  text-align: center;
}
.trade_page_new .border_botton .title-table li a.active {
  color: #9685ff;
  border-bottom: 2px solid #4f41a4;
}
.trade_page_new .border_botton .title-table li {
  margin: 0;
  display: flex;
  flex-grow: 1;
}
.new_buyform .formplac li {
  flex-grow: 1;
}
.new_buyform .formplac li a {
  flex-grow: 1;
  color: #9685ff;
  border: 1px solid #4f41a4;
  padding: 13px 10px;
  text-align: center;
  border-radius: 16px;
}
.new_buyform .formplac li a.active {
  border: 1px solid #4f41a4;
  background: #4f41a4;
  border-radius: 16px !important;

  color: #fff;
  -webkit-text-fill-color: snow;
}
.light .new_buyform .formplac li a.active:hover {
  border: 1px solid #553bf5;
  background: #553bf5;
  border-radius: 16px !important;

  color: #fff;
  -webkit-text-fill-color: snow;
}
.light .form_pading_s.box .form-group {
  background: #fff;
  border-radius: 8px;
  margin: 0;
  padding: 19px 10px !important;
}
.light .postion_reletitt.refer {
  position: relative;
  background: #ededed;
  color: #553bf5;
  border: none;
  border-radius: 10px;
  height: 44px;
  padding: 11px;
}
.light .onlywhitee.new_login_bb .register_login .form-control-ref {
  color: #9685ff;
}
.new_buyform .nav {
  gap: 20px;
}
.inner_form {
  margin-top: 15px;
}
.inner_form li {
  gap: 0;
}
.inner_form li a {
  border-radius: 0 !important;
  background: transparent;
  border: none !important;
  color: #cecece !important;
  border-bottom: 1px solid #252525 !important;
}
.inner_form li a.active {
  color: #9685ff !important;
  border-bottom: 1px solid #4f41a4 !important;
}
.formtext label {
  color: #949393;
  margin-bottom: 0;
}
.formtext .form-group {
  margin-top: 10px !important;
  background: #202020 !important;
  border-color: #202020 !important;
  border-radius: 10px !important;
  padding: 0px 3px !important;
  margin-bottom: 10px !important;
}
.color_teds {
  color: #949393 !important;
}
.foem_group .form-group.vuttond > button {
  width: 100%;
  margin: 0 auto;
  display: block;
  padding: 10px 0px;
  background: #4f41a4 !important;
  color: #fff;
  border: 1px solid #4f41a4 !important;
}
.light .foem_group .form-group.vuttond > button {
  width: 100%;
  margin: 0 auto;
  display: block;
  padding: 10px 0px;
  background: #553bf5 !important;
  color: #fff;
  border: 1px solid #553bf5 !important;
}
.light .foem_group .form-group.vuttond > button :hover {
  border: 1px solid #553bf5 !important;
}
.light .foem_group .form-group.vuttond > button span {
  color: #fff !important;
}
.foem_group .form-group.vuttond > button a {
  color: #fff !important;
}
.color_padinr {
  background: #181818;
  padding: 20px;
  padding-top: 0;
  overflow: hidden;
  border-radius: 0px 0px 16px 16px;
  padding-bottom: 0px !important;
}
.transe {
  background: transparent !important;
}
.trade_page_new .ordertabel .fixTableHead {
  overflow-y: auto;
  height: 540px;
}
.trade_page_new .fixTableHead th {
  color: #949393;
}
.trade_page_new .foem_group .form-group .currency {
  right: 14px;
  color: #939292;
  font-weight: 400;
  background: transparent;
}
a.forget {
  color: #7363d7;
  text-decoration: none;
  font-size: 14px;
  width: 100%;
  display: block;
  text-align: center;
}
p.text_newsd {
  text-align: left !important;
  color: #949393 !important;
  font-weight: 300 !important;
  font-size: 14px !important;
}
span.color {
  color: #949393;
  margin-right: 8px;
}
.Card_dashboard {
  background: #181818;
  padding: 38px 30px;
  border-radius: 10px;
}
.Card_dashboard p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #e6e6e6;
  font-size: 34px;
}
.Card_dashboard p span {
  color: #b3b3b3;
  font-size: 16px;
}
.button_footer {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #252525;
  padding-top: 30px;
  padding-bottom: 10px;
  gap: 20px;
}
.button_footer .button {
  border: 1px solid #4f41a4 !important;
  color: #8d81d4 !important;
  text-transform: capitalize !important;
  font-size: 14px !important;
  padding: 15px 27px !important;
  background: transparent !important;
  font-weight: 400 !important;
  flex-grow: 1 !important;
  border-radius: 16px !important;
}
.marging_button {
  /* line-height: 0; */
  margin-bottom: -5px;
}

/* Landing page */
.light .banner-text {
  color: #131313;
  font-weight: 600;
  font-size: 64px;
}

.light .banner {
  background-color: #f8f8f8;
}

.light .banner-content {
  color: #131313;
}
.light .banner-btn {
  background-color: #553bf5;
}

/* DARK MODE START */
.banner-btn {
  padding: 15px 60px;
  border: none;
  border-radius: 12px;
  color: #fff;
}
.bnr {
  text-align: left;
}

.banner-text {
  color: #b3b3b3;
  align-self: stretch;
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.banner {
  background: #131313;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 50px 0px;
}
.banner-content {
  color: #7a7a7a;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.18px;
}
.banner-btn {
  background: #4f41a4;
  color: #fff;
}
/*  */
.light .slider-list {
  background-color: #f3f3f3;
  border: 2px solid #fff;
}
.light .slider-list1 {
  background-color: #f3f3f3;
  border: 2px solid #fff;
}
.light .slider-list2 {
  background-color: #f3f3f3;
  border: 2px solid #fff;
}

.light .slider-list3 {
  background-color: #f3f3f3;
  border: 2px solid #fff;
}
.light .slider {
  background: #f3f3f3;
}

.light .slider-logo {
  background: #ffffff9c;
  display: inline-block;
}
.light .slider-logo1 {
  background: #ffffff9c;
  display: inline-block;
}
.light .slider-logo2 {
  background: #ffffff9c;
  display: inline-block;
}

/* DARK MODE START */
.darkmode-banner-image {
  display: inline;
}
.lightmode-banner-image {
  display: none;
}
.light .darkmode-banner-image {
  display: none;
}

.light .lightmode-banner-image {
  display: inline;
}

.slider {
  background: #181818;
  padding: 50px 0px;
}
.slider .slick-slide {
  padding: 0 15px;
}

.slider .slick-list {
  margin: 0 2em;
}

.slider-logo {
  margin-bottom: 12px;
  border-radius: 50%;
}

.slider-logo1 {
  margin-bottom: 12px;
  border-radius: 50%;
  padding: 10px;
}
.slider-logo2 {
  margin-bottom: 12px;
  border-radius: 50%;
}
.slider-list {
  border: 2px solid rgba(255, 255, 255, 0.06);
  background: rgba(237, 237, 237, 0);
  border-radius: 12px;
  padding: 20px 24px;
  display: flex !important  ;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  opacity: 0.8;
  background-size: contain;
  background-position-x: 55px;
  background-position-y: -55px;
}
.slider-list1 {
  border: 2px solid rgba(255, 255, 255, 0.06);
  background: rgba(237, 237, 237, 0);
  border-radius: 12px;
  padding: 20px 24px;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  opacity: 0.8;
  background-size: contain;
  background-position-x: 55px;
  background-position-y: -55px;
}
.slider-list2 {
  border: 2px solid rgba(255, 255, 255, 0.06);
  background: rgba(237, 237, 237, 0);
  border-radius: 12px;
  padding: 20px 24px;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  opacity: 0.8;
  background-size: contain;
  background-position-x: 55px;
  background-position-y: -55px;
}
.slider-list3 {
  border: 2px solid rgba(255, 255, 255, 0.06);
  background: rgba(237, 237, 237, 0);
  border-radius: 12px;
  padding: 20px 24px;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  opacity: 0.8;
  background-size: contain;
  background-position-x: 55px;
  background-position-y: -55px;
}

.slider-heading {
  color: #b3b3b3;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-top: 2px;
}
.slider-num {
  color: #b3b3b3;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.slider-text {
  color: #b3b3b3;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 8px;
  margin-top: -12px;
}
.light .slider-text {
  color: #131313 !important;
}

.light .slider-num {
  color: #131313 !important;
}
.y-taikonz-content {
  margin-bottom: 20px;
}
.y-taikonz-content h1 {
  font-size: 54px;
  font-style: normal;
  text-align: left;
}
.y-taikonz-content p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
}
.light .x-taikonz {
  background-image: url("../src/img/New_images/taikonx/Subtract.png");
}
.x-taikonz {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-position: bottom;
  background-size: 100%;
  background-repeat: no-repeat;
  padding-bottom: 80px;
  position: relative;
  z-index: 2;
}

.x-taikonz-content h3 {
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 5px;
}
.bitcoin-logo {
  padding: 20px;
  margin-right: 15px;
}
.x-taikonz-content p {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.light .taikonz {
  background: #f3f3f3;
}

.taikonz {
  padding: 90px 0px;
}

.light .index1::before {
  background-color: #553bf5;
}
.light .index2::before {
  background-color: #553bf5;
}
.light .index3::before {
  background-color: #553bf5;
}

.bitcoin-logo img {
  margin: 0 auto;
  margin-bottom: 15px;
}
.payment_cancel_btn {
  background: transparent;
  border: 1px solid #4f41a4 !important;
  color: #4f41a4 !important;
}
/* DARK MODE START */

.taikonz {
  background: #131313;
}
.y-taikonz-content h1 {
  color: #b3b3b3;
}
.y-taikonz-content p {
  color: #7a7a7a;
}
.index1::before {
  content: "";
  position: absolute;
  height: 26px;
  background-color: #9685ff;
  border-radius: 101px;
  right: 34px;
  z-index: 1;
  top: 99%;
  transform: translate(-50%, -50%);
  width: 32%;
  bottom: -31px;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.index2::before {
  content: "";
  position: absolute;
  height: 26px;
  background-color: #9685ff;
  border-radius: 101px;
  right: 34px;
  z-index: 1;
  top: 99%;
  transform: translate(-50%, -50%);
  width: 32%;
  bottom: -31px;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.index3::before {
  content: "";
  position: absolute;
  height: 26px;
  background-color: #9685ff;
  border-radius: 101px;
  right: 34px;
  z-index: 1;
  top: 99%;
  transform: translate(-50%, -50%);
  width: 32%;
  bottom: -31px;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.x-taikonz {
  background-image: url("../src/img/New_images/taikonx/dark_sub.png");
}
.x-taikonz-content h3 {
  color: #b3b3b3;
}
.x-taikonz-content p {
  color: #7a7a7a;
}
.light .currency-content h1 {
  color: #131313;
  font-size: 64px;
  font-weight: 600;
}
.light .y-taikonz-content h1 {
  color: #131313;
}
.light .y-bitcoin1 {
  -webkit-backdrop-filter: blur(54.098594665527344px);
  backdrop-filter: blur(54.098594665527344px);
  border: none !important ;
  background: linear-gradient(
    180deg,
    rgba(248, 248, 248, 0) 0%,
    #f8f8f8 95.21%
  );
  border-radius: 16px;
  border: 1.296px solid rgba(255, 255, 255, 0.13);
  background: rgba(237, 237, 237, 0.72);
  backdrop-filter: blur(54.098594665527344px);
}
.light .vertival_scrolls::after {
  background: linear-gradient(
    180deg,
    rgba(248, 248, 248, 0) 0%,
    #f8f8f8 95.21%
  );
}

.vertival_scrolls::after {
  content: "";
  height: 100px;
  background: linear-gradient(180deg, rgba(248, 248, 248, 0) 0%, #fff 95.21%);
  width: 100%;
  position: absolute;
  bottom: 0;
}

.word_break .preview span {
  width: 56% !important;
  text-align: right !important;
  word-wrap: break-word;
}

.table-cell.padding-left {
  padding-left: 30px;
}

.table-cell.padding-right {
  padding-right: 52px;
}

/* DARK MODE END */

.light .x-taikonz-content h3 {
  color: #131313;
}

.light .slider-list {
  background-color: #f3f3f3;
  border: 2px solid #fff;
  color: #131313;
}
.light .slider-heading {
  color: #131313;
}

.light .currency {
  padding: 80px 0px;
  background: #f8f8f8;
}
.y-taikonz {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.y-bitcoin1 {
  display: flex;
  padding: 20px 24px;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  border: 1.296px solid rgba(255, 255, 255, 0.13);
  background: rgba(237, 237, 237, 0.72);
  backdrop-filter: blur(54.098594665527344px);
  margin-bottom: 15px;
}
.y-bitcoin-logo {
  height: 56px;
}
.light .y-bitcoin-content {
  color: #131313 !important;
}
.y-bitcoin-content {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.15px;
}
.btc {
  color: #7a7a7a;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.y-bitcoin2 {
  display: flex;
  padding: 20px 24px;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  border: 1.296px solid rgba(255, 255, 255, 0.13);
  background: rgba(237, 237, 237, 0.72);
  backdrop-filter: blur(54.098594665527344px);
  margin-top: 18px;
}
.y-bitcoin3 {
  display: flex;
  padding: 20px 24px;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  border: 1.296px solid rgba(255, 255, 255, 0.13);
  background: rgba(237, 237, 237, 0.72);
  backdrop-filter: blur(54.098594665527344px);
  margin-top: 18px;
}
.y-bitcoin4 {
  display: flex;
  padding: 20px 24px;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  border: 1.296px solid rgba(255, 255, 255, 0.13);
}

/* DARK MODE START */

.currency {
  background-color: #131313;
}
.currency-content h1 {
  color: #b3b3b3;
  font-size: 56px;
  font-weight: 400;
}
.currency-content p {
  color: #7a7a7a;
  font-size: 24px;
}
.y-bitcoin1 {
  border-radius: 16px;
  border: 1.296px solid #2a282c;
  background: rgba(24, 24, 24, 0.64);
  backdrop-filter: blur(54.098594665527344px);
}
.y-bitcoin2 {
  border-radius: 16px;
  border: 1.296px solid #2a282c;
  background: rgba(24, 24, 24, 0.64);
  backdrop-filter: blur(54.098594665527344px);
}
.y-bitcoin3 {
  border-radius: 16px;
  border: 1.296px solid #2a282c;
  background: rgba(24, 24, 24, 0.64);
  backdrop-filter: blur(54.098594665527344px);
}

.y-bitcoin-content {
  color: #b3b3b3;
}
.currency-btn {
  width: 196px;
  height: 44px;
  border: none;
  border-radius: 12px;
  background-color: #4f41a4;
  color: #fff;
}

/* Dark end */

.light .currency-btn {
  background-color: #553bf5;
  color: #fff;
}

.arrow {
  display: flex;
  align-items: center;
  gap: 6px;
}
.arrow span {
  font-size: 16px;
  color: #4ca860;
}
.dex_default {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}
.dex_default .btc {
  margin-bottom: -4px;
}

.vertival_scrolls {
  position: relative;
}
.vertival_scrolls::after {
  content: "";
  height: 300px;
  background: linear-gradient(
    180deg,
    rgba(248, 248, 248, 0) 0%,
    #131313 95.21%
  );
  width: 100%;
  position: absolute;
  bottom: 0;
}

.investing-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #9685ff;
  font-size: 59px;
  font-style: normal;
  font-weight: 800;
  margin-left: 20px;
  position: relative;
  z-index: 2;
}
.x-investing {
  display: flex;
  flex-shrink: 0;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  fill: rgba(237, 237, 237, 0.2);
  stroke-width: 2px;
  stroke: #fff;
  border: 1px solid white;
  border-radius: 10px;
  margin: top 30px;
  border-image: linear-gradient(90deg, #29272b 0%, #181818 98.5%) 1;
}
.light .x-investing {
  stroke: #fff;
  border: 1px solid white;
  border-image: linear-gradient(90deg, #fefefe 11.2%, #f3f3f3 98.5%) 2;
}

.light .investing-logo {
  color: #553bf5;
}
.light .x-investing-heading {
  color: #131313;
}
.light .investing {
  background: #f3f3f3;
}
.light .investing-heading {
  color: #131313;
  font-weight: 600;
  font-size: 64px;
}
.light .y-taikonz-content p {
  color: #131313;
}

.light .currency-content p {
  color: #131313;
}

.light .investing-text {
  color: #131313;
}

.light .platform-text {
  color: #131313;
}

.x-investing::before {
  content: "";
  background-image: url("../src/img//New_images/taikonx/investing_dark_bg.png");
  background-repeat: no-repeat;
  position: absolute;
  opacity: 0.5;
  top: -3px;
  left: 2px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-size: contain;
  z-index: 1;
}

/* DARK MODE START */

.light .select_duration ul li a.active {
  border: 1px solid #553bf5;
  color: #553bf5 !important;
}

.light .select_duration ul li a.active:hover {
  border: 1px solid #553bf5 !important;
  color: #553bf5 !important;
}

.light .x-investing::before {
  background-image: url("../src/img//New_images/taikonx/investing_lightbg.png");
  background-repeat: no-repeat;
}
.x-investing-content {
  padding: 12px;
  fill: rgba(237, 237, 237, 0.2);
  stroke-width: 2px;
  stroke: #fff;
  margin-left: 15px;
}

.investing-text {
  margin-top: 20px;
  font-size: 24px;
  font-style: normal;
  line-height: 150%;
  letter-spacing: 0.18px;
  color: #7a7a7a;
}

.investing-heading {
  color: #b3b3b3;
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
}
.investing {
  padding: 100px 0px;
  background: #181818;
}
.x-investing-heading {
  color: #b3b3b3;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 5px;
}

.x-investing-text {
  color: #7a7a7a;
}

/* Dark end */
.Card_dashboard {
  overflow: hidden;
  overflow-x: auto;
}

.light .crypto-platform {
  background: hsl(0, 0%, 97%);
  background-image: url("../src/img/New_images/taikonx/Mockupbackground.png");
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 51%;
}
.light .crypto-platform-content h1 {
  color: #131313;
  font-size: 64px;
  font-weight: 600;
}
.light .x-investing-text {
  color: #131313;
}

.light .x-taikonz-content p {
  color: #131313;
}
/* DARK MOOD START */

.crypto-platform-content p {
  font-size: 18px;
}
.crypto-platform-content h1 {
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
}

.crypto-platform {
  padding: 120px 0px;
  background: #181818;
  background-image: url("../src/img/New_images/taikonx/Dark_MockupBackground.png");
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 51%;
}
.platform-heading {
  color: #b3b3b3;
  font-weight: 700;
}
.platform-text {
  color: #7a7a7a;
}
/* Dark end */
.light .list-items {
  color: #131313;
}
.light .footer {
  background: #fff;
}
.light .footer ul li {
  color: #131313;
}
.light .copyright {
  color: #131313;
}
.light .page {
  color: #131313;
}

/* DARK MOOD START */

.footer {
  background: #131313;
  padding: 80px 0px;
}
.copyright {
  color: #b3b3b3;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  margin: 10px 17px;
  margin-left: 33px;
}
.list-items {
  color: #b3b3b3;
  list-style-type: none;
  padding: 0;
  margin-top: 20px;
  line-height: 30px;
  font-size: 18.651px;
  font-style: normal;
  font-weight: 400;
}
.list-items li {
  cursor: pointer;
}
.page {
  color: #b3b3b3;
  font-size: 20.315px;
  font-style: normal;
  font-weight: 700;
}
.dark_taikonz-logo {
  display: inline;
  cursor: pointer;
}
.taikonz-logo {
  display: none;
  cursor: pointer;
}
.light .dark_taikonz-logo {
  display: none;
}
.light .taikonz-logo {
  display: inline;
}

.footer ul li {
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: 300;
  font-size: 18px;
}
/* Dark end */
.light_logo {
  display: none;
  cursor: pointer;
}

.toplable {
  margin-bottom: 18px;
  margin-top: 4px;
}

.toplable p {
  font-size: 16px;
  margin-bottom: 0;
}
.dashboard_title {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #e6e6e6;
  margin-top: 20px !important;
  margin-bottom: -10px;
  font-size: 19px;
}
.toplable h2 {
  margin-top: 3px;
  display: flex;
  color: #e6e6e6;
}
.toplable h2 span {
  font-weight: 300;
  font-size: 13px;
  margin-left: 5px;
}
.tabs_collection span {
  cursor: pointer;
  padding: 0px 0px;
  color: #b3b3b3;
  font-weight: 600;
  font-size: 16px;
  border: 1px solid #252525;
  padding: 6px 20px;
  border-radius: 30px;
}
.tabs_collection span.active {
  background: #b3b3b3;
  color: #000;
}

.index_1 {
  background-image: url(".//img/New_images/taikonx/dark_sub.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 320px;
  text-align: center;
  margin-top: 64px;
  z-index: 2;
  position: relative;
}

.index_1 img {
  margin-top: -50px;
  margin-bottom: 30px;
}

.index_1 h3 {
  font-size: 32px;
  font-weight: 400;
  color: #b3b3b3;
}

.index_1 p {
  font-size: 16px;
  font-weight: 400;
  color: #7a7a7a;
}
.y-taikonz-contant h1 {
  font-size: 56px;
  font-weight: 400;
  color: #b3b3b3;
}
.y-taikonz-contant p {
  font-size: 24px;
  font-weight: 400;
  color: #7a7a7a;
}

.index_2::before {
  content: "";
  position: absolute !important;
  height: 26px;
  background-color: #9685ff;
  border-radius: 101px;
  z-index: 1;
  transform: translate(-50%, -50%);
  width: 32%;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: relative;
}

/*  */

.light .index_1 {
  background-image: url(".//img/New_images/taikonx/Subtract.png");
}

.light .y-taikonz-contant h1 {
  color: #131313;
  font-weight: 600;
  font-size: 64px;
}

.light .y-taikonz-contant p {
  color: #131313;
}

.light .index_1 h3 {
  color: #131313;
}

.light .index_1 p {
  color: #131313;
}

.light .index_2::before {
  background-color: #553bf5;
}
/* app .css */
.light .chat_conent .chart_card {
  background: #fff;
  color: #131313;
  border-radius: 8px;
  background: #ededed;
}

.light .form_login_section p {
  color: #131313;
}

.light .register_login p {
  color: #131313 !important;
}

/* launchpad buy new light mode */

.light button.back_butn {
  color: #252525 !important;
}

.light .progredsss_launch .processs {
  background: #eafaf2;
  color: #12b76a;
}

.light .progredsss_launch .date_section p span {
  font-weight: 400;
  font-size: 14px;
}

.light .progredsss_launch .date_section p {
  color: #252525;
}

.light .price_pair {
  background: #f3f3f3;
}

.light .price_pair .paitr_content {
  color: #252525;
}

.light .contant_details h3 {
  color: #252525;
}

.light .contant_details p {
  color: #252525;
}

.light .innaer_details label {
  color: #252525;
}

.light .innaer_details h5 {
  color: #252525;
}

.light .contant_collect h6 {
  color: #252525;
}

.light .mgs_content .card_bg {
  background: #ededed;
}

.light .mgs_content .card_bg h4 {
  color: #252525;
}

.light .contecnt_main {
  color: #252525;
}

.light .teble_primary_color.coloe {
  background: #ededed;
}

.light .teble_primary_color div {
  color: #252525;
}

.light .table_responsive .table-row {
  background: transparent;
}

/* pop up-1 */

.modal-text1 span {
  color: #949393;
  font-size: 14px;
  font-weight: 400;
}
.modal-text1 h3 {
  color: #9685ff;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.modal-text1 p {
  border-top: 2px dashed #3d3d3d;
  color: #cecece;
  font-size: 14px;
  font-weight: 400;
  margin-top: 15px;
  padding-top: 15px;
}
input {
  background: transparent;
}

/*  Pop up-2 */

.modal-title {
  color: #cecece;
  font-size: 24px;
  font-weight: 400;
}

.modal-text {
  color: #cecece;
  font-weight: 500;
  border-bottom: 2px dashed #949393;
}

.modal-text span {
  color: #949393;
  font-size: 14px;
  font-weight: 400;
}

.modal-footer {
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0.75rem;
  border: none;
}

.modal-header {
  border: none;
}

.max {
  display: flex;
  justify-content: space-between;
  padding: 10px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 12px;
  background: #2c2c2c;
  color: #cecece;
  font-size: 16px;
  font-weight: 400;
}
.modal-content {
  padding: 10px 30px;
  border: 1px solid #383838;
  margin-bottom: 25px;
  background: #2f324180;
  border-radius: 15px;
}

#input {
  border-radius: 12px;
  background: #2c2c2c;
  margin-top: 8px !important;
  margin-bottom: 10px;
  font-size: 14px;
  padding: 10px 10px;
  width: 100%;
  color: #cecece;
  display: flex;
  justify-content: space-between;
}

.amt p {
  margin-top: 20px !important;
  margin-bottom: 0px;
  color: #949393;
  font-size: 14px;
  font-weight: 400;
}

#commit-btn {
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
}

#cancel-btn {
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 12px;
  border: 1.5px solid #4f41a4;
  color: #9685ff;
  font-size: 16px;
  font-weight: 500;
}
.wallet {
  margin-top: 0px;
  margin-bottom: 15px !important;
}

.fas {
  font-size: "24px";
  color: "#fff";
  cursor: "pointer";
}

.light .fas {
  color: #181818 !important;
}

.light .bi-trash-fill::before {
  color: red;
}

.light .content_notification .msg-noti p {
  color: #121212;
}

.proof_text h4 {
  text-align: center;
}

.proof_text {
  width: auto;
}

.button_checkbox2 {
  display: inline;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
}

.verifyed {
  margin-right: 15px;
}

.light button.btn.connectBtn {
  background: #553bf5 !important;
  color: #ffffff !important;
}
.light button.btn.connectBtn:hover {
  background: #553bf5 !important;
  color: #ffffff !important;
  border: none !important;
}

.nav-pills li:active {
  border-bottom: 1px solid black;
}
.new_buyform .formplac .limit_newcolor li a.active {
  border-bottom: 1px solid #4f41a4 !important;
  background: transparent;
  -webkit-text-fill-color: #553bf5;
  border-radius: 0px !important;
}

.new_buyform .formplac .limit_newcolor li a.active {
  border-bottom: 1px solid #553bf5 !important;
  background: transparent;
  -webkit-text-fill-color: #553bf5;
  border-radius: 0px !important;
}

.light .new_buyform .formplac .limit_newcolor li a.active {
  border-bottom: 1px solid #553bf5 !important;
  background: transparent;
  -webkit-text-fill-color: #553bf5;
  border-radius: 0px !important;
}

.light .onlywhitee.new_login_bb .register_login .form-textarea {
  background: #f9fafa;
  color: #000;
}
.light .new_login_bb .register_login .btn-second {
  border: 1px solid #553bf5;
  color: #553bf5 !important;
}
.dilesd {
  display: flex;
  justify-content: space-between;
}
.light_logo {
  display: none;
  cursor: pointer;
}

.toplable {
  margin-bottom: 18px;
  margin-top: 4px;
}

.toplable p {
  font-size: 16px;
  margin-bottom: 0;
}
.dashboard_title {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #e6e6e6;
  margin-top: 20px;
  margin-bottom: -10px;
  font-size: 19px;
}
.toplable h2 {
  margin-top: 3px;
  display: flex;
  color: #e6e6e6;
}
.toplable h2 span {
  font-weight: 4 00;
  font-size: 13px;
  margin-left: 5px;
}

.light .dashboard_title {
  color: #252525;
}

.light .toplable h2 {
  color: #252525;
}

.light .toplable h2 span {
  color: #252525;
}
.light .light_dashboard {
  background-color: #fff;
}

.light .light_dashboard .Card_dashboard {
  border-radius: 16px;
  background: #e5e5e5;
}

.light .light_dashboard .tabs_collection span.active {
  background: #252525;
  color: #e5e5e5;
}

.light .tabs_collection span {
  color: #252525;
  border: 1px solid #252525;
}

.light .form_login_section {
  border-radius: 16px;
}

.light .timer h6 {
  color: #131313;
}
.amt_detail {
  padding-bottom: 60px !important;
}
.detail_profile {
  margin-left: 10px !important;
}
/* trade dark */

.trade_page_new .foem_group .form-group .currency {
  right: 14px;
  color: #939292;
  font-weight: 400;
  background: transparent;
}

.slider-list {
  background: url(http://localhost:3001/static/media/btc_background.ea5b39960e693186c502.png) !important;
  background-repeat: no-repeat;
  position: relative;
  background-position-x: 65px;
  background-position-y: -36px;
}
.register_btn {
  color: #9685ff !important;
  text-transform: capitalize !important;
  border: 1px solid #4f41a4 !important;
  border-radius: 12px !important;
  margin-right: 15px !important;
}
.light .register_btn {
  border: 1px solid #553bf5 !important;
  color: #553bf5 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  /* border-radius: 10px !important; */
}
.light .reg_acc p {
  color: #252525;
}
.reg_acc p {
  color: #b3b3b3;
  font-weight: 500 !important;
  font-size: 14px;
  margin-right: 25px !important;
}
.reg_acc {
  margin: auto 0 !important;
}
.agrreement input {
  margin-left: 0px !important ;
}
.agree_check {
  gap: 8px !important;
}
.light .new_login_bb .register_login .btn-primary.payment_cancel {
  background: transparent !important;
  color: #553bf5;
  border: 1px solid;
}

.light .chat_contianer .chat_footer button {
  border: 1px solid #553bf5;
  color: #553bf5;
}

.light .form_pading_s.box {
  background: linear-gradient(93deg, #553bf5f5 3.79%, #68301870 104.96%),
    linear-gradient(180deg, #d9d9d9 0%, rgba(217, 217, 217, 0) 100%) !important;
}
.qr_img {
  border-radius: 20px;
  box-shadow: 0px 6px 63px 0px #666;
  margin-top: 40px;
}

.light .qr_img {
  box-shadow: none;
}

.input-group-addon i {
  color: #fff;
}

.light .input-group-addon i {
  color: #242424;
}

.payment_heading {
  color: #b3b3b3;
  font-size: 24px !important;
  font-weight: 400;
  margin-bottom: 35px;
  text-transform: capitalize;
}

.edit_delete_btn .payment_delete {
  color: #b3b3b3 !important;
}
.edit_delete_btn {
  gap: 0px !important;
  margin-right: -35px;
}

/* Media query for mobile responsiveness */

.posab {
  left: 92px !important;
}
.banner-text {
  font-size: 52px;
}
.banner-content {
  font-size: 18px;
}
.y-taikonz-contant h1 {
  font-size: 52px;
}
.y-taikonz-contant p {
  font-size: 18px;
}
.currency-content h1 {
  font-size: 52px;
}
.currency-content p {
  font-size: 18px;
}
.investing-heading {
  font-size: 52px;
}
.investing-text {
  font-size: 18px;
}
.crypto-platform-content h1 {
  font-size: 52px;
}

.stake_box .form_pading_s {
  margin-top: 40px;
  padding-bottom: 56px;
}
.claimed_btn {
  color: rgb(104, 227, 104) !important;
  border: 1px solid rgb(104, 227, 104) !important;
}
.profile_image1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2px;
}
.new_login_bb .success_stake {
  padding: 50px 80px !important;
}
input:placeholder-shown {
  text-overflow: ellipsis;
}

.onlywhitee.new_login_bb .register_login .form-control-ref {
  width: 250px;
  background: transparent;
  border: none;
  color: #fff;
  text-overflow: ellipsis;
  overflow: hidden;
}

.card_launchpad.stake_home {
  min-height: 340px !important;
  margin-top: 10px;
}

.postion_reletitt.refer {
  position: relative;
  background: #2c2c2c;
  color: #fff;
  border: none;
  border-radius: 10px;
  height: 44px;
  padding: 11px;
}

.logo_new {
  width: 140px;
  cursor: pointer;
  padding: 12px;
  margin-top: 6px !important;
}

.max-btc-btn {
  display: flex;
  gap: 15px;
}

.dash_board_search_box {
  left: 42%;
  position: relative;
  border: 1px solid #252525;
}
.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  background-color: #202020 !important;
}

/*  */
@media (max-width: 1280px) {
  .light .crypto-platform-content h1 {
    font-size: 50px;
  }
  .light .currency-content h1 {
    font-size: 50px;
  }
  .light .investing-heading {
    font-size: 50px;
  }
  .new_login_bb .pading_cardd {
    padding: 50px 30px !important;
  }
  .data_inner .p2p_profile_image {
    margin-left: -80px;
  }
  .light .banner-text {
    font-size: 50px;
  }
  .light .y-taikonz-contant h1 {
    font-size: 50px;
  }
  .light .crypto-platform {
    background-size: 65%;
    background-position-x: 100%;
  }

  .dropdown_butn {
    font-size: 16px;
  }
  .light .menu_items_fex .navlink_new {
    font-size: 16px !important;
  }
  .tabs_collection span {
    font-size: 14px;
    padding: 2px 14px;
    padding-left: 12px;
  }
  .menu_items_fex .navlink_new {
    font-size: 16px;
  }
  .navlink_new {
    font-size: 16px;
  }
  .navlink_new {
    padding: 14px 25px;
  }

  .data_limit_p2p p {
    text-wrap: nowrap;
  }
  .profile_image.sell_profile {
    margin-left: 0px !important;
  }
}

@media (max-width: 1024px) {
  .font_bold_s span {
    font-size: 18px;
    margin-left: 55px;
  }
  .data_inner .p2p_profile_image {
    margin-left: -56px;
  }
  .border_table_row {
    padding: 18px 8px;
  }
  .tabs_collection {
    justify-content: flex-end;
    gap: 10px;
  }
  .posab {
    left: 72px !important;
  }
  .onlywhitee.new_login_bb .register_login .form-control-ref {
    width: 200px;
  }
  .class-padding {
    padding: 0 40px;
  }
  .dash_board_search_box {
    left: 36%;
  }
}

@media (max-width: 900px) {
  .y-taikonz-contant h1 {
    font-size: 30px;
  }
  .index_1 h3 {
    font-size: 30px !important;
  }
  .index_1 p {
    font-size: 18px;
  }
  .table_responsive {
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;
    padding: 10px;
  }
  .table_responsive .table-row {
    flex-direction: row;
    background: transparent;
  }
  .table_responsive {
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;
    padding: 10px;
  }
  .table_responsive .table-cell {
    border: 0px solid #ddd;
    min-width: 240px;
    background: transparent;
  }
  .table_responsive .border_table_row {
    border: 0px solid #252525;
  }
  .light .table_responsive {
    background-color: #ededed;
    border-radius: 20px;
  }
  .posab {
    left: 75px !important;
  }
}
@media (max-width: 820px) {
  .dash_board_search_box {
    left: 28%;
  }
}

@media (max-width: 700px) {
  .y-taikonz-contant h1 {
    font-size: 30px;
  }
  .index_1 h3 {
    font-size: 25px !important;
  }
  .index_1 p {
    font-size: 17px;
  }
  .slick-prev:before,
  .slick-next:before {
    display: none;
  }
}

@media (max-width: 600px) {
  .tabs_collection span {
    padding: 6px 16px;
    border-radius: 30px;
    margin-bottom: 16px;
  }
  .stake-tabel .buttonddd {
    background: transparent;
    border: none !important;
    justify-content: space-around;
  }

  .stake-tabel .stake-cell {
    padding-left: 30px;
  }
  .history-profile {
    margin-right: 35px;
  }
  /* .table-row {
    flex-direction: column;
  } */

  /* .table-cell {
    border: 1px solid #ddd;
    border-bottom: none;
  } */

  /* .table-cell:last-child {
    border-bottom: 1px solid #ddd;
  } */
  .table_responsive .table-row {
    flex-direction: row;
    background: transparent;
  }
  .table_responsive {
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;
    padding: 10px;
  }
  .table_responsive .table-cell {
    border: 0px solid #ddd;
    min-width: 240px;
    background: transparent;
  }
  .table_responsive .border_table_row {
    border: 0px solid #252525;
  }
  .page-link {
    padding: 8px 14px !important;
  }
  .new_login_bb .form_pading_s {
    padding: 24px 12px;
  }
  .pading_cardd {
    margin: 13px 0px;
    padding: 43px 26px;
  }
  .bootsrab_tabs .nav li a {
    margin-right: 0px;
  }

  .profile_content_image img {
    height: 60px;
    width: 60px;
  }
  .profile_content_image .profile_content h3 {
    font-size: 14px;
  }
  .new_login_bb .card_logoki h1 {
    font-size: 24px;
  }

  .preview {
    font-size: 14px;
    flex-wrap: wrap;
  }
  .preview span {
    font-size: 14px !important;
  }
  .y-taikonz-contant h1 {
    font-size: 30px;
  }
  .index_1 h3 {
    font-size: 26px !important;
  }
  .index_1 p {
    font-size: 15px;
  }
  .class-padding {
    padding: 0 25px;
  }
  .dash_board_search_box {
    left: 23%;
  }
}

/* David   */

/* Responsive max-width :500 */

@media (max-width: 500px) {
  .table-cell.padding-left {
    padding-left: 50px;
}
  .histroy_tabs .nav li a {
    padding: 4px 15px;  
  }
  .form_pading_s.box::before {
    width: -webkit-fill-available;
  }
  .card_logoki.pading_cardd.payment_card {
    padding: 20px 3px !important;
  }
  .payment_form {
    padding: 10px;
  }

  .posab {
    left: 126px !important;
  }
  .button_cirle a {
    padding: 10px 12px;
    font-size: 14px;
  }
  .aling_caseds {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0px;
  }

  .y-taikonz-contant h1 {
    font-size: 30px;
  }
  .index_1 h3 {
    font-size: 24px !important;
  }
  .index_1 p {
    font-size: 16px;
  }

  .x-investing {
    margin-bottom: 20px;
  }

  .button_accorndi,
  .button_accorndi:hover {
    font-size: 19px !important;
  }
  .bootsrab_tabs .nav li a {
    display: block;
    color: #9685ff;
    padding: 10px 26px;
    border: 1px solid #9685ff;
    border-radius: 10px;
    margin-right: 0px;
  }
  .bootsrab_tabs {
    display: flex;
    justify-content: space-around;
  }
  .new_login_bb .register_login .btn-primary {
    padding: 12px 2px;
    margin-top: 10px !important;
  }
  .onlywhitee.new_login_bb .register_login .form-control-ref {
    width: 166px;
  }
  .y-taikonz-contant p {
    font-size: 14px;
  }
  .light .banner-text {
    font-size: 35px;
  }
  .light .y-taikonz-contant h1 {
    font-size: 35px;
  }
  .light .currency-content h1 {
    font-size: 35px;
  }
  .light .investing-heading {
    font-size: 35px;
  }
  .crypto-platform {
    padding: 30px 0px;
  }
  .light .crypto-platform-content h1 {
    font-size: 35px;
  }
  .class-padding {
    padding: 0px 20px;
  }
  .dash_board_search_box {
    left: 17%;
  }
}

@media (max-width: 400px) {
  .onlywhitee.new_login_bb .register_login .form-control-ref {
    width: 166px;
  }
  .x-taikonz {
    padding-bottom: 42px;
  }
  .profile_content_image {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
  }
  .onlywhitee.new_login_bb .register_login .form-control {
    margin-top: 15px;
  }

  .input_section_kyc {
    width: 300px !important;
    height: auto !important;
    margin-bottom: 25px;
    border: 2px dotted;
  }
  .input_section_kyc img {
    width: 140px !important;
    height: auto;
  }

  .new_login_bb .register_login .btn-primary {
    padding: 12px 2px;
    margin-top: 10px !important;
  }
  .y-taikonz-contant h1 {
    font-size: 30px;
  }
  .index_1 h3 {
    font-size: 24px !important;
  }
  .index_1 p {
    font-size: 14px;
  }
  .posab {
    left: 104px !important;
  }
  .class-padding {
    padding: 0 20px;
  }
  .dash_board_search_box {
    left: 14%;
  }
  .index_1 {
    padding: 8px;
  }
}

@media (max-width: 300px) {
  .new_login_bb .register_login .btn-primary {
    padding: -1px 2px;
  }
  .y-taikonz-contant h1 {
    font-size: 30px;
  }
  .index_1 h3 {
    font-size: 20px !important;
  }
  .index_1 p {
    font-size: 13px;
  }
  .posab {
    top: 38px !important;
    left: 80px !important;
  }
  .tabs_collection span {
    padding: 1px 7px;
  }
  .logo_new {
    width: 150px;
    cursor: pointer;
    padding: 12px;
  }

  .font_color {
    font-size: 30px;
  }
  .dash_board_search_box {
    left: 3%;
  }
}
